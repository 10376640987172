import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '../../../services/business/user/user.service';
import { BaseComponent } from '../../../framework/base.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent extends BaseComponent {
  loginForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
    this.loginForm = this.formBuilder.group({
      email: '',
      password: '',
    });
  }

  async onSubmit(loginData: any) {
    await this.userService.login(loginData.email, loginData.password);
    window.location.reload();
  }
}
