import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from '../../system/config/config.service';
import { CreateAccountResponse, LoginResponse } from './ezrest.models';
import { AuthStore } from '../../system/auth/auth.store';

export interface ImageUpload {
  data: string;
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class EzrestService {
  private baseUrl = 'https://ezrest.scanapps.net';

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private authStore: AuthStore
  ) {}

  login(email: string, password: string): Promise<LoginResponse> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const params = new HttpParams()
      .set('space', this.configService.space)
      .set('email', email)
      .set('password', password);
    return this.httpClient
      .post<LoginResponse>(`${this.baseUrl}/user/login`, params.toString(), {
        headers,
      })
      .toPromise();
  }

  createAccount(
    email: string,
    password: string
  ): Promise<CreateAccountResponse> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const params = new HttpParams()
      .set('space', this.configService.space)
      .set('email', email)
      .set('password', password);
    return this.httpClient
      .put<CreateAccountResponse>(`${this.baseUrl}/user`, params.toString(), {
        headers,
      })
      .toPromise();
  }

  async globalGet(path: string): Promise<any> {
    return this.httpClient
      .get<any>(this.getBaseUrl(path), {
        headers: this.getDefaultHeader(),
      })
      .toPromise();
  }

  async globalPost(path: string, data: any): Promise<any> {
    return this.httpClient
      .post<any>(this.getBaseUrl(path), data, {
        headers: this.getDefaultHeader(),
      })
      .toPromise();
  }

  async globalPut(path: string, data: any): Promise<any> {
    return this.httpClient
      .put<any>(this.getBaseUrl(path), data, {
        headers: this.getDefaultHeader(),
      })
      .toPromise();
  }

  async userGet(
    path: string,
    privateArea: boolean = true,
    userId?: string
  ): Promise<any> {
    if (!userId) {
      userId = this.authStore.userId;
    }
    return this.globalGet(
      `/users/${userId}/${this.getuserArea(privateArea)}${path}`
    );
  }

  async userPost(
    path: string,
    data: any,
    privateArea: boolean = true,
    userId?: string
  ): Promise<any> {
    if (!userId) {
      userId = this.authStore.userId;
    }
    return this.globalPost(
      `/users/${userId}/${this.getuserArea(privateArea)}${path}`,
      data
    );
  }

  async userPut(
    path: string,
    data: any,
    privateArea: boolean = true,
    userId?: string
  ): Promise<any> {
    if (!userId) {
      userId = this.authStore.userId;
    }
    return this.globalPut(
      `/users/${userId}/${this.getuserArea(privateArea)}${path}`,
      data
    );
  }

  async imagePost(image: ImageUpload): Promise<any> {
    return this.httpClient
      .post<any>(`${this.baseUrl}/img/new`, image, {
        headers: this.getDefaultHeader(),
      })
      .toPromise();
  }

  getImageUrl(name: string) {
    return `${this.baseUrl}/img/${name}`;
  }

  private getDefaultHeader(): HttpHeaders {
    if (this.authStore.token) {
      return new HttpHeaders().set(
        'Authorization',
        `Bearer ${this.authStore.token}`
      );
    }
    return new HttpHeaders();
  }

  private getuserArea(privateArea: boolean): string {
    return privateArea ? 'private' : 'public';
  }

  private getBaseUrl(path: string): string {
    return `${this.baseUrl}/api/german${path}`;
  }

  get isAuthenticated(): boolean {
    return !!this.authStore.token;
  }
}
