import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent } from '../../../framework/base.component';
import { UserService } from '../../../services/business/user/user.service';

@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountFormComponent extends BaseComponent {
  accountForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
    this.accountForm = this.formBuilder.group({
      email: '',
      password: '',
    });
  }

  async onSubmit(accountData: any) {
    await this.userService.createAccount(accountData.email, accountData.password);
  }
}
