import { Injectable } from '@angular/core';
import { EzrestService } from '../../http/ezrest/ezrest.service';
import { BaseService } from '../../../framework/base.service';
import { UserService } from '../user/user.service';
import { NounsHighscoreService } from '../nouns-highscore/nouns-highscore.service';
import { VersionService } from '../../http/version/ezrest.service';
import { OfflineStore } from '../../system/offline/offline.store';
import {
  SyncStateService,
  NounStats,
} from '../../system/sync-state/sync-state.service';

export interface Noun {
  id: number;
  article: string;
  noun: string;
  plural: string;
  english: string;
  en: string;
  da: string;
}

@Injectable({
  providedIn: 'root',
})
export class NounsService extends BaseService {
  private nouns: Noun[];
  private activeNoun: Noun;
  private firstGuessWrong = false;
  private never = false;
  private later = false;
  private badTranslation = false;
  private _ready = false;
  private errorText: string | null = null;
  private serverVersion: string;

  constructor(
    private ezrestService: EzrestService,
    private userService: UserService,
    private nounsHighscoreService: NounsHighscoreService,
    private syncStateService: SyncStateService,
    private versionService: VersionService
  ) {
    super();
    this.registerDependencies(syncStateService);
    this.loadData();

    this.versionService
      .getVersion()
      .then((version) => (this.serverVersion = version));

    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        try {
          const version = await this.versionService.getVersion();
          if (version && version !== this.serverVersion) {
            window.location.reload();
          }
        } catch {}
      }
    });
  }

  private initActiveWord() {
    const activeNounId = this.syncStateService.activeNoun;
    if (activeNounId) {
      this.activeNoun = this.getNounById(activeNounId);
      const nounStats = this.syncStateService.getNounStats(this.activeNoun.id);
      if (nounStats && nounStats.badTranslation) {
        this.badTranslation = true;
      }
    } else {
      this.nextWord();
    }
    this._ready = true;
    this.serviceContentUpdated();
  }

  protected async update(): Promise<void> {
    if (this.syncStateService.ready && this.nouns) {
      if (
        !this._ready ||
        this.activeNoun.id !== this.syncStateService.activeNoun
      ) {
        this.initActiveWord();
      }
    }
  }

  private async loadData() {
    // this.nouns = await this.ezrestService.globalGet('/public/nouns');
    this.nouns = [
      {
        article: 'die',
        noun: 'Milch',
        plural: 'Milch',
        english: 'Milk',
        da: 'mælk',
        en: 'milk',
        id: 1,
      },
      {
        article: 'der',
        noun: 'Stuhl',
        plural: 'Stühle',
        english: 'Chair',
        da: 'stol',
        en: 'chair',
        id: 2,
      },
      {
        article: 'der',
        noun: 'Käse',
        plural: 'Käse',
        english: 'Cheese',
        da: 'ost',
        en: 'cheese',
        id: 3,
      },
      {
        article: 'die',
        noun: 'Wohnung',
        plural: 'Wohnungen',
        english: 'Apartment',
        da: 'flad',
        en: 'flat',
        id: 4,
      },
      {
        article: 'die',
        noun: 'Flasche',
        plural: 'Flaschen',
        english: 'Bottle',
        da: 'flaske',
        en: 'bottle',
        id: 5,
      },
      {
        article: 'die',
        noun: 'Tasse',
        plural: 'Tassen',
        english: 'Cup',
        da: 'Kop',
        en: 'Cup',
        id: 6,
      },
      {
        article: 'das',
        noun: 'Glas',
        plural: 'Gläser',
        english: 'Glass',
        da: 'Glas',
        en: 'Glass',
        id: 7,
      },
      {
        article: 'das',
        noun: 'Papier',
        plural: 'Papiere',
        english: 'Paper',
        da: 'papir',
        en: 'paper',
        id: 8,
      },
      {
        article: 'der',
        noun: 'Kuli',
        plural: 'Kulis',
        english: 'Pen',
        da: 'pen',
        en: 'pen',
        id: 9,
      },
      {
        article: 'der',
        noun: 'Kugelschreiber',
        plural: 'Kugelschreiber',
        english: 'Pen',
        da: 'pen',
        en: 'pen',
        id: 10,
      },
      {
        article: 'das',
        noun: 'Kind',
        plural: 'Kinder',
        english: 'Child',
        da: 'barn',
        en: 'child',
        id: 11,
      },
      {
        article: 'die',
        noun: 'Tochter',
        plural: 'Töchter',
        english: 'Daughter',
        da: 'datter',
        en: 'daughter',
        id: 12,
      },
      {
        article: 'der',
        noun: 'Tee',
        plural: 'Tees',
        english: 'Tea',
        da: 'te',
        en: 'tea',
        id: 13,
      },
      {
        article: 'die',
        noun: 'Kirche',
        plural: 'Kirchen',
        english: 'Church',
        da: 'kirke',
        en: 'church',
        id: 14,
      },
      {
        article: 'der',
        noun: 'Kollege',
        plural: null,
        english: 'Colleague',
        da: 'kollega',
        en: 'colleague',
        id: 15,
      },
      {
        article: 'der',
        noun: 'Supermarkt',
        plural: null,
        english: 'Supermarket',
        da: 'Supermarked',
        en: 'Supermarket',
        id: 16,
      },
      {
        article: 'die',
        noun: 'Apotheken',
        plural: null,
        english: 'Pharmacy',
        da: 'Apoteker',
        en: 'Pharmacies',
        id: 17,
      },
      {
        article: 'die',
        noun: 'Firma',
        plural: null,
        english: 'Company',
        da: 'Selskab',
        en: 'company',
        id: 18,
      },
      {
        article: 'der',
        noun: 'Nachbar',
        plural: null,
        english: 'Neighbor',
        da: 'Nabo',
        en: 'Neighbor',
        id: 19,
      },
      {
        article: 'der',
        noun: 'Lehrer',
        plural: 'Lehrer',
        english: 'Teacher',
        da: 'Lærer',
        en: 'Teacher',
        id: 20,
      },
      {
        article: 'die',
        noun: 'Strasse',
        plural: null,
        english: 'Street',
        da: 'Vej',
        en: 'Road',
        id: 21,
      },
      {
        article: 'das',
        noun: 'Museum',
        plural: null,
        english: 'Museum',
        da: 'museum',
        en: 'museum',
        id: 22,
      },
      {
        article: 'das',
        noun: 'Einkaufzentrum',
        plural: null,
        english: 'Shopping Center',
        da: 'Indkøbscenter',
        en: 'Shopping center',
        id: 23,
      },
      {
        article: 'das',
        noun: 'Rathaus',
        plural: null,
        english: 'Town Hall',
        da: 'Rådhus',
        en: 'town hall',
        id: 24,
      },
      {
        article: 'das',
        noun: 'Kino',
        plural: null,
        english: 'Cinema',
        da: 'biograf',
        en: 'movie theater',
        id: 25,
      },
      {
        article: 'der',
        noun: 'Parkplatz',
        plural: null,
        english: 'Parking Spot',
        da: 'parkeringsplads',
        en: 'parking spot',
        id: 26,
      },
      {
        article: 'die',
        noun: 'Fussgängerzone',
        plural: null,
        english: null,
        da: 'Fodgængerfelt',
        en: 'Pedestrian zone',
        id: 27,
      },
      {
        article: 'der',
        noun: 'Bahnhof',
        plural: null,
        english: 'Railway Station',
        da: 'Jernbanestation',
        en: 'railway station',
        id: 28,
      },
      {
        article: 'die',
        noun: 'Zeitung',
        plural: null,
        english: 'Newspaper',
        da: 'avis',
        en: 'newspaper',
        id: 29,
      },
      {
        article: 'das',
        noun: 'Telefon',
        plural: null,
        english: 'Phone',
        da: 'telefon',
        en: 'phone',
        id: 30,
      },
      {
        article: 'das',
        noun: 'Büro',
        plural: null,
        english: 'Office',
        da: 'kontor',
        en: 'office',
        id: 31,
      },
      {
        article: 'der',
        noun: 'Computer',
        plural: null,
        english: 'Computer',
        da: 'computer',
        en: 'computer',
        id: 32,
      },
      {
        article: 'der',
        noun: 'Wein',
        plural: null,
        english: 'Wine',
        da: 'Vin',
        en: 'Wine',
        id: 33,
      },
      {
        article: 'das',
        noun: 'Foto',
        plural: null,
        english: 'Photo',
        da: 'Foto',
        en: 'photo',
        id: 34,
      },
      {
        article: 'der',
        noun: 'Salat',
        plural: null,
        english: 'Salad',
        da: 'salat',
        en: 'salad',
        id: 35,
      },
      {
        article: 'das',
        noun: 'Fenster',
        plural: null,
        english: 'window',
        da: 'vindue',
        en: 'window',
        id: 36,
      },
      {
        article: 'der',
        noun: 'Tisch',
        plural: 'Tische',
        english: 'table',
        da: 'bord',
        en: 'table',
        id: 37,
      },
      {
        article: 'der',
        noun: 'Gebrauch',
        plural: null,
        english: 'use',
        da: 'brug',
        en: 'use',
        id: 38,
      },
      {
        article: 'das',
        noun: 'Eis',
        plural: null,
        english: 'ice, ice cream',
        da: 'is',
        en: 'ice',
        id: 39,
      },
      {
        article: 'das',
        noun: 'Hackfleisch',
        plural: null,
        english: 'piece of meat for slicing',
        da: 'Hakket kød',
        en: 'Minced meat',
        id: 40,
      },
      {
        article: 'das',
        noun: 'Müsli',
        plural: null,
        english: 'grain cereal, cereal grains',
        da: 'korn',
        en: 'cereal',
        id: 41,
      },
      {
        article: 'das',
        noun: 'Ungeheuer',
        plural: null,
        english: 'monster, monstrosities',
        da: 'Enorm',
        en: 'Enormous',
        id: 42,
      },
      {
        article: 'das',
        noun: 'Abitur',
        plural: null,
        english: 'school exam',
        da: 'Gymnasium',
        en: 'High School',
        id: 43,
      },
      {
        article: 'das',
        noun: 'Alter',
        plural: null,
        english: 'age',
        da: 'Alder',
        en: 'Age',
        id: 44,
      },
      {
        article: 'das',
        noun: 'Amt',
        plural: null,
        english: 'office, bureau',
        da: 'Kontor',
        en: 'Office',
        id: 45,
      },
      {
        article: 'das',
        noun: 'Angebot',
        plural: null,
        english: 'bid, offer',
        da: 'tilbud',
        en: 'offer',
        id: 46,
      },
      {
        article: 'das',
        noun: 'Aquarium',
        plural: null,
        english: 'aquarium',
        da: 'Akvarium',
        en: 'Aquarium',
        id: 47,
      },
      {
        article: 'das',
        noun: 'Asyl',
        plural: null,
        english: 'asylum',
        da: 'asyl',
        en: 'asylum',
        id: 48,
      },
      {
        article: 'das',
        noun: 'Auge',
        plural: null,
        english: 'eye',
        da: 'øje',
        en: 'eye',
        id: 49,
      },
      {
        article: 'das',
        noun: 'Ausland',
        plural: null,
        english: 'overseas',
        da: 'Fremmede lande',
        en: 'foreign countries',
        id: 50,
      },
      {
        article: 'das',
        noun: 'Aussehen',
        plural: null,
        english: 'appearance',
        da: 'Udseende',
        en: 'Appearance',
        id: 51,
      },
      {
        article: 'das',
        noun: 'Autohaus',
        plural: null,
        english: 'car dealership',
        da: 'bilforhandler',
        en: 'car dealer',
        id: 52,
      },
      {
        article: 'das',
        noun: 'Bargeld',
        plural: null,
        english: 'cash money',
        da: 'Kontanter',
        en: 'Cash',
        id: 53,
      },
      {
        article: 'das',
        noun: 'Beet',
        plural: null,
        english: 'bed, patch (of flowers, etc.)',
        da: 'Seng',
        en: 'Bed',
        id: 54,
      },
      {
        article: 'das',
        noun: 'Bein',
        plural: null,
        english: 'leg',
        da: 'ben',
        en: 'leg',
        id: 55,
      },
      {
        article: 'das',
        noun: 'Bekenntnis',
        plural: null,
        english: 'confession',
        da: 'Tilståelse',
        en: 'Confession',
        id: 56,
      },
      {
        article: 'das',
        noun: 'Bekleidungsgeschäft',
        plural: null,
        english: 'clothing store',
        da: 'Tøjbutik',
        en: 'Clothing store',
        id: 57,
      },
      {
        article: 'das',
        noun: 'Besteck',
        plural: null,
        english: 'cutlery, flatware, silverware',
        da: 'bestik',
        en: 'cutlery',
        id: 58,
      },
      {
        article: 'das',
        noun: 'Bier',
        plural: null,
        english: 'beer',
        da: 'øl',
        en: 'beer',
        id: 59,
      },
      {
        article: 'das',
        noun: 'Bild',
        plural: null,
        english: 'photo',
        da: 'billede',
        en: 'picture',
        id: 60,
      },
      {
        article: 'das',
        noun: 'Blatt',
        plural: null,
        english: 'leaf, leaves',
        da: 'ark',
        en: 'sheet',
        id: 61,
      },
      {
        article: 'das',
        noun: 'Blut',
        plural: null,
        english: 'blood',
        da: 'blod',
        en: 'blood',
        id: 62,
      },
      {
        article: 'das',
        noun: 'Blütenblatt',
        plural: null,
        english: 'flower petal',
        da: 'Kronblad',
        en: 'Petal',
        id: 63,
      },
      {
        article: 'das',
        noun: 'Bonbon',
        plural: null,
        english: 'candy, treat',
        da: 'sød',
        en: 'sweet',
        id: 64,
      },
      {
        article: 'das',
        noun: 'Brett',
        plural: null,
        english: 'cutting board',
        da: 'bestyrelse',
        en: 'board',
        id: 65,
      },
      {
        article: 'das',
        noun: 'Brät',
        plural: null,
        english: 'meat',
        da: 'Pølsekød',
        en: 'Sausage meat',
        id: 66,
      },
      {
        article: 'das',
        noun: 'Brötchen',
        plural: null,
        english: 'small bread roll',
        da: 'bolle',
        en: 'bun',
        id: 67,
      },
      {
        article: 'das',
        noun: 'Bund',
        plural: null,
        english: 'a bundle',
        da: 'Føderation',
        en: 'Federation',
        id: 68,
      },
      {
        article: 'das',
        noun: 'Bußgeld',
        plural: null,
        english: 'fine, penalty',
        da: 'bøde',
        en: 'fine',
        id: 69,
      },
      {
        article: 'das',
        noun: 'Bügeleisen',
        plural: null,
        english: 'noun',
        da: 'Jern',
        en: 'Iron',
        id: 70,
      },
      {
        article: 'das',
        noun: 'Chaos',
        plural: null,
        english: 'chaos. Disorder',
        da: 'kaos',
        en: 'chaos',
        id: 71,
      },
      {
        article: 'das',
        noun: 'Croissant',
        plural: null,
        english: 'croissant',
        da: 'croissant',
        en: 'croissant',
        id: 72,
      },
      {
        article: 'das',
        noun: 'Datum',
        plural: null,
        english: 'date',
        da: 'dato',
        en: 'date',
        id: 73,
      },
      {
        article: 'das',
        noun: 'Denken',
        plural: null,
        english: 'thought, thoughts',
        da: 'Tænke',
        en: 'Think',
        id: 74,
      },
      {
        article: 'das',
        noun: 'Dirndl',
        plural: null,
        english: 'bavarian traditional women´s dress',
        da: 'dirndl',
        en: 'Dirndl',
        id: 75,
      },
      {
        article: 'das',
        noun: 'Doppelbett',
        plural: null,
        english: 'double bed',
        da: 'Dobbeltseng',
        en: 'Double bed',
        id: 76,
      },
      {
        article: 'das',
        noun: 'Doppelzimmer',
        plural: null,
        english: 'double room',
        da: 'Dobbeltværelse',
        en: 'Double room',
        id: 77,
      },
      {
        article: 'das',
        noun: 'Dorf',
        plural: null,
        english: 'village',
        da: 'Landsby',
        en: 'Village',
        id: 78,
      },
      {
        article: 'das',
        noun: 'Dreieck',
        plural: null,
        english: 'triangle',
        da: 'trekant',
        en: 'triangle',
        id: 79,
      },
      {
        article: 'das',
        noun: 'Dunkel',
        plural: null,
        english: 'darkness',
        da: 'mørk',
        en: 'dark',
        id: 80,
      },
      {
        article: 'das',
        noun: 'Einzelzimmer',
        plural: null,
        english: 'one bedroom hotel',
        da: 'Enkeltværelse',
        en: 'Single room',
        id: 81,
      },
      {
        article: 'das',
        noun: 'Elektrogeschäft',
        plural: null,
        english: 'electronics store',
        da: 'Elektronik butik',
        en: 'Electronics store',
        id: 82,
      },
      {
        article: 'das',
        noun: 'Erdbeben',
        plural: null,
        english: 'earthquake',
        da: 'jordskælv',
        en: 'earthquake',
        id: 83,
      },
      {
        article: 'das',
        noun: 'Etui',
        plural: null,
        english: 'small case - i.e. Glasses case',
        da: 'sag',
        en: 'case',
        id: 84,
      },
      {
        article: 'das',
        noun: 'Fahrzeug',
        plural: null,
        english: 'vehicle',
        da: 'køretøj',
        en: 'vehicle',
        id: 85,
      },
      {
        article: 'das',
        noun: 'Fasten',
        plural: null,
        english: 'a fast',
        da: 'Hurtig',
        en: 'Fast',
        id: 86,
      },
      {
        article: 'das',
        noun: 'Feld',
        plural: null,
        english: 'field',
        da: 'Mark',
        en: 'field',
        id: 87,
      },
      {
        article: 'das',
        noun: 'Fenster',
        plural: null,
        english: 'window',
        da: 'vindue',
        en: 'window',
        id: 88,
      },
      {
        article: 'das',
        noun: 'Fernweh',
        plural: null,
        english: 'wander lust',
        da: 'Wanderlust',
        en: 'Wanderlust',
        id: 89,
      },
      {
        article: 'das',
        noun: 'Festgewand',
        plural: null,
        english: 'festive garment',
        da: 'Kjole',
        en: 'Dress',
        id: 90,
      },
      {
        article: 'das',
        noun: 'Fett',
        plural: null,
        english: 'fat',
        da: 'fed',
        en: 'fat',
        id: 91,
      },
      {
        article: 'das',
        noun: 'Fieber',
        plural: null,
        english: 'fever',
        da: 'feber',
        en: 'fever',
        id: 92,
      },
      {
        article: 'das',
        noun: 'Formular',
        plural: null,
        english: 'form',
        da: 'form',
        en: 'form',
        id: 93,
      },
      {
        article: 'das',
        noun: 'Fresko',
        plural: null,
        english: 'fresco',
        da: 'freske',
        en: 'fresco',
        id: 94,
      },
      {
        article: 'das',
        noun: 'Fundament',
        plural: null,
        english: 'foundation',
        da: 'fundament',
        en: 'foundation',
        id: 95,
      },
      {
        article: 'das',
        noun: 'Gammelfleisch',
        plural: null,
        english: 'rotten meat, roadkill',
        da: 'Råt kød',
        en: 'Rotten meat',
        id: 96,
      },
      {
        article: 'das',
        noun: 'Garagentor',
        plural: null,
        english: 'garage door',
        da: 'Garageport',
        en: 'Garage door',
        id: 97,
      },
      {
        article: 'das',
        noun: 'Gebirge',
        plural: null,
        english: 'the mountains, mountain range',
        da: 'Bjerge',
        en: 'Mountains',
        id: 98,
      },
      {
        article: 'das',
        noun: 'Gebot',
        plural: null,
        english: 'command, commandments',
        da: 'bud',
        en: 'bid',
        id: 99,
      },
      {
        article: 'das',
        noun: 'Gefieder',
        plural: null,
        english: 'feathers, plumage',
        da: 'fjerdragt',
        en: 'plumage',
        id: 100,
      },
      {
        article: 'das',
        noun: 'Geflügel',
        plural: null,
        english: 'poultry',
        da: 'fjerkræ',
        en: 'poultry',
        id: 101,
      },
      {
        article: 'das',
        noun: 'Gefolge',
        plural: null,
        english: 'entourage',
        da: 'entourage',
        en: 'entourage',
        id: 102,
      },
      {
        article: 'das',
        noun: 'Gefühl',
        plural: null,
        english: 'a feeling, feelings',
        da: 'følelse',
        en: 'feeling',
        id: 103,
      },
      {
        article: 'das',
        noun: 'Gegenteil',
        plural: null,
        english: 'opposite',
        da: 'modsatte',
        en: 'opposite',
        id: 104,
      },
      {
        article: 'das',
        noun: 'Geheimnis',
        plural: null,
        english: 'mystery',
        da: 'hemmelighed',
        en: 'secret',
        id: 105,
      },
      {
        article: 'das',
        noun: 'Gelächter',
        plural: null,
        english: 'laughter',
        da: 'latter',
        en: 'laughter',
        id: 106,
      },
      {
        article: 'das',
        noun: 'Gemüse',
        plural: null,
        english: 'vegetables',
        da: 'grøntsager',
        en: 'vegetables',
        id: 107,
      },
      {
        article: 'das',
        noun: 'Gepäck',
        plural: null,
        english: 'hand luggage',
        da: 'bagage',
        en: 'baggage',
        id: 108,
      },
      {
        article: 'das',
        noun: 'Gericht',
        plural: null,
        english: 'meal, court of justice',
        da: 'fad',
        en: 'dish',
        id: 109,
      },
      {
        article: 'das',
        noun: 'Geschenk',
        plural: null,
        english: 'gift',
        da: 'gave',
        en: 'gift',
        id: 110,
      },
      {
        article: 'das',
        noun: 'Geschirr',
        plural: null,
        english: 'dishes',
        da: 'fade',
        en: 'dishes',
        id: 111,
      },
      {
        article: 'das',
        noun: 'Geschrei',
        plural: null,
        english: 'clamour',
        da: 'råben',
        en: 'shouting',
        id: 112,
      },
      {
        article: 'das',
        noun: 'Gesetz',
        plural: null,
        english: 'law, statute',
        da: 'lov',
        en: 'law',
        id: 113,
      },
      {
        article: 'das',
        noun: 'Gesicht',
        plural: null,
        english: 'face',
        da: 'ansigt',
        en: 'face',
        id: 114,
      },
      {
        article: 'das',
        noun: 'Gesäß',
        plural: null,
        english: 'bottom, seat, posterior',
        da: 'balder',
        en: 'buttocks',
        id: 115,
      },
      {
        article: 'das',
        noun: 'Getreide',
        plural: null,
        english: 'grain, cereals',
        da: 'Korn',
        en: 'Grain',
        id: 116,
      },
      {
        article: 'das',
        noun: 'Getöse',
        plural: null,
        english: 'roar',
        da: 'Brøl',
        en: 'Roar',
        id: 117,
      },
      {
        article: 'das',
        noun: 'Getümmel',
        plural: null,
        english: 'ruckus',
        da: 'oprør',
        en: 'turmoil',
        id: 118,
      },
      {
        article: 'das',
        noun: 'Gewicht',
        plural: null,
        english: 'weight',
        da: 'Vægt',
        en: 'Weight',
        id: 119,
      },
      {
        article: 'das',
        noun: 'Glas',
        plural: null,
        english: 'glass',
        da: 'Glas',
        en: 'Glass',
        id: 120,
      },
      {
        article: 'das',
        noun: 'Glück',
        plural: null,
        english: 'luck',
        da: 'held',
        en: 'luck',
        id: 121,
      },
      {
        article: 'das',
        noun: 'Gold',
        plural: null,
        english: 'gold',
        da: 'guld',
        en: 'gold',
        id: 122,
      },
      {
        article: 'das',
        noun: 'Grundstück',
        plural: null,
        english: 'piece of land, real estate, lot',
        da: 'Ejendom',
        en: 'Property',
        id: 123,
      },
      {
        article: 'das',
        noun: 'Haar',
        plural: null,
        english: 'hair',
        da: 'hår',
        en: 'hair',
        id: 124,
      },
      {
        article: 'das',
        noun: 'Hammerwerfen',
        plural: null,
        english: 'hammer throwing',
        da: 'Hammerkast',
        en: 'Hammer throw',
        id: 125,
      },
      {
        article: 'das',
        noun: 'Handgelenk',
        plural: null,
        english: 'wrist',
        da: 'håndled',
        en: 'wrist',
        id: 126,
      },
      {
        article: 'das',
        noun: 'Handgepäck',
        plural: null,
        english: 'hand luggage',
        da: 'håndbagage',
        en: 'carry-on baggage',
        id: 127,
      },
      {
        article: 'das',
        noun: 'Heimweh',
        plural: null,
        english: 'homesickness',
        da: 'hjemve',
        en: 'Homesickness',
        id: 128,
      },
      {
        article: 'das',
        noun: 'Hemd',
        plural: null,
        english: 'shirt',
        da: 'skjorte',
        en: 'shirt',
        id: 129,
      },
      {
        article: 'das',
        noun: 'Hochhaus',
        plural: null,
        english: 'apartment building',
        da: 'skyskraber',
        en: 'skyscraper',
        id: 130,
      },
      {
        article: 'das',
        noun: 'Holz',
        plural: null,
        english: 'wood',
        da: 'Træ',
        en: 'Wood',
        id: 131,
      },
      {
        article: 'das',
        noun: 'Hähnchen',
        plural: null,
        english: 'chicken',
        da: 'kylling',
        en: 'chicken',
        id: 132,
      },
      {
        article: 'das',
        noun: 'Hörnchen',
        plural: null,
        english: 'croissant',
        da: 'croissant',
        en: 'croissant',
        id: 133,
      },
      {
        article: 'das',
        noun: 'Ideal',
        plural: null,
        english: 'ideal, ideals',
        da: 'ideal',
        en: 'ideal',
        id: 134,
      },
      {
        article: 'das',
        noun: 'Idol',
        plural: null,
        english: 'idol',
        da: 'idol',
        en: 'idol',
        id: 135,
      },
      {
        article: 'das',
        noun: 'Interesse',
        plural: null,
        english: 'interest',
        da: 'interesse',
        en: 'interest',
        id: 136,
      },
      {
        article: 'das',
        noun: 'Interrogativpronomen',
        plural: null,
        english: 'interrogative pronoun',
        da: 'Interrogative pronomen',
        en: 'Interrogative pronouns',
        id: 137,
      },
      {
        article: 'das',
        noun: 'Jahr',
        plural: null,
        english: 'year',
        da: 'år',
        en: 'year',
        id: 138,
      },
      {
        article: 'das',
        noun: 'Joch',
        plural: null,
        english: 'yoke',
        da: 'åg',
        en: 'yoke',
        id: 139,
      },
      {
        article: 'das',
        noun: 'Kalb',
        plural: null,
        english: 'calf',
        da: 'kalv',
        en: 'calf',
        id: 140,
      },
      {
        article: 'das',
        noun: 'Kalbsflesich',
        plural: null,
        english: 'calf meat',
        da: 'Kalvekød',
        en: 'Veal flesh',
        id: 141,
      },
      {
        article: 'das',
        noun: 'Kaninchen',
        plural: null,
        english: 'rabbit, small domesticated',
        da: 'Kaniner',
        en: 'Rabbits',
        id: 142,
      },
      {
        article: 'das',
        noun: 'Kapitel',
        plural: null,
        english: 'chapter',
        da: 'kapitel',
        en: 'chapter',
        id: 143,
      },
      {
        article: 'das',
        noun: 'Kaufhaus',
        plural: null,
        english: 'department store',
        da: 'stormagasin',
        en: 'Department store',
        id: 144,
      },
      {
        article: 'das',
        noun: 'Kilo',
        plural: null,
        english: 'kilo',
        da: 'kilo',
        en: 'kilo',
        id: 145,
      },
      {
        article: 'das',
        noun: 'Kinn',
        plural: null,
        english: 'chin',
        da: 'hage',
        en: 'chin',
        id: 146,
      },
      {
        article: 'das',
        noun: 'Kissen',
        plural: null,
        english: 'cushion, pillow',
        da: 'pude',
        en: 'pillow',
        id: 147,
      },
      {
        article: 'das',
        noun: 'Klavier',
        plural: null,
        english: 'piano',
        da: 'klaver',
        en: 'piano',
        id: 148,
      },
      {
        article: 'das',
        noun: 'Kleingeld',
        plural: null,
        english: 'small change',
        da: 'Lave om',
        en: 'Change',
        id: 149,
      },
      {
        article: 'das',
        noun: 'Knie',
        plural: null,
        english: 'knee',
        da: 'knæ',
        en: 'knee',
        id: 150,
      },
      {
        article: 'das',
        noun: 'Koffein',
        plural: null,
        english: 'caffeine',
        da: 'koffein',
        en: 'caffeine',
        id: 151,
      },
      {
        article: 'das',
        noun: 'Konzert',
        plural: null,
        english: 'concert',
        da: 'koncert',
        en: 'concert',
        id: 152,
      },
      {
        article: 'das',
        noun: 'Kotelett',
        plural: null,
        english: 'cutlet',
        da: 'hugge',
        en: 'chop',
        id: 153,
      },
      {
        article: 'das',
        noun: 'Kraut',
        plural: null,
        english: 'plant',
        da: 'urt',
        en: 'herb',
        id: 154,
      },
      {
        article: 'das',
        noun: 'Kugelstoßen',
        plural: null,
        english: 'shot put (sport)',
        da: 'Kuglestød',
        en: 'Shot put',
        id: 155,
      },
      {
        article: 'das',
        noun: 'Känguru',
        plural: null,
        english: 'kangaroo',
        da: 'kænguru',
        en: 'kangaroo',
        id: 156,
      },
      {
        article: 'das',
        noun: 'Kännchen',
        plural: null,
        english: 'pot, kettle',
        da: 'jug',
        en: 'Jug',
        id: 157,
      },
      {
        article: 'das',
        noun: 'Lammfleisch',
        plural: null,
        english: 'lamb meat',
        da: 'Lamkød',
        en: 'Lamb meat',
        id: 158,
      },
      {
        article: 'das',
        noun: 'Land',
        plural: null,
        english: 'land, country, nation',
        da: 'Land',
        en: 'country',
        id: 159,
      },
      {
        article: 'das',
        noun: 'Leben',
        plural: null,
        english: 'life',
        da: 'Liv',
        en: 'Life',
        id: 160,
      },
      {
        article: 'das',
        noun: 'Lied',
        plural: null,
        english: 'song',
        da: 'sang',
        en: 'song',
        id: 161,
      },
      {
        article: 'das',
        noun: 'Lied',
        plural: null,
        english: 'song, songs',
        da: 'sang',
        en: 'song',
        id: 162,
      },
      {
        article: 'das',
        noun: 'Lob',
        plural: null,
        english: 'praise',
        da: 'ros',
        en: 'praise',
        id: 163,
      },
      {
        article: 'das',
        noun: 'Maul',
        plural: null,
        english: 'mouth, jaws (for animal)',
        da: 'Mund',
        en: 'Mouth',
        id: 164,
      },
      {
        article: 'das',
        noun: 'Medikament',
        plural: null,
        english: 'medication',
        da: 'medicin',
        en: 'drug',
        id: 165,
      },
      {
        article: 'das',
        noun: 'Meer',
        plural: null,
        english: 'sea',
        da: 'hav',
        en: 'sea',
        id: 166,
      },
      {
        article: 'das',
        noun: 'Messer',
        plural: null,
        english: 'knife',
        da: 'kniv',
        en: 'knife',
        id: 167,
      },
      {
        article: 'das',
        noun: 'Metermaß',
        plural: null,
        english: 'ruler, measuring tape',
        da: 'Målemåler',
        en: 'Meter measure',
        id: 168,
      },
      {
        article: 'das',
        noun: 'Mikrofon',
        plural: null,
        english: 'microphone',
        da: 'mikrofon',
        en: 'microphone',
        id: 169,
      },
      {
        article: 'das',
        noun: 'Missverständnis',
        plural: null,
        english: 'misunderstanding',
        da: 'misforståelse',
        en: 'misunderstanding',
        id: 170,
      },
      {
        article: 'das',
        noun: 'Mitleid',
        plural: null,
        english: 'compassion',
        da: 'medlidenhed',
        en: 'pity',
        id: 171,
      },
      {
        article: 'das',
        noun: 'Mäppchen',
        plural: null,
        english: 'small pencil case',
        da: 'penalhus',
        en: 'pencil case',
        id: 172,
      },
      {
        article: 'das',
        noun: 'Möbelhaus',
        plural: null,
        english: 'furniture store',
        da: 'møbelbutik',
        en: 'furniture shop',
        id: 173,
      },
      {
        article: 'das',
        noun: 'Nasenloch',
        plural: null,
        english: 'nostrils',
        da: 'Næsehul',
        en: 'Nose hole',
        id: 174,
      },
      {
        article: 'das',
        noun: 'Nest',
        plural: null,
        english: 'nest',
        da: 'rede',
        en: 'nest',
        id: 175,
      },
      {
        article: 'das',
        noun: 'Netz',
        plural: null,
        english: 'net, the internet',
        da: 'netværk',
        en: 'network',
        id: 176,
      },
      {
        article: 'das',
        noun: 'Obst',
        plural: null,
        english: 'fruit',
        da: 'frugt',
        en: 'fruit',
        id: 177,
      },
      {
        article: 'das',
        noun: 'Ohr',
        plural: null,
        english: 'ear',
        da: 'øre',
        en: 'ear',
        id: 178,
      },
      {
        article: 'das',
        noun: 'Paar',
        plural: null,
        english: 'a pair of something',
        da: 'Par',
        en: 'Pair',
        id: 179,
      },
      {
        article: 'das',
        noun: 'Paket',
        plural: null,
        english: 'parcel (in the mail)',
        da: 'pakke',
        en: 'package',
        id: 180,
      },
      {
        article: 'das',
        noun: 'Paradie',
        plural: null,
        english: 'paradise',
        da: 'Paradis',
        en: 'Paradise',
        id: 181,
      },
      {
        article: 'das',
        noun: 'Pech',
        plural: null,
        english: 'pitch',
        da: 'uheldig',
        en: 'bad luck',
        id: 182,
      },
      {
        article: 'das',
        noun: 'Pflaster',
        plural: null,
        english: 'plaster, poultice',
        da: 'plaster',
        en: 'band Aid',
        id: 183,
      },
      {
        article: 'das',
        noun: 'Pfund',
        plural: null,
        english: '500 grams, .5 Kilo',
        da: 'Ib',
        en: 'lb',
        id: 184,
      },
      {
        article: 'das',
        noun: 'Picknick',
        plural: null,
        english: 'picnic',
        da: 'picnic',
        en: 'picnic',
        id: 185,
      },
      {
        article: 'das',
        noun: 'Plätzchen',
        plural: null,
        english: 'biscuit',
        da: 'cookie',
        en: 'cookie',
        id: 186,
      },
      {
        article: 'das',
        noun: 'Polen',
        plural: null,
        english: 'poland',
        da: 'Polen',
        en: 'Poland',
        id: 187,
      },
      {
        article: 'das',
        noun: 'Püree',
        plural: null,
        english: 'puree, i.e. Kartoffelpüree - mashed potatoes',
        da: 'puré',
        en: 'puree',
        id: 188,
      },
      {
        article: 'das',
        noun: 'Quadrat',
        plural: null,
        english: 'square',
        da: 'firkant',
        en: 'square',
        id: 189,
      },
      {
        article: 'das',
        noun: 'Rankengewächs',
        plural: null,
        english: 'creeping plant',
        da: 'Vine',
        en: 'Vine',
        id: 190,
      },
      {
        article: 'das',
        noun: 'Rechteck',
        plural: null,
        english: 'rectangle',
        da: 'rektangel',
        en: 'rectangle',
        id: 191,
      },
      {
        article: 'das',
        noun: 'Regal',
        plural: null,
        english: 'bookshelves',
        da: 'hylde',
        en: 'shelf',
        id: 192,
      },
      {
        article: 'das',
        noun: 'Reihenhaus',
        plural: null,
        english: 'terraced house, row house',
        da: 'rækkehus',
        en: 'townhouse',
        id: 193,
      },
      {
        article: 'das',
        noun: 'Reisebüro',
        plural: null,
        english: 'travel agent',
        da: 'Rejsebureau',
        en: 'Travel agency',
        id: 194,
      },
      {
        article: 'das',
        noun: 'Restaurant',
        plural: null,
        english: 'restaurant',
        da: 'restaurant',
        en: 'restaurant',
        id: 195,
      },
      {
        article: 'das',
        noun: 'Rezept',
        plural: null,
        english: 'prescription',
        da: 'opskrift',
        en: 'recipe',
        id: 196,
      },
      {
        article: 'das',
        noun: 'Rhabarber',
        plural: null,
        english: 'rhubarb',
        da: 'rabarber',
        en: 'rhubarb',
        id: 197,
      },
      {
        article: 'das',
        noun: 'Rindfleisch',
        plural: null,
        english: 'beef',
        da: 'bøf',
        en: 'beef',
        id: 198,
      },
      {
        article: 'das',
        noun: 'Rohr',
        plural: null,
        english: 'pipe - water pipe, etc.',
        da: 'rør',
        en: 'pipe',
        id: 199,
      },
      {
        article: 'das',
        noun: 'Rätsel',
        plural: null,
        english: 'riddle, puzzle',
        da: 'mysterium',
        en: 'mystery',
        id: 200,
      },
      {
        article: 'das',
        noun: 'Salz',
        plural: null,
        english: 'salt',
        da: 'salt',
        en: 'salt',
        id: 201,
      },
      {
        article: 'das',
        noun: 'Scheitern',
        plural: null,
        english: 'collapse',
        da: 'svigte',
        en: 'fail',
        id: 202,
      },
      {
        article: 'das',
        noun: 'Schifferklavier',
        plural: null,
        english: 'accordion',
        da: 'Schiffer klaver',
        en: 'Schiffer piano',
        id: 203,
      },
      {
        article: 'das',
        noun: 'Schloss',
        plural: null,
        english: 'lock, castle',
        da: 'låse',
        en: 'lock',
        id: 204,
      },
      {
        article: 'das',
        noun: 'Schmuckgeschäft',
        plural: null,
        english: 'jewelry store',
        da: 'Guldsmed',
        en: 'Jewelry store',
        id: 205,
      },
      {
        article: 'das',
        noun: 'Schnitzel',
        plural: null,
        english: 'pork cutlett',
        da: 'schnitzel',
        en: 'schnitzel',
        id: 206,
      },
      {
        article: 'das',
        noun: 'Schreibwarengeschäft',
        plural: null,
        english: 'stationery store',
        da: 'Papirvarer butik',
        en: 'Stationery store',
        id: 207,
      },
      {
        article: 'das',
        noun: 'Schwein',
        plural: null,
        english: 'pig, hog',
        da: 'svin',
        en: 'pig',
        id: 208,
      },
      {
        article: 'das',
        noun: 'Schweinefleisch',
        plural: null,
        english: 'pork meat',
        da: 'svinekød',
        en: 'pork meat',
        id: 209,
      },
      {
        article: 'das',
        noun: 'Schwimmbad',
        plural: null,
        english: 'swimming pool',
        da: 'svømmepøl',
        en: 'swimming pool',
        id: 210,
      },
      {
        article: 'das',
        noun: 'Seil',
        plural: null,
        english: 'rope, line, climbing line',
        da: 'reb',
        en: 'rope',
        id: 211,
      },
      {
        article: 'das',
        noun: 'Sekretariat',
        plural: null,
        english: 'office',
        da: 'kontor',
        en: 'office',
        id: 212,
      },
      {
        article: 'das',
        noun: 'Shampoo',
        plural: null,
        english: 'shampoo',
        da: 'shampoo',
        en: 'shampoo',
        id: 213,
      },
      {
        article: 'das',
        noun: 'Silber',
        plural: null,
        english: 'silver',
        da: 'sølv',
        en: 'silver',
        id: 214,
      },
      {
        article: 'das',
        noun: 'Sofa',
        plural: null,
        english: 'sofa, couch',
        da: 'sofa',
        en: 'sofa',
        id: 215,
      },
      {
        article: 'das',
        noun: 'Sonderangebot',
        plural: null,
        english: 'special offer',
        da: 'Special tilbud',
        en: 'special offer',
        id: 216,
      },
      {
        article: 'das',
        noun: 'Speerwerfen',
        plural: null,
        english: 'spear throwing',
        da: 'Javelin kaster',
        en: 'Javelin throwing',
        id: 217,
      },
      {
        article: 'das',
        noun: 'Spiegelei',
        plural: null,
        english: 'fried egg',
        da: 'stegt æg',
        en: 'fried egg',
        id: 218,
      },
      {
        article: 'das',
        noun: 'Spielzeug',
        plural: null,
        english: 'playtoy',
        da: 'legetøj',
        en: 'toy',
        id: 219,
      },
      {
        article: 'das',
        noun: 'Ständchen',
        plural: null,
        english: 'seranade',
        da: 'serenade',
        en: 'serenade',
        id: 220,
      },
      {
        article: 'das',
        noun: 'Stück',
        plural: null,
        english: 'piece',
        da: 'stykke',
        en: 'piece',
        id: 221,
      },
      {
        article: 'das',
        noun: 'Talent',
        plural: null,
        english: 'talent, capacity, bib. - measure of weight',
        da: 'talent',
        en: 'talent',
        id: 222,
      },
      {
        article: 'das',
        noun: 'Telefon',
        plural: null,
        english: 'telephone',
        da: 'telefon',
        en: 'phone',
        id: 223,
      },
      {
        article: 'das',
        noun: 'Teppichgeschäft',
        plural: null,
        english: 'carpet store',
        da: 'Tæppe butik',
        en: 'Carpet store',
        id: 224,
      },
      {
        article: 'das',
        noun: 'Thema',
        plural: null,
        english: 'theme, topic',
        da: 'tema',
        en: 'theme',
        id: 225,
      },
      {
        article: 'das',
        noun: 'Thermometer',
        plural: null,
        english: 'thermometer',
        da: 'termometer',
        en: 'thermometer',
        id: 226,
      },
      {
        article: 'das',
        noun: 'Ticket',
        plural: null,
        english: 'ticket',
        da: 'billet',
        en: 'ticket',
        id: 227,
      },
      {
        article: 'das',
        noun: 'Tor',
        plural: null,
        english: 'door, sport - goal',
        da: 'Port',
        en: 'gate',
        id: 228,
      },
      {
        article: 'das',
        noun: 'Treffe',
        plural: null,
        english: 'meeting',
        da: 'Møde',
        en: 'Meet',
        id: 229,
      },
      {
        article: 'das',
        noun: 'Triebwerk',
        plural: null,
        english: 'engine, power plant',
        da: 'motor',
        en: 'Engine',
        id: 230,
      },
      {
        article: 'das',
        noun: 'Trinkgeld',
        plural: null,
        english: 'a tip',
        da: 'Tip',
        en: 'Tip',
        id: 231,
      },
      {
        article: 'das',
        noun: 'Tuch',
        plural: null,
        english: 'cloth, kerchief',
        da: 'klæde',
        en: 'cloth',
        id: 232,
      },
      {
        article: 'das',
        noun: 'Turnen',
        plural: null,
        english: 'gymnastics',
        da: 'lave gymnastik',
        en: 'do gymnastics',
        id: 233,
      },
      {
        article: 'das',
        noun: 'Turnier',
        plural: null,
        english: 'tournament, show',
        da: 'konkurrence',
        en: 'competition',
        id: 234,
      },
      {
        article: 'das',
        noun: 'Ufer',
        plural: null,
        english: 'bank of river, shoreline',
        da: 'kyst',
        en: 'shore',
        id: 235,
      },
      {
        article: 'das',
        noun: 'Unkraut',
        plural: null,
        english: 'weed',
        da: 'ukrudtsplante',
        en: 'weed',
        id: 236,
      },
      {
        article: 'das',
        noun: 'Unrecht',
        plural: null,
        english: 'injustice',
        da: 'Forkert',
        en: 'Wrong',
        id: 237,
      },
      {
        article: 'das',
        noun: 'Verbrechen',
        plural: null,
        english: 'crime',
        da: 'forbrydelse',
        en: 'crime',
        id: 238,
      },
      {
        article: 'das',
        noun: 'Vergehen',
        plural: null,
        english: 'offense',
        da: 'Forbrydelse',
        en: 'Offense',
        id: 239,
      },
      {
        article: 'das',
        noun: 'Verhältnis',
        plural: null,
        english: 'relationship, affair',
        da: 'forhold',
        en: 'relationship',
        id: 240,
      },
      {
        article: 'das',
        noun: 'Verkehrsmittel',
        plural: null,
        english: 'transportation',
        da: 'Transportmidler',
        en: 'Transportation',
        id: 241,
      },
      {
        article: 'das',
        noun: 'Verschwinden',
        plural: null,
        english: 'disappearance',
        da: 'Forsvinde',
        en: 'Disappear',
        id: 242,
      },
      {
        article: 'das',
        noun: 'Vertrauen',
        plural: null,
        english: 'trust',
        da: 'tillid',
        en: 'trust',
        id: 243,
      },
      {
        article: 'das',
        noun: 'Video',
        plural: null,
        english: 'video',
        da: 'video',
        en: 'Video',
        id: 244,
      },
      {
        article: 'das',
        noun: 'Vieh',
        plural: null,
        english: 'cattle',
        da: 'kvæg',
        en: 'cattle',
        id: 245,
      },
      {
        article: 'das',
        noun: 'Viertel',
        plural: null,
        english: 'quarter',
        da: 'kvarter',
        en: 'quarter',
        id: 246,
      },
      {
        article: 'das',
        noun: 'Volk',
        plural: null,
        english: 'people, nation',
        da: 'mennesker',
        en: 'people',
        id: 247,
      },
      {
        article: 'das',
        noun: 'Vortschritt',
        plural: null,
        english: 'progress',
        da: 'Fremskridt',
        en: 'Progress',
        id: 248,
      },
      {
        article: 'das',
        noun: 'Wachstum',
        plural: null,
        english: 'growth',
        da: 'vækst',
        en: 'growth',
        id: 249,
      },
      {
        article: 'das',
        noun: 'Wasser',
        plural: null,
        english: 'water',
        da: 'vand',
        en: 'water',
        id: 250,
      },
      {
        article: 'das',
        noun: 'Wechselgeld',
        plural: null,
        english: 'change',
        da: 'Lave om',
        en: 'Change',
        id: 251,
      },
      {
        article: 'das',
        noun: 'Werkzeug',
        plural: null,
        english: 'work tools',
        da: 'Værktøj',
        en: 'Tool',
        id: 252,
      },
      {
        article: 'das',
        noun: 'Wesen',
        plural: null,
        english: 'being, nature',
        da: 'væsener',
        en: 'Beings',
        id: 253,
      },
      {
        article: 'das',
        noun: 'Wissen',
        plural: null,
        english: 'knowledge',
        da: 'Viden',
        en: 'Knowledge',
        id: 254,
      },
      {
        article: 'das',
        noun: 'Wunder',
        plural: null,
        english: 'wonder , marvel',
        da: 'undre mig',
        en: 'wonder',
        id: 255,
      },
      {
        article: 'das',
        noun: 'Wurzelverzeichnis',
        plural: null,
        english: 'root directory',
        da: 'Rodkatalog',
        en: 'Root directory',
        id: 256,
      },
      {
        article: 'das',
        noun: 'Zentrum',
        plural: null,
        english: 'center',
        da: 'centrum',
        en: 'center',
        id: 257,
      },
      {
        article: 'das',
        noun: 'Zimmer',
        plural: null,
        english: 'room',
        da: 'værelse',
        en: 'room',
        id: 258,
      },
      {
        article: 'das',
        noun: 'Zollkontrolle',
        plural: null,
        english: 'customs in airport',
        da: 'Toldkontrol',
        en: 'Customs control',
        id: 259,
      },
      {
        article: 'das',
        noun: 'Öl',
        plural: null,
        english: 'oil',
        da: 'olie',
        en: 'oil',
        id: 260,
      },
      {
        article: 'der',
        noun: 'ABA',
        plural: null,
        english: 'answering machine',
        da: 'ABA',
        en: 'ABA',
        id: 261,
      },
      {
        article: 'der',
        noun: 'Abflug',
        plural: null,
        english: 'departure',
        da: 'afgang',
        en: 'departure',
        id: 262,
      },
      {
        article: 'der',
        noun: 'Abriss',
        plural: null,
        english: 'demolition',
        da: 'nedrivning',
        en: 'demolition',
        id: 263,
      },
      {
        article: 'der',
        noun: 'Abschied',
        plural: null,
        english: 'goodbye, farewell',
        da: 'farvel',
        en: 'farewell',
        id: 264,
      },
      {
        article: 'der',
        noun: 'Abstand',
        plural: null,
        english: 'distance',
        da: 'afstand',
        en: 'distance',
        id: 265,
      },
      {
        article: 'der',
        noun: 'Acker',
        plural: null,
        english: 'field',
        da: 'Mark',
        en: 'Field',
        id: 266,
      },
      {
        article: 'der',
        noun: 'Affe',
        plural: null,
        english: 'monkey',
        da: 'abe',
        en: 'monkey',
        id: 267,
      },
      {
        article: 'der',
        noun: 'Aktenkoffer',
        plural: null,
        english: 'attache case',
        da: 'Mappe',
        en: 'Briefcase',
        id: 268,
      },
      {
        article: 'der',
        noun: 'Aktenordner',
        plural: null,
        english: 'file, file cabinet, etc.',
        da: 'Fil og mappe',
        en: 'File folder',
        id: 269,
      },
      {
        article: 'der',
        noun: 'Allgemeinarzt',
        plural: null,
        english: 'general practice doctor',
        da: 'Læge',
        en: 'General practitioner',
        id: 270,
      },
      {
        article: 'der',
        noun: 'Anblick',
        plural: null,
        english: 'sight, a look',
        da: 'syn',
        en: 'sight',
        id: 271,
      },
      {
        article: 'der',
        noun: 'Anfang',
        plural: null,
        english: 'the beginning',
        da: 'Starten',
        en: 'Beginning',
        id: 272,
      },
      {
        article: 'der',
        noun: 'Angriff',
        plural: null,
        english: 'attack, raid',
        da: 'angreb',
        en: 'attack',
        id: 273,
      },
      {
        article: 'der',
        noun: 'Anlass',
        plural: null,
        english: 'motive, cause',
        da: 'lejlighed',
        en: 'occasion',
        id: 274,
      },
      {
        article: 'der',
        noun: 'Anruf',
        plural: null,
        english: 'phone call',
        da: 'Opkald',
        en: 'Call',
        id: 275,
      },
      {
        article: 'der',
        noun: 'Anschluss',
        plural: null,
        english: 'connection, (water) supply',
        da: 'forbindelse',
        en: 'connection',
        id: 276,
      },
      {
        article: 'der',
        noun: 'Anspruch',
        plural: null,
        english: 'claim (ins.)',
        da: 'påstand',
        en: 'claim',
        id: 277,
      },
      {
        article: 'der',
        noun: 'Anwohner',
        plural: null,
        english: 'resident',
        da: 'lokale beboere',
        en: 'local residents',
        id: 278,
      },
      {
        article: 'der',
        noun: 'Anästhesist',
        plural: null,
        english: 'anathesiologist',
        da: 'anæstesilæge',
        en: 'anesthetist',
        id: 279,
      },
      {
        article: 'der',
        noun: 'Apfel',
        plural: null,
        english: 'apple',
        da: 'Æble',
        en: 'Apple',
        id: 280,
      },
      {
        article: 'der',
        noun: 'Apparat',
        plural: null,
        english: 'apparatus, Gadget, Machine',
        da: 'apparat',
        en: 'apparatus',
        id: 281,
      },
      {
        article: 'der',
        noun: 'Arm',
        plural: null,
        english: 'arm',
        da: 'fattige',
        en: 'poor',
        id: 282,
      },
      {
        article: 'der',
        noun: 'Ast',
        plural: null,
        english: 'branch of a tree',
        da: 'Afdeling',
        en: 'Branch',
        id: 283,
      },
      {
        article: 'der',
        noun: 'Asylant',
        plural: null,
        english: 'asylum seeker',
        da: 'Asylansøger',
        en: 'Asylum seeker',
        id: 284,
      },
      {
        article: 'der',
        noun: 'Ausflug',
        plural: null,
        english: 'outing, field trip',
        da: 'Udflugt',
        en: 'Excursion',
        id: 285,
      },
      {
        article: 'der',
        noun: 'Auswander',
        plural: null,
        english: 'emigrant',
        da: 'emigranter',
        en: 'Emigrants',
        id: 286,
      },
      {
        article: 'der',
        noun: 'Ausweis',
        plural: null,
        english: 'ID',
        da: 'ID-kort',
        en: 'identification card',
        id: 287,
      },
      {
        article: 'der',
        noun: 'Badeanzug',
        plural: null,
        english: 'bathing suit',
        da: 'badedragt',
        en: 'swimsuit',
        id: 288,
      },
      {
        article: 'der',
        noun: 'Bahnhof',
        plural: null,
        english: 'train station',
        da: 'Jernbanestation',
        en: 'railway station',
        id: 289,
      },
      {
        article: 'der',
        noun: 'Balkon',
        plural: null,
        english: 'balcony',
        da: 'balkon',
        en: 'balcony',
        id: 290,
      },
      {
        article: 'der',
        noun: 'Ball',
        plural: null,
        english: 'ball',
        da: 'bold',
        en: 'ball',
        id: 291,
      },
      {
        article: 'der',
        noun: 'Barren',
        plural: null,
        english: 'the parallel bars',
        da: 'ingot',
        en: 'Ingot',
        id: 292,
      },
      {
        article: 'der',
        noun: 'Bart',
        plural: null,
        english: 'beard',
        da: 'skæg',
        en: 'beard',
        id: 293,
      },
      {
        article: 'der',
        noun: 'Bastler',
        plural: null,
        english: 'craftsman, do it yourselfer',
        da: 'hobbyist',
        en: 'Hobbyist',
        id: 294,
      },
      {
        article: 'der',
        noun: 'Bauch',
        plural: null,
        english: 'stomach',
        da: 'bug',
        en: 'belly',
        id: 295,
      },
      {
        article: 'der',
        noun: 'Baum',
        plural: null,
        english: 'tree',
        da: 'træ',
        en: 'tree',
        id: 296,
      },
      {
        article: 'der',
        noun: 'Becher',
        plural: null,
        english: 'beaker, plastic cup',
        da: 'kopper',
        en: 'cups',
        id: 297,
      },
      {
        article: 'der',
        noun: 'Beginn',
        plural: null,
        english: 'beginning, start',
        da: 'Starten',
        en: 'Beginning',
        id: 298,
      },
      {
        article: 'der',
        noun: 'Beruf',
        plural: null,
        english: 'occupation, career',
        da: 'job',
        en: 'job',
        id: 299,
      },
      {
        article: 'der',
        noun: 'Besitz',
        plural: null,
        english: 'resources, assets',
        da: 'besiddelse',
        en: 'possession',
        id: 300,
      },
      {
        article: 'der',
        noun: 'Beweis',
        plural: null,
        english: 'proof',
        da: 'bevis',
        en: 'proof',
        id: 301,
      },
      {
        article: 'der',
        noun: 'Bezwinger',
        plural: null,
        english: 'conqueror',
        da: 'Erobrer',
        en: 'Conqueror',
        id: 302,
      },
      {
        article: 'der',
        noun: 'Blitz',
        plural: null,
        english: 'lightning, flash',
        da: 'lyn',
        en: 'lightning',
        id: 303,
      },
      {
        article: 'der',
        noun: 'Blumenkohl',
        plural: null,
        english: 'cauliflower',
        da: 'blomkål',
        en: 'cauliflower',
        id: 304,
      },
      {
        article: 'der',
        noun: 'Blumenladen',
        plural: null,
        english: 'flower shop',
        da: 'blomsterbutik',
        en: 'flower shop',
        id: 305,
      },
      {
        article: 'der',
        noun: 'Blumenstrauß',
        plural: null,
        english: 'bunch of flowers',
        da: 'Bunch af blomster',
        en: 'Bunch of flowers',
        id: 306,
      },
      {
        article: 'der',
        noun: 'Boden',
        plural: null,
        english: 'ground, dirt',
        da: 'jord',
        en: 'ground',
        id: 307,
      },
      {
        article: 'der',
        noun: 'Brei',
        plural: null,
        english: 'mash, puree',
        da: 'Grød',
        en: 'Porridge',
        id: 308,
      },
      {
        article: 'der',
        noun: 'Briefkasten',
        plural: null,
        english: 'mailbox',
        da: 'Postkasse',
        en: 'Mailbox',
        id: 309,
      },
      {
        article: 'der',
        noun: 'Bruder',
        plural: null,
        english: 'brother',
        da: 'Brothers',
        en: 'Brothers',
        id: 310,
      },
      {
        article: 'der',
        noun: 'Buckel',
        plural: null,
        english: 'hunchback',
        da: 'pukkelhval',
        en: 'humpback',
        id: 311,
      },
      {
        article: 'der',
        noun: 'Buddhismus',
        plural: null,
        english: 'buddhism',
        da: 'buddhisme',
        en: 'Buddhism',
        id: 312,
      },
      {
        article: 'der',
        noun: 'Bush',
        plural: null,
        english: 'bush (strauch)',
        da: 'Busk',
        en: 'Bush',
        id: 313,
      },
      {
        article: 'der',
        noun: 'Chef',
        plural: null,
        english: 'boss',
        da: 'chef',
        en: 'boss',
        id: 314,
      },
      {
        article: 'der',
        noun: 'Chinese',
        plural: null,
        english: 'chinese (people)',
        da: 'kinesisk',
        en: 'Chinese',
        id: 315,
      },
      {
        article: 'der',
        noun: 'Chirurg',
        plural: null,
        english: 'surgeon',
        da: 'kirurg',
        en: 'surgeon',
        id: 316,
      },
      {
        article: 'der',
        noun: 'Christ',
        plural: null,
        english: 'Christian',
        da: 'kristen',
        en: 'Christian',
        id: 317,
      },
      {
        article: 'der',
        noun: 'Computer',
        plural: null,
        english: 'computer',
        da: 'computer',
        en: 'computer',
        id: 318,
      },
      {
        article: 'der',
        noun: 'Dachboden',
        plural: null,
        english: 'attic',
        da: 'Attic',
        en: 'Attic',
        id: 319,
      },
      {
        article: 'der',
        noun: 'Dampf',
        plural: null,
        english: 'steam, vapor',
        da: 'damp',
        en: 'steam',
        id: 320,
      },
      {
        article: 'der',
        noun: 'Detektiv',
        plural: null,
        english: 'detective',
        da: 'detektiv',
        en: 'detective',
        id: 321,
      },
      {
        article: 'der',
        noun: 'Dieb',
        plural: null,
        english: 'thief',
        da: 'Tyv',
        en: 'Thief',
        id: 322,
      },
      {
        article: 'der',
        noun: 'Dirigent',
        plural: null,
        english: 'conductor',
        da: 'leder',
        en: 'conductor',
        id: 323,
      },
      {
        article: 'der',
        noun: 'Dirigentenstab',
        plural: null,
        english: 'conductors baton',
        da: 'Ledende personale',
        en: 'Conducting staff',
        id: 324,
      },
      {
        article: 'der',
        noun: 'Dom',
        plural: null,
        english: 'cathedral',
        da: 'Katedral',
        en: 'Cathedral',
        id: 325,
      },
      {
        article: 'der',
        noun: 'Dorn',
        plural: null,
        english: 'thorn',
        da: 'dorn',
        en: 'mandrel',
        id: 326,
      },
      {
        article: 'der',
        noun: 'Drache',
        plural: null,
        english: 'dragon',
        da: 'Trække på',
        en: 'Dragon',
        id: 327,
      },
      {
        article: 'der',
        noun: 'Drachen',
        plural: null,
        english: 'a kite, a glider',
        da: 'Dragons',
        en: 'Dragons',
        id: 328,
      },
      {
        article: 'der',
        noun: 'Dualismus',
        plural: null,
        english: 'dualism',
        da: 'dualisme',
        en: 'dualism',
        id: 329,
      },
      {
        article: 'der',
        noun: 'Dung',
        plural: null,
        english: 'dung, manure',
        da: 'Gødning',
        en: 'Manure',
        id: 330,
      },
      {
        article: 'der',
        noun: 'Dünger',
        plural: null,
        english: 'fertilizer',
        da: 'gødning',
        en: 'fertilizer',
        id: 331,
      },
      {
        article: 'der',
        noun: 'Düsenantrieb',
        plural: null,
        english: 'jet propulsion',
        da: 'Dysedrev',
        en: 'Nozzle drive',
        id: 332,
      },
      {
        article: 'der',
        noun: 'Düsenjäger',
        plural: null,
        english: 'fighter jet',
        da: 'Jagerfly',
        en: 'Jet fighter',
        id: 333,
      },
      {
        article: 'der',
        noun: 'Edelstein',
        plural: null,
        english: 'precious stone',
        da: 'ædelsten',
        en: 'Gemstone',
        id: 334,
      },
      {
        article: 'der',
        noun: 'Einkaufszettel',
        plural: null,
        english: 'shopping list',
        da: 'Indkøbsliste',
        en: 'Shopping list',
        id: 335,
      },
      {
        article: 'der',
        noun: 'Einwander',
        plural: null,
        english: 'immigrant',
        da: 'Indvandrere',
        en: 'Immigrants',
        id: 336,
      },
      {
        article: 'der',
        noun: 'Eiskunstlauf',
        plural: null,
        english: 'figure skating',
        da: 'kunstskøjteløb',
        en: 'figure skating',
        id: 337,
      },
      {
        article: 'der',
        noun: 'Ellbogen',
        plural: null,
        english: 'elbow',
        da: 'albue',
        en: 'elbow',
        id: 338,
      },
      {
        article: 'der',
        noun: 'Eltern',
        plural: null,
        english: 'parents',
        da: 'forældre',
        en: 'parents',
        id: 339,
      },
      {
        article: 'der',
        noun: 'Esel',
        plural: null,
        english: 'donkey',
        da: 'røv',
        en: 'ass',
        id: 340,
      },
      {
        article: 'der',
        noun: 'Faden',
        plural: null,
        english: 'thread',
        da: 'tråd',
        en: 'thread',
        id: 341,
      },
      {
        article: 'der',
        noun: 'Fahrradabstellplatz',
        plural: null,
        english: 'bicycle parking place',
        da: 'Cykelparkeringsplads',
        en: 'Bicycle parking space',
        id: 342,
      },
      {
        article: 'der',
        noun: 'Fallschirm',
        plural: null,
        english: 'parachute',
        da: 'Faldskærm',
        en: 'Parachute',
        id: 343,
      },
      {
        article: 'der',
        noun: 'Familienstand',
        plural: null,
        english: 'family status',
        da: 'Civilstand',
        en: 'marital status',
        id: 344,
      },
      {
        article: 'der',
        noun: 'Fehler',
        plural: null,
        english: 'mistake',
        da: 'fejl',
        en: 'error',
        id: 345,
      },
      {
        article: 'der',
        noun: 'Feind',
        plural: null,
        english: 'enemy',
        da: 'fjende',
        en: 'enemy',
        id: 346,
      },
      {
        article: 'der',
        noun: 'Feldrand',
        plural: null,
        english: 'edge of a field',
        da: 'Feltkant',
        en: 'Field edge',
        id: 347,
      },
      {
        article: 'der',
        noun: 'Finger',
        plural: null,
        english: 'finger',
        da: 'finger',
        en: 'finger',
        id: 348,
      },
      {
        article: 'der',
        noun: 'Fingerabdruck',
        plural: null,
        english: 'fingerprint',
        da: 'fingeraftryk',
        en: 'fingerprint',
        id: 349,
      },
      {
        article: 'der',
        noun: 'Fingerhut',
        plural: null,
        english: 'thimble',
        da: 'fingerbøl',
        en: 'foxglove',
        id: 350,
      },
      {
        article: 'der',
        noun: 'Fingernagel',
        plural: null,
        english: 'fingernail',
        da: 'Fingernegl',
        en: 'Fingernail',
        id: 351,
      },
      {
        article: 'der',
        noun: 'Fisch',
        plural: null,
        english: 'fish',
        da: 'fisk',
        en: 'fish',
        id: 352,
      },
      {
        article: 'der',
        noun: 'Flaschenöffner',
        plural: null,
        english: 'bottle opener',
        da: 'flaskeåbner',
        en: 'bottle opener',
        id: 353,
      },
      {
        article: 'der',
        noun: 'Fleck',
        plural: null,
        english: 'spot',
        da: 'få øje på',
        en: 'spot',
        id: 354,
      },
      {
        article: 'der',
        noun: 'Flug',
        plural: null,
        english: 'flight',
        da: 'flyvningen',
        en: 'flight',
        id: 355,
      },
      {
        article: 'der',
        noun: 'Flugbegleiter',
        plural: null,
        english: 'airline stewardess',
        da: 'Stewardesse',
        en: 'Flight attendant',
        id: 356,
      },
      {
        article: 'der',
        noun: 'Flughafen',
        plural: null,
        english: 'airport',
        da: 'Lufthavn',
        en: 'Airport',
        id: 357,
      },
      {
        article: 'der',
        noun: 'Flur',
        plural: null,
        english: 'hallway in a house',
        da: 'Hal',
        en: 'Hall',
        id: 358,
      },
      {
        article: 'der',
        noun: 'Fluss',
        plural: null,
        english: 'river',
        da: 'flyde',
        en: 'flow',
        id: 359,
      },
      {
        article: 'der',
        noun: 'Freimut',
        plural: null,
        english: 'frankness',
        da: 'åbenhed',
        en: 'Frankness',
        id: 360,
      },
      {
        article: 'der',
        noun: 'Frevel',
        plural: null,
        english: 'iniquity, outrage',
        da: 'Skandale',
        en: 'Outrage',
        id: 361,
      },
      {
        article: 'der',
        noun: 'Frühaufsteher',
        plural: null,
        english: 'early riser, hard working person',
        da: 'Morgenmenneske',
        en: 'Early riser',
        id: 362,
      },
      {
        article: 'der',
        noun: 'Fuchs',
        plural: null,
        english: 'fox',
        da: 'Ræv',
        en: 'Fox',
        id: 363,
      },
      {
        article: 'der',
        noun: 'Fuß',
        plural: null,
        english: 'foot',
        da: 'fod',
        en: 'foot',
        id: 364,
      },
      {
        article: 'der',
        noun: 'Fußgänger',
        plural: null,
        english: 'pedestrian',
        da: 'fodgænger',
        en: 'pedestrian',
        id: 365,
      },
      {
        article: 'der',
        noun: 'Fön',
        plural: null,
        english: 'hairdryer',
        da: 'hårtørrer',
        en: 'hairdryer',
        id: 366,
      },
      {
        article: 'der',
        noun: 'Führerschein',
        plural: null,
        english: 'driver´s license',
        da: 'Kørekort',
        en: 'Driving license',
        id: 367,
      },
      {
        article: 'der',
        noun: 'Fürst',
        plural: null,
        english: 'prince, princess',
        da: 'Prins',
        en: 'Prince',
        id: 368,
      },
      {
        article: 'der',
        noun: 'Gammel',
        plural: null,
        english: 'rubbish',
        da: 'Vagabond',
        en: 'Bum',
        id: 369,
      },
      {
        article: 'der',
        noun: 'Gang',
        plural: null,
        english: 'walkway, corridor',
        da: 'korridor',
        en: 'corridor',
        id: 370,
      },
      {
        article: 'der',
        noun: 'Garten',
        plural: null,
        english: 'garden',
        da: 'have',
        en: 'garden',
        id: 371,
      },
      {
        article: 'der',
        noun: 'Gast',
        plural: null,
        english: 'guest',
        da: 'gæst',
        en: 'guest',
        id: 372,
      },
      {
        article: 'der',
        noun: 'Geburtstag',
        plural: null,
        english: 'birthday',
        da: 'fødselsdag',
        en: 'birthday',
        id: 373,
      },
      {
        article: 'der',
        noun: 'Gehsteig',
        plural: null,
        english: 'sidewalk',
        da: 'fortov',
        en: 'pavement',
        id: 374,
      },
      {
        article: 'der',
        noun: 'Geruch',
        plural: null,
        english: 'smell, odor',
        da: 'lugt',
        en: 'odor',
        id: 375,
      },
      {
        article: 'der',
        noun: 'Geschäftsführer',
        plural: null,
        english: 'store manager',
        da: 'administrerende direktør',
        en: 'chief Executive Officer',
        id: 376,
      },
      {
        article: 'der',
        noun: 'Gestank',
        plural: null,
        english: 'stench',
        da: 'stinke',
        en: 'stink',
        id: 377,
      },
      {
        article: 'der',
        noun: 'Getränkemarkt',
        plural: null,
        english: 'party store, beer - wine store',
        da: 'Drikkevaremarked',
        en: 'Beverage market',
        id: 378,
      },
      {
        article: 'der',
        noun: 'Gott',
        plural: null,
        english: 'God, gods',
        da: 'Gud',
        en: 'God',
        id: 379,
      },
      {
        article: 'der',
        noun: 'Grund',
        plural: null,
        english: 'the reason, the matter',
        da: 'grund',
        en: 'reason',
        id: 380,
      },
      {
        article: 'der',
        noun: 'Grundsatz',
        plural: null,
        english: 'principle',
        da: 'princip',
        en: 'principle',
        id: 381,
      },
      {
        article: 'der',
        noun: 'Gürtel',
        plural: null,
        english: 'belt',
        da: 'bælte',
        en: 'belt',
        id: 382,
      },
      {
        article: 'der',
        noun: 'Haarschnitt',
        plural: null,
        english: 'haircut',
        da: 'Klipning',
        en: 'Haircut',
        id: 383,
      },
      {
        article: 'der',
        noun: 'Hacken',
        plural: null,
        english: 'hooks',
        da: 'Hugge',
        en: 'Chop',
        id: 384,
      },
      {
        article: 'der',
        noun: 'Hafen',
        plural: null,
        english: 'harbor',
        da: 'Havn',
        en: 'port',
        id: 385,
      },
      {
        article: 'der',
        noun: 'Hafer',
        plural: null,
        english: 'oat',
        da: 'havre',
        en: 'oats',
        id: 386,
      },
      {
        article: 'der',
        noun: 'Hals',
        plural: null,
        english: 'neck',
        da: 'nakke',
        en: 'neck',
        id: 387,
      },
      {
        article: 'der',
        noun: 'Halz',
        plural: null,
        english: 'ear, nose and throat doctor',
        da: 'Halz',
        en: 'Halz',
        id: 388,
      },
      {
        article: 'der',
        noun: 'Hammer',
        plural: null,
        english: 'hammer',
        da: 'Hammer',
        en: 'hammer',
        id: 389,
      },
      {
        article: 'der',
        noun: 'Hase',
        plural: null,
        english: 'hare, undomesticated rabbit',
        da: 'Kanin',
        en: 'Rabbit',
        id: 390,
      },
      {
        article: 'der',
        noun: 'Hass',
        plural: null,
        english: 'hate, hatred',
        da: 'had',
        en: 'hate',
        id: 391,
      },
      {
        article: 'der',
        noun: 'Haushalt',
        plural: null,
        english: 'household',
        da: 'husstand',
        en: 'household',
        id: 392,
      },
      {
        article: 'der',
        noun: 'Heilend',
        plural: null,
        english: 'savior',
        da: 'Healing',
        en: 'Healing',
        id: 393,
      },
      {
        article: 'der',
        noun: 'Heilige',
        plural: null,
        english: 'saint',
        da: 'Saints',
        en: 'Saints',
        id: 394,
      },
      {
        article: 'der',
        noun: 'Held',
        plural: null,
        english: 'hero, heroin',
        da: 'helt',
        en: 'hero',
        id: 395,
      },
      {
        article: 'der',
        noun: 'Herd',
        plural: null,
        english: 'whole stove, oven and top',
        da: 'komfur',
        en: 'stove',
        id: 396,
      },
      {
        article: 'der',
        noun: 'Himmel',
        plural: null,
        english: 'heaven',
        da: 'himmel',
        en: 'sky',
        id: 397,
      },
      {
        article: 'der',
        noun: 'Hinduismus',
        plural: null,
        english: 'hinduism',
        da: 'hinduisme',
        en: 'Hinduism',
        id: 398,
      },
      {
        article: 'der',
        noun: 'Hochzeitstage',
        plural: null,
        english: 'wedding day',
        da: 'Bryllupsdage',
        en: 'Wedding days',
        id: 399,
      },
      {
        article: 'der',
        noun: 'Hof',
        plural: null,
        english: 'yard, courtyard',
        da: 'ret',
        en: 'court',
        id: 400,
      },
      {
        article: 'der',
        noun: 'Honig',
        plural: null,
        english: 'honey',
        da: 'honning',
        en: 'honey',
        id: 401,
      },
      {
        article: 'der',
        noun: 'Horizont',
        plural: null,
        english: 'horizon',
        da: 'horisont',
        en: 'horizon',
        id: 402,
      },
      {
        article: 'der',
        noun: 'Huf',
        plural: null,
        english: 'hoof',
        da: 'hov',
        en: 'hoof',
        id: 403,
      },
      {
        article: 'der',
        noun: 'Husten',
        plural: null,
        english: 'cough',
        da: 'at hoste',
        en: 'to cough',
        id: 404,
      },
      {
        article: 'der',
        noun: 'Hustensaft',
        plural: null,
        english: 'cough syrup',
        da: 'Hoste sirup',
        en: 'Cough Syrup',
        id: 405,
      },
      {
        article: 'der',
        noun: 'Hüter',
        plural: null,
        english: 'custodian',
        da: 'værge',
        en: 'guardian',
        id: 406,
      },
      {
        article: 'der',
        noun: 'Internist',
        plural: null,
        english: 'internalist',
        da: 'Internist',
        en: 'Internist',
        id: 407,
      },
      {
        article: 'der',
        noun: 'Internspeicher',
        plural: null,
        english: 'computer memory',
        da: 'Intern hukommelse',
        en: 'Internal memory',
        id: 408,
      },
      {
        article: 'der',
        noun: 'Kachelboden',
        plural: null,
        english: 'tile floor',
        da: 'Flisebelagt gulv',
        en: 'Tiled floor',
        id: 409,
      },
      {
        article: 'der',
        noun: 'Kalender',
        plural: null,
        english: 'calender',
        da: 'kalender',
        en: 'calendar',
        id: 410,
      },
      {
        article: 'der',
        noun: 'Kamm',
        plural: null,
        english: 'comb',
        da: 'Kam',
        en: 'Comb',
        id: 411,
      },
      {
        article: 'der',
        noun: 'Kasperl',
        plural: null,
        english: 'puppet',
        da: 'Punch',
        en: 'Punch',
        id: 412,
      },
      {
        article: 'der',
        noun: 'Kassenzettel',
        plural: null,
        english: 'receipt',
        da: 'Regning',
        en: 'Bill',
        id: 413,
      },
      {
        article: 'der',
        noun: 'Kater',
        plural: null,
        english: 'tomcat',
        da: 'Hankat',
        en: 'Male cat',
        id: 414,
      },
      {
        article: 'der',
        noun: 'Keks',
        plural: null,
        english: 'cookies',
        da: 'kiks',
        en: 'biscuit',
        id: 415,
      },
      {
        article: 'der',
        noun: 'Kellner',
        plural: null,
        english: 'waiter, waitress',
        da: 'Tjener',
        en: 'Waiter',
        id: 416,
      },
      {
        article: 'der',
        noun: 'Klee',
        plural: null,
        english: 'clover',
        da: 'kløver',
        en: 'clover',
        id: 417,
      },
      {
        article: 'der',
        noun: 'Kleiderbügel',
        plural: null,
        english: 'coat hanger',
        da: 'bøjler',
        en: 'Hangers',
        id: 418,
      },
      {
        article: 'der',
        noun: 'Klub',
        plural: null,
        english: 'club, civic group',
        da: 'forening',
        en: 'club',
        id: 419,
      },
      {
        article: 'der',
        noun: 'Knoblauch',
        plural: null,
        english: 'garlic',
        da: 'hvidløg',
        en: 'garlic',
        id: 420,
      },
      {
        article: 'der',
        noun: 'Knöchel',
        plural: null,
        english: 'ankle',
        da: 'ankel',
        en: 'ankle',
        id: 421,
      },
      {
        article: 'der',
        noun: 'Koffer',
        plural: null,
        english: 'suitcase',
        da: 'kuffert',
        en: 'suitcase',
        id: 422,
      },
      {
        article: 'der',
        noun: 'Kohl',
        plural: null,
        english: 'cabbage',
        da: 'Kål',
        en: 'Cabbage',
        id: 423,
      },
      {
        article: 'der',
        noun: 'Kontrast',
        plural: null,
        english: 'contrast',
        da: 'kontrast',
        en: 'contrast',
        id: 424,
      },
      {
        article: 'der',
        noun: 'Kontrolleur',
        plural: null,
        english: 'inspector',
        da: 'controller',
        en: 'controller',
        id: 425,
      },
      {
        article: 'der',
        noun: 'Kopf',
        plural: null,
        english: 'head',
        da: 'hoved',
        en: 'head',
        id: 426,
      },
      {
        article: 'der',
        noun: 'Kopfsalat',
        plural: null,
        english: 'head lettuce',
        da: 'Salat',
        en: 'Lettuce',
        id: 427,
      },
      {
        article: 'der',
        noun: 'Korb',
        plural: null,
        english: 'basket',
        da: 'kurv',
        en: 'basket',
        id: 428,
      },
      {
        article: 'der',
        noun: 'Korken',
        plural: null,
        english: 'cork',
        da: 'kork',
        en: 'cork',
        id: 429,
      },
      {
        article: 'der',
        noun: 'Korkenzieher',
        plural: null,
        english: 'corkscrew',
        da: 'proptrækker',
        en: 'corkscrew',
        id: 430,
      },
      {
        article: 'der',
        noun: 'Krankenpfleger',
        plural: null,
        english: 'nurse, male or female',
        da: 'Sygeplejersker',
        en: 'Nurses',
        id: 431,
      },
      {
        article: 'der',
        noun: 'Kratzer',
        plural: null,
        english: 'scratch',
        da: 'kradse',
        en: 'scratch',
        id: 432,
      },
      {
        article: 'der',
        noun: 'Kreis',
        plural: null,
        english: 'circle',
        da: 'cirkel',
        en: 'circle',
        id: 433,
      },
      {
        article: 'der',
        noun: 'Kuchen',
        plural: null,
        english: 'cake',
        da: 'kage',
        en: 'cake',
        id: 434,
      },
      {
        article: 'der',
        noun: 'Kugelschreiber',
        plural: null,
        english: 'ink pen',
        da: 'pen',
        en: 'pen',
        id: 435,
      },
      {
        article: 'der',
        noun: 'Kunde',
        plural: null,
        english: 'customer',
        da: 'kunde',
        en: 'customer',
        id: 436,
      },
      {
        article: 'der',
        noun: 'Kundenservice',
        plural: null,
        english: 'customer service department',
        da: 'Kunde service',
        en: 'Customer service',
        id: 437,
      },
      {
        article: 'der',
        noun: 'Kuss',
        plural: null,
        english: 'kiss',
        da: 'kys',
        en: 'kiss',
        id: 438,
      },
      {
        article: 'der',
        noun: 'Käfig',
        plural: null,
        english: 'birdcage',
        da: 'Bur',
        en: 'Cage',
        id: 439,
      },
      {
        article: 'der',
        noun: 'Käse',
        plural: null,
        english: 'cheese',
        da: 'ost',
        en: 'cheese',
        id: 440,
      },
      {
        article: 'der',
        noun: 'Körper',
        plural: null,
        english: 'body',
        da: 'legeme',
        en: 'body',
        id: 441,
      },
      {
        article: 'der',
        noun: 'Kühlschrank',
        plural: null,
        english: 'refrigerator',
        da: 'køleskab',
        en: 'fridge',
        id: 442,
      },
      {
        article: 'der',
        noun: 'Kürbis',
        plural: null,
        english: 'pumpkins',
        da: 'græskar',
        en: 'pumpkin',
        id: 443,
      },
      {
        article: 'der',
        noun: 'Laden',
        plural: null,
        english: 'shop',
        da: 'belastning',
        en: 'load',
        id: 444,
      },
      {
        article: 'der',
        noun: 'Langschläfer',
        plural: null,
        english: 'late sleeper, lazy person',
        da: 'Sent stigerør',
        en: 'Late risers',
        id: 445,
      },
      {
        article: 'der',
        noun: 'Laptop',
        plural: null,
        english: 'laptop computer',
        da: 'Bærbar',
        en: 'Laptop',
        id: 446,
      },
      {
        article: 'der',
        noun: 'Lauch',
        plural: null,
        english: 'leek',
        da: 'porre',
        en: 'leek',
        id: 447,
      },
      {
        article: 'der',
        noun: 'Leerlauf',
        plural: null,
        english: 'idle',
        da: 'Neutral',
        en: 'Neutral',
        id: 448,
      },
      {
        article: 'der',
        noun: 'Legastheniker',
        plural: null,
        english: 'dyslexic',
        da: 'ordblind',
        en: 'Dyslexic',
        id: 449,
      },
      {
        article: 'der',
        noun: 'Leiter',
        plural: null,
        english: 'leader',
        da: 'stige',
        en: 'ladder',
        id: 450,
      },
      {
        article: 'der',
        noun: 'Liebling',
        plural: null,
        english: 'loved one',
        da: 'skat',
        en: 'Darling',
        id: 451,
      },
      {
        article: 'der',
        noun: 'Lockenwickler',
        plural: null,
        english: 'hair curlers',
        da: 'curler',
        en: 'curler',
        id: 452,
      },
      {
        article: 'der',
        noun: 'Lärm',
        plural: null,
        english: 'noise',
        da: 'støj',
        en: 'noise',
        id: 453,
      },
      {
        article: 'der',
        noun: 'Löffel',
        plural: null,
        english: 'spoon',
        da: 'ske',
        en: 'spoon',
        id: 454,
      },
      {
        article: 'der',
        noun: 'Löwe',
        plural: null,
        english: 'lion',
        da: 'løve',
        en: 'lion',
        id: 455,
      },
      {
        article: 'der',
        noun: 'Mais',
        plural: null,
        english: 'corn',
        da: 'majs',
        en: 'Corn',
        id: 456,
      },
      {
        article: 'der',
        noun: 'Maiskolben',
        plural: null,
        english: 'corncob',
        da: 'Maiskolber',
        en: 'Corn cobs',
        id: 457,
      },
      {
        article: 'der',
        noun: 'Maurer',
        plural: null,
        english: 'bricklayer',
        da: 'murer',
        en: 'mason',
        id: 458,
      },
      {
        article: 'der',
        noun: 'Mediabericht',
        plural: null,
        english: 'media report',
        da: 'Medierapport',
        en: 'Media report',
        id: 459,
      },
      {
        article: 'der',
        noun: 'Meeresblick',
        plural: null,
        english: 'seaside view',
        da: 'havudsigt',
        en: 'sea View',
        id: 460,
      },
      {
        article: 'der',
        noun: 'Mensch',
        plural: null,
        english: 'humans',
        da: 'human',
        en: 'human',
        id: 461,
      },
      {
        article: 'der',
        noun: 'Mond',
        plural: null,
        english: 'the moon',
        da: 'måne',
        en: 'moon',
        id: 462,
      },
      {
        article: 'der',
        noun: 'Mord',
        plural: null,
        english: 'murder',
        da: 'mord',
        en: 'murder',
        id: 463,
      },
      {
        article: 'der',
        noun: 'Morgenmantel',
        plural: null,
        english: 'housecoat',
        da: 'Badekåbe',
        en: 'Dressing gown',
        id: 464,
      },
      {
        article: 'der',
        noun: 'Mulleimer',
        plural: null,
        english: 'trash bin',
        da: 'Skraldespand',
        en: 'Garbage can',
        id: 465,
      },
      {
        article: 'der',
        noun: 'Mund',
        plural: null,
        english: 'mouth',
        da: 'mund',
        en: 'mouth',
        id: 466,
      },
      {
        article: 'der',
        noun: 'Musiker',
        plural: null,
        english: 'musician',
        da: 'Musiker',
        en: 'Musician',
        id: 467,
      },
      {
        article: 'der',
        noun: 'Muskel',
        plural: null,
        english: 'muscle',
        da: 'muskel',
        en: 'muscle',
        id: 468,
      },
      {
        article: 'der',
        noun: 'Mut',
        plural: null,
        english: 'courage, pluck',
        da: 'mod',
        en: 'courage',
        id: 469,
      },
      {
        article: 'der',
        noun: 'Nabel',
        plural: null,
        english: 'navel',
        da: 'navle',
        en: 'navel',
        id: 470,
      },
      {
        article: 'der',
        noun: 'Nachbar',
        plural: null,
        english: 'neighbor',
        da: 'Nabo',
        en: 'Neighbor',
        id: 471,
      },
      {
        article: 'der',
        noun: 'Nachteil',
        plural: null,
        english: 'disadvantage',
        da: 'ulempe',
        en: 'disadvantage',
        id: 472,
      },
      {
        article: 'der',
        noun: 'Name',
        plural: null,
        english: 'name',
        da: 'Efternavn',
        en: 'Surname',
        id: 473,
      },
      {
        article: 'der',
        noun: 'Nebel',
        plural: null,
        english: 'fog, mist',
        da: 'tåge',
        en: 'fog',
        id: 474,
      },
      {
        article: 'der',
        noun: 'Nord',
        plural: null,
        english: 'North, South, East and West',
        da: 'Nord',
        en: 'North',
        id: 475,
      },
      {
        article: 'der',
        noun: 'Notausgang',
        plural: null,
        english: 'emergency exit',
        da: 'Nødudgang',
        en: 'Emergency exit',
        id: 476,
      },
      {
        article: 'der',
        noun: 'Nu',
        plural: null,
        english: 'no time',
        da: 'Nu',
        en: 'Now',
        id: 477,
      },
      {
        article: 'der',
        noun: 'Nudelsalat',
        plural: null,
        english: 'pasta salad',
        da: 'Pasta salat',
        en: 'Pasta salad',
        id: 478,
      },
      {
        article: 'der',
        noun: 'Oberkörper',
        plural: null,
        english: 'upper body',
        da: 'overkroppen',
        en: 'upper body',
        id: 479,
      },
      {
        article: 'der',
        noun: 'Oberschenkel',
        plural: null,
        english: 'thigh',
        da: 'Lår',
        en: 'Thigh',
        id: 480,
      },
      {
        article: 'der',
        noun: 'Ochse',
        plural: null,
        english: 'ox',
        da: 'Okse',
        en: 'Ox',
        id: 481,
      },
      {
        article: 'der',
        noun: 'Ofen',
        plural: null,
        english: 'oven',
        da: 'ovn',
        en: 'oven',
        id: 482,
      },
      {
        article: 'der',
        noun: 'Ort',
        plural: null,
        english: 'place',
        da: 'placere',
        en: 'place',
        id: 483,
      },
      {
        article: 'der',
        noun: 'Orthopäde',
        plural: null,
        english: 'orthopedist',
        da: 'orthopæd',
        en: 'Orthopedist',
        id: 484,
      },
      {
        article: 'der',
        noun: 'Pantoffelheld',
        plural: null,
        english: 'hen pecked husband, i.e. Slipper bound',
        da: 'Slipper helt',
        en: 'Slipper hero',
        id: 485,
      },
      {
        article: 'der',
        noun: 'Papagei',
        plural: null,
        english: 'parrot',
        da: 'papegøje',
        en: 'parrot',
        id: 486,
      },
      {
        article: 'der',
        noun: 'Paprika',
        plural: null,
        english: 'bell peppers',
        da: 'paprika',
        en: 'paprika',
        id: 487,
      },
      {
        article: 'der',
        noun: 'Park',
        plural: null,
        english: 'park',
        da: 'parkere',
        en: 'park',
        id: 488,
      },
      {
        article: 'der',
        noun: 'Partner',
        plural: null,
        english: 'partner',
        da: 'partner',
        en: 'partner',
        id: 489,
      },
      {
        article: 'der',
        noun: 'Pass',
        plural: null,
        english: 'passport',
        da: 'pas',
        en: 'passport',
        id: 490,
      },
      {
        article: 'der',
        noun: 'Pfeffer',
        plural: null,
        english: 'pepper',
        da: 'peber',
        en: 'pepper',
        id: 491,
      },
      {
        article: 'der',
        noun: 'Pfirsich',
        plural: null,
        english: 'peach',
        da: 'fersken',
        en: 'peach',
        id: 492,
      },
      {
        article: 'der',
        noun: 'Pilz',
        plural: null,
        english: 'mushroom',
        da: 'champignon',
        en: 'mushroom',
        id: 493,
      },
      {
        article: 'der',
        noun: 'Pinsel',
        plural: null,
        english: 'brush',
        da: 'børste',
        en: 'brush',
        id: 494,
      },
      {
        article: 'der',
        noun: 'Plünderer',
        plural: null,
        english: 'raider, plunderer',
        da: 'Raiders',
        en: 'Raiders',
        id: 495,
      },
      {
        article: 'der',
        noun: 'Psychiater',
        plural: null,
        english: 'psychiatrist',
        da: 'psykiater',
        en: 'psychiatrist',
        id: 496,
      },
      {
        article: 'der',
        noun: 'Pullover',
        plural: null,
        english: 'a sweater',
        da: 'bluse',
        en: 'pullover',
        id: 497,
      },
      {
        article: 'der',
        noun: 'Quark',
        plural: null,
        english: 'sour cream',
        da: 'Quark',
        en: 'Quark',
        id: 498,
      },
      {
        article: 'der',
        noun: 'Rabe',
        plural: null,
        english: 'raven',
        da: 'Krage',
        en: 'Crow',
        id: 499,
      },
      {
        article: 'der',
        noun: 'Rahmen',
        plural: null,
        english: 'frame',
        da: 'ramme',
        en: 'frame',
        id: 500,
      },
      {
        article: 'der',
        noun: 'Rand',
        plural: null,
        english: 'edge',
        da: 'kant',
        en: 'edge',
        id: 501,
      },
      {
        article: 'der',
        noun: 'Rat',
        plural: null,
        english: 'advice',
        da: 'råd',
        en: 'advice',
        id: 502,
      },
      {
        article: 'der',
        noun: 'Raum',
        plural: null,
        english: 'space, room',
        da: 'værelse',
        en: 'room',
        id: 503,
      },
      {
        article: 'der',
        noun: 'Rechtsstreit',
        plural: null,
        english: 'legal dispute',
        da: 'Retssager',
        en: 'Litigation',
        id: 504,
      },
      {
        article: 'der',
        noun: 'Regenschirm',
        plural: null,
        english: 'umbrella',
        da: 'paraply',
        en: 'umbrella',
        id: 505,
      },
      {
        article: 'der',
        noun: 'Respekt',
        plural: null,
        english: 'respect',
        da: 'respektere',
        en: 'respect',
        id: 506,
      },
      {
        article: 'der',
        noun: 'Rest',
        plural: null,
        english: 'the rest',
        da: 'hvile',
        en: 'rest',
        id: 507,
      },
      {
        article: 'der',
        noun: 'Retter',
        plural: null,
        english: 'rescuer, savior',
        da: 'frelser',
        en: 'savior',
        id: 508,
      },
      {
        article: 'der',
        noun: 'Rettich',
        plural: null,
        english: 'radish',
        da: 'radise',
        en: 'radish',
        id: 509,
      },
      {
        article: 'der',
        noun: 'Rhythmus',
        plural: null,
        english: 'rhythm',
        da: 'rytme',
        en: 'rhythm',
        id: 510,
      },
      {
        article: 'der',
        noun: 'Riese',
        plural: null,
        english: 'giant',
        da: 'kæmpe stor',
        en: 'giant',
        id: 511,
      },
      {
        article: 'der',
        noun: 'Ring',
        plural: null,
        english: 'ring',
        da: 'ring',
        en: 'ring',
        id: 512,
      },
      {
        article: 'der',
        noun: 'Ritter',
        plural: null,
        english: 'knight',
        da: 'Ridder',
        en: 'Knight',
        id: 513,
      },
      {
        article: 'der',
        noun: 'Rock',
        plural: null,
        english: 'skirt',
        da: 'nederdel',
        en: 'skirt',
        id: 514,
      },
      {
        article: 'der',
        noun: 'Roller',
        plural: null,
        english: 'scooter',
        da: 'Scooter',
        en: 'Scooter',
        id: 515,
      },
      {
        article: 'der',
        noun: 'Ruhm',
        plural: null,
        english: 'glory',
        da: 'berømmelse',
        en: 'Fame',
        id: 516,
      },
      {
        article: 'der',
        noun: 'Rumpf',
        plural: null,
        english: 'body core, trunk',
        da: 'skrog',
        en: 'hull',
        id: 517,
      },
      {
        article: 'der',
        noun: 'Rücken',
        plural: null,
        english: 'the back, spine',
        da: 'bevæge sig',
        en: 'move',
        id: 518,
      },
      {
        article: 'der',
        noun: 'Sack',
        plural: null,
        english: 'sack',
        da: 'taske',
        en: 'bag',
        id: 519,
      },
      {
        article: 'der',
        noun: 'Saft',
        plural: null,
        english: 'juice',
        da: 'Juice',
        en: 'juice',
        id: 520,
      },
      {
        article: 'der',
        noun: 'Salat',
        plural: null,
        english: 'lettuce',
        da: 'salat',
        en: 'salad',
        id: 521,
      },
      {
        article: 'der',
        noun: 'Sand',
        plural: null,
        english: 'sand',
        da: 'sand',
        en: 'sand',
        id: 522,
      },
      {
        article: 'der',
        noun: 'Sandstrand',
        plural: null,
        english: 'sandy beach',
        da: 'sandet strand',
        en: 'Sandy beach',
        id: 523,
      },
      {
        article: 'der',
        noun: 'Schal',
        plural: null,
        english: 'scarf, shawl',
        da: 'halstørklæde',
        en: 'scarf',
        id: 524,
      },
      {
        article: 'der',
        noun: 'Schalter',
        plural: null,
        english: 'counter, switch',
        da: 'skranke',
        en: 'counter',
        id: 525,
      },
      {
        article: 'der',
        noun: 'Schatz',
        plural: null,
        english: 'treasure, sweetheart',
        da: 'kæreste',
        en: 'sweetheart',
        id: 526,
      },
      {
        article: 'der',
        noun: 'Schein',
        plural: null,
        english: 'paper money, bill',
        da: 'Udseende',
        en: 'Appearance',
        id: 527,
      },
      {
        article: 'der',
        noun: 'Schimpf',
        plural: null,
        english: 'insult, affront',
        da: 'Sværge',
        en: 'Swear',
        id: 528,
      },
      {
        article: 'der',
        noun: 'Schinken',
        plural: null,
        english: 'ham',
        da: 'skinke',
        en: 'ham',
        id: 529,
      },
      {
        article: 'der',
        noun: 'Schirm',
        plural: null,
        english: 'shield, screen',
        da: 'paraply',
        en: 'umbrella',
        id: 530,
      },
      {
        article: 'der',
        noun: 'Schlitten',
        plural: null,
        english: 'sled, sledge',
        da: 'vogn',
        en: 'carriage',
        id: 531,
      },
      {
        article: 'der',
        noun: 'Schlitz',
        plural: null,
        english: 'slot, slit',
        da: 'slot',
        en: 'slot',
        id: 532,
      },
      {
        article: 'der',
        noun: 'Schluck',
        plural: null,
        english: 'of liquid, a drink, a swallow, a drop',
        da: 'Nippe til',
        en: 'Sip',
        id: 533,
      },
      {
        article: 'der',
        noun: 'Schlüssel',
        plural: null,
        english: 'key',
        da: 'nøgle',
        en: 'key',
        id: 534,
      },
      {
        article: 'der',
        noun: 'Schmeichler',
        plural: null,
        english: 'flatterer',
        da: 'Smigrer',
        en: 'Flatterer',
        id: 535,
      },
      {
        article: 'der',
        noun: 'Schmerz',
        plural: null,
        english: 'pain',
        da: 'smerte',
        en: 'pain',
        id: 536,
      },
      {
        article: 'der',
        noun: 'Schmetterling',
        plural: null,
        english: 'butterfly',
        da: 'sommerfugl',
        en: 'butterfly',
        id: 537,
      },
      {
        article: 'der',
        noun: 'Schmuck',
        plural: null,
        english: 'jewelry',
        da: 'Smykker',
        en: 'Jewellery',
        id: 538,
      },
      {
        article: 'der',
        noun: 'Schnabel',
        plural: null,
        english: 'beak',
        da: 'næb',
        en: 'beak',
        id: 539,
      },
      {
        article: 'der',
        noun: 'Schnupften',
        plural: null,
        english: 'a cold',
        da: 'snifning',
        en: 'Sniffing',
        id: 540,
      },
      {
        article: 'der',
        noun: 'Schrank',
        plural: null,
        english: 'cupboard, wardrobe',
        da: 'skab',
        en: 'closet',
        id: 541,
      },
      {
        article: 'der',
        noun: 'Schrei',
        plural: null,
        english: 'scream',
        da: 'Skrige',
        en: 'Scream',
        id: 542,
      },
      {
        article: 'der',
        noun: 'Schwager',
        plural: null,
        english: 'brother in law, sister in law',
        da: 'svoger',
        en: 'brother in law',
        id: 543,
      },
      {
        article: 'der',
        noun: 'Schwan',
        plural: null,
        english: 'swan',
        da: 'Svane',
        en: 'swan',
        id: 544,
      },
      {
        article: 'der',
        noun: 'Schwerpunkt',
        plural: null,
        english: 'center of gravity',
        da: 'hovedvægt',
        en: 'main emphasis',
        id: 545,
      },
      {
        article: 'der',
        noun: 'Schäferhund',
        plural: null,
        english: 'german shepherd',
        da: 'Schæferhund',
        en: 'German Shepherd',
        id: 546,
      },
      {
        article: 'der',
        noun: 'Schönheitschirurg',
        plural: null,
        english: 'plastic surgeon',
        da: 'plastikkirurg',
        en: 'plastic surgeon',
        id: 547,
      },
      {
        article: 'der',
        noun: 'Seite',
        plural: null,
        english: 'side, page',
        da: 'side',
        en: 'page',
        id: 548,
      },
      {
        article: 'der',
        noun: 'Senf',
        plural: null,
        english: 'mustard',
        da: 'sennep',
        en: 'mustard',
        id: 549,
      },
      {
        article: 'der',
        noun: 'Sessel',
        plural: null,
        english: 'armchair',
        da: 'lænestol',
        en: 'armchair',
        id: 550,
      },
      {
        article: 'der',
        noun: 'Seufzer',
        plural: null,
        english: 'sigh',
        da: 'suk',
        en: 'sigh',
        id: 551,
      },
      {
        article: 'der',
        noun: 'Sklave',
        plural: null,
        english: 'slave',
        da: 'slave',
        en: 'slave',
        id: 552,
      },
      {
        article: 'der',
        noun: 'Sohn',
        plural: null,
        english: 'son, sons',
        da: 'søn',
        en: 'son',
        id: 553,
      },
      {
        article: 'der',
        noun: 'Spinat',
        plural: null,
        english: 'spinach',
        da: 'spinat',
        en: 'spinach',
        id: 554,
      },
      {
        article: 'der',
        noun: 'Spitzer',
        plural: null,
        english: 'pencil sharpener',
        da: 'sharpener',
        en: 'Sharpener',
        id: 555,
      },
      {
        article: 'der',
        noun: 'Sprecher',
        plural: null,
        english: 'speaker',
        da: 'højttaler',
        en: 'speaker',
        id: 556,
      },
      {
        article: 'der',
        noun: 'Staat',
        plural: null,
        english: 'state, country',
        da: 'Land',
        en: 'Country',
        id: 557,
      },
      {
        article: 'der',
        noun: 'Stab',
        plural: null,
        english: 'stick, pole',
        da: 'Rod',
        en: 'Rod',
        id: 558,
      },
      {
        article: 'der',
        noun: 'Stabhochsprung',
        plural: null,
        english: 'pole vaulting',
        da: 'Stangspring',
        en: 'Pole vault',
        id: 559,
      },
      {
        article: 'der',
        noun: 'Stadtplan',
        plural: null,
        english: 'city plan, map',
        da: 'kort',
        en: 'map',
        id: 560,
      },
      {
        article: 'der',
        noun: 'Stadtteil',
        plural: null,
        english: 'city district',
        da: 'distrikt',
        en: 'district',
        id: 561,
      },
      {
        article: 'der',
        noun: 'Stamm',
        plural: null,
        english: 'tree trunk, tribe, clan',
        da: 'stamme',
        en: 'tribe',
        id: 562,
      },
      {
        article: 'der',
        noun: 'Stand',
        plural: null,
        english: 'stand, counter',
        da: 'stod',
        en: 'was standing',
        id: 563,
      },
      {
        article: 'der',
        noun: 'Stapel',
        plural: null,
        english: 'pile, stack',
        da: 'stak',
        en: 'stack',
        id: 564,
      },
      {
        article: 'der',
        noun: 'Stau',
        plural: null,
        english: 'traffic jam',
        da: 'trafikprop',
        en: 'traffic jam',
        id: 565,
      },
      {
        article: 'der',
        noun: 'Stern',
        plural: null,
        english: 'stars',
        da: 'stjerne',
        en: 'star',
        id: 566,
      },
      {
        article: 'der',
        noun: 'Steward',
        plural: null,
        english: 'airline stewardess',
        da: 'steward',
        en: 'steward',
        id: 567,
      },
      {
        article: 'der',
        noun: 'Stich',
        plural: null,
        english: 'stitch',
        da: 'Stik',
        en: 'Sting',
        id: 568,
      },
      {
        article: 'der',
        noun: 'Stiefel',
        plural: null,
        english: 'boot',
        da: 'støvler',
        en: 'Boots',
        id: 569,
      },
      {
        article: 'der',
        noun: 'Stier',
        plural: null,
        english: 'A steer, a bull',
        da: 'tyr',
        en: 'bull',
        id: 570,
      },
      {
        article: 'der',
        noun: 'Stift',
        plural: null,
        english: 'pen, pin, peg',
        da: 'pen',
        en: 'pen',
        id: 571,
      },
      {
        article: 'der',
        noun: 'Stock',
        plural: null,
        english: 'cane',
        da: 'etage',
        en: 'floor',
        id: 572,
      },
      {
        article: 'der',
        noun: 'Stoff',
        plural: null,
        english: 'fabric, material',
        da: 'materiale',
        en: 'material',
        id: 573,
      },
      {
        article: 'der',
        noun: 'Stolz',
        plural: null,
        english: 'pride',
        da: 'Stolt',
        en: 'Proud',
        id: 574,
      },
      {
        article: 'der',
        noun: 'Stoß',
        plural: null,
        english: 'push, shove, punch',
        da: 'Chok',
        en: 'Shock',
        id: 575,
      },
      {
        article: 'der',
        noun: 'Strafzettel',
        plural: null,
        english: 'parking ticket, etc.',
        da: 'Fartbøde',
        en: 'Speeding ticket',
        id: 576,
      },
      {
        article: 'der',
        noun: 'Strand',
        plural: null,
        english: 'beach',
        da: 'Strand',
        en: 'Beach',
        id: 577,
      },
      {
        article: 'der',
        noun: 'Strauch',
        plural: null,
        english: 'shrubbery',
        da: 'Busk',
        en: 'shrub',
        id: 578,
      },
      {
        article: 'der',
        noun: 'Strauss',
        plural: null,
        english: 'ostrich',
        da: 'Struds',
        en: 'Ostrich',
        id: 579,
      },
      {
        article: 'der',
        noun: 'Straßenrand',
        plural: null,
        english: 'roadside',
        da: 'Roadside',
        en: 'Roadside',
        id: 580,
      },
      {
        article: 'der',
        noun: 'Streifschar',
        plural: null,
        english: 'raiding party',
        da: 'Streifschar',
        en: 'Streifschar',
        id: 581,
      },
      {
        article: 'der',
        noun: 'Streit',
        plural: null,
        english: 'argument, quarrel, dispute',
        da: 'argument',
        en: 'argument',
        id: 582,
      },
      {
        article: 'der',
        noun: 'Strom',
        plural: null,
        english: 'river',
        da: 'elektricitet',
        en: 'electricity',
        id: 583,
      },
      {
        article: 'der',
        noun: 'Supermarkt',
        plural: null,
        english: 'supermarket',
        da: 'Supermarked',
        en: 'Supermarket',
        id: 584,
      },
      {
        article: 'der',
        noun: 'Suppenteller',
        plural: null,
        english: 'soup bowl',
        da: 'Suppeplade',
        en: 'Soup plate',
        id: 585,
      },
      {
        article: 'der',
        noun: 'Synthesizer',
        plural: null,
        english: 'synthesizer',
        da: 'synthesizer',
        en: 'synthesizer',
        id: 586,
      },
      {
        article: 'der',
        noun: 'Sünder',
        plural: null,
        english: 'sinner',
        da: 'synder',
        en: 'sinner',
        id: 587,
      },
      {
        article: 'der',
        noun: 'Tagtraum',
        plural: null,
        english: 'daydream',
        da: 'Dag drøm',
        en: 'Daydream',
        id: 588,
      },
      {
        article: 'der',
        noun: 'Takt',
        plural: null,
        english: '(music) - bar, time',
        da: 'Ur',
        en: 'Clock',
        id: 589,
      },
      {
        article: 'der',
        noun: 'Taschenrechner',
        plural: null,
        english: 'calculator',
        da: 'lommeregner',
        en: 'calculator',
        id: 590,
      },
      {
        article: 'der',
        noun: 'Teddybär',
        plural: null,
        english: 'teddy bear',
        da: 'Bamse',
        en: 'teddy bear',
        id: 591,
      },
      {
        article: 'der',
        noun: 'Tee',
        plural: null,
        english: 'tea',
        da: 'te',
        en: 'tea',
        id: 592,
      },
      {
        article: 'der',
        noun: 'Teich',
        plural: null,
        english: 'pond',
        da: 'Dam',
        en: 'pond',
        id: 593,
      },
      {
        article: 'der',
        noun: 'Teller',
        plural: null,
        english: 'plate',
        da: 'Plade',
        en: 'Plate',
        id: 594,
      },
      {
        article: 'der',
        noun: 'Teppich',
        plural: null,
        english: 'carpeting, flooring',
        da: 'tæppe',
        en: 'carpet',
        id: 595,
      },
      {
        article: 'der',
        noun: 'Termin',
        plural: null,
        english: 'official appointment',
        da: 'møde',
        en: 'meeting',
        id: 596,
      },
      {
        article: 'der',
        noun: 'Tintenfisch',
        plural: null,
        english: 'inkfish - i.e. Squid, octopus, etc.',
        da: 'Blæksprutte',
        en: 'Octopus',
        id: 597,
      },
      {
        article: 'der',
        noun: 'Tisch',
        plural: null,
        english: 'table',
        da: 'bord',
        en: 'table',
        id: 598,
      },
      {
        article: 'der',
        noun: 'Tod',
        plural: null,
        english: 'death',
        da: 'død',
        en: 'death',
        id: 599,
      },
      {
        article: 'der',
        noun: 'Topf',
        plural: null,
        english: 'pot',
        da: 'gryde',
        en: 'pot',
        id: 600,
      },
      {
        article: 'der',
        noun: 'Torhüter',
        plural: null,
        english: 'goalie',
        da: 'målmand',
        en: 'goalkeeper',
        id: 601,
      },
      {
        article: 'der',
        noun: 'Trainer',
        plural: null,
        english: 'trainer',
        da: 'Træner',
        en: 'Trainer',
        id: 602,
      },
      {
        article: 'der',
        noun: 'Traumurlaub',
        plural: null,
        english: 'dream vacation',
        da: 'drømmeferie',
        en: 'dream holiday',
        id: 603,
      },
      {
        article: 'der',
        noun: 'Trauzeuge',
        plural: null,
        english: 'witness at a marriage ceremony',
        da: 'Forlover',
        en: 'Best man',
        id: 604,
      },
      {
        article: 'der',
        noun: 'Tresor',
        plural: null,
        english: 'a safe, lockbox, etc.',
        da: 'sikker',
        en: 'safe',
        id: 605,
      },
      {
        article: 'der',
        noun: 'Tropfen',
        plural: null,
        english: 'drops',
        da: 'dråber',
        en: 'drops',
        id: 606,
      },
      {
        article: 'der',
        noun: 'Trubel',
        plural: null,
        english: 'turbulence',
        da: 'Stress og jag',
        en: 'Hustle and bustle',
        id: 607,
      },
      {
        article: 'der',
        noun: 'Tumor',
        plural: null,
        english: 'tumor, growth',
        da: 'svulst',
        en: 'tumor',
        id: 608,
      },
      {
        article: 'der',
        noun: 'Turm',
        plural: null,
        english: 'tower',
        da: 'tårn',
        en: 'tower',
        id: 609,
      },
      {
        article: 'der',
        noun: 'Umzug',
        plural: null,
        english: 'parade, relocation, move',
        da: 'bevæge sig',
        en: 'move',
        id: 610,
      },
      {
        article: 'der',
        noun: 'Unfall',
        plural: null,
        english: 'accident, wreck',
        da: 'ulykke',
        en: 'accident',
        id: 611,
      },
      {
        article: 'der',
        noun: 'Unterkörper',
        plural: null,
        english: 'lower body',
        da: 'underkrop',
        en: 'lower body',
        id: 612,
      },
      {
        article: 'der',
        noun: 'Unterricht',
        plural: null,
        english: 'class, lesson, instructions',
        da: 'klasser',
        en: 'classes',
        id: 613,
      },
      {
        article: 'der',
        noun: 'Unterschied',
        plural: null,
        english: 'difference, variation',
        da: 'forskel',
        en: 'difference',
        id: 614,
      },
      {
        article: 'der',
        noun: 'Verein',
        plural: null,
        english: 'official club, organization',
        da: 'samfund',
        en: 'society',
        id: 615,
      },
      {
        article: 'der',
        noun: 'Verkäufer',
        plural: null,
        english: 'shopkeeper',
        da: 'Sælger',
        en: 'Seller',
        id: 616,
      },
      {
        article: 'der',
        noun: 'Verlust',
        plural: null,
        english: 'loss',
        da: 'tab',
        en: 'loss',
        id: 617,
      },
      {
        article: 'der',
        noun: 'Verräter',
        plural: null,
        english: 'traitor',
        da: 'forræder',
        en: 'traitor',
        id: 618,
      },
      {
        article: 'der',
        noun: 'Vers',
        plural: null,
        english: 'verse',
        da: 'vers',
        en: 'verse',
        id: 619,
      },
      {
        article: 'der',
        noun: 'Verstärker',
        plural: null,
        english: 'amplifier',
        da: 'forstærker',
        en: 'amplifier',
        id: 620,
      },
      {
        article: 'der',
        noun: 'Vertrieb',
        plural: null,
        english: 'sales',
        da: 'fordeling',
        en: 'distribution',
        id: 621,
      },
      {
        article: 'der',
        noun: 'Videorekorder',
        plural: null,
        english: 'videorecorder',
        da: 'VCR',
        en: 'VCR',
        id: 622,
      },
      {
        article: 'der',
        noun: 'Vorhang',
        plural: null,
        english: 'curtains',
        da: 'gardin',
        en: 'curtain',
        id: 623,
      },
      {
        article: 'der',
        noun: 'Vormittag',
        plural: null,
        english: 'the morning',
        da: 'morgen',
        en: 'morning',
        id: 624,
      },
      {
        article: 'der',
        noun: 'Vorschlag',
        plural: null,
        english: 'proposal, suggestion',
        da: 'forslag',
        en: 'suggestion',
        id: 625,
      },
      {
        article: 'der',
        noun: 'Vorstand',
        plural: null,
        english: 'the board (of directors)',
        da: 'Bestyrelse',
        en: 'Board',
        id: 626,
      },
      {
        article: 'der',
        noun: 'Vorstandsvorsitzende',
        plural: null,
        english: 'corporate president, head of the board',
        da: 'direktør',
        en: 'CEO',
        id: 627,
      },
      {
        article: 'der',
        noun: 'Vorteil',
        plural: null,
        english: 'advantage',
        da: 'fordel',
        en: 'advantage',
        id: 628,
      },
      {
        article: 'der',
        noun: 'Wagen',
        plural: null,
        english: 'car, truck, etc.',
        da: 'tør',
        en: 'dare',
        id: 629,
      },
      {
        article: 'der',
        noun: 'Wald',
        plural: null,
        english: 'forest',
        da: 'Skov',
        en: 'Forest',
        id: 630,
      },
      {
        article: 'der',
        noun: 'Wart',
        plural: null,
        english: 'attendant',
        da: 'Vente',
        en: 'Wait',
        id: 631,
      },
      {
        article: 'der',
        noun: 'Wasserhahn',
        plural: null,
        english: 'faucet',
        da: 'vandhane',
        en: 'water tap',
        id: 632,
      },
      {
        article: 'der',
        noun: 'Wecker',
        plural: null,
        english: 'alarm clock',
        da: 'Vækkeur',
        en: 'Alarm clock',
        id: 633,
      },
      {
        article: 'der',
        noun: 'Weg',
        plural: null,
        english: 'path',
        da: 'sti',
        en: 'path',
        id: 634,
      },
      {
        article: 'der',
        noun: 'Wein',
        plural: null,
        english: 'wine',
        da: 'Vin',
        en: 'Wine',
        id: 635,
      },
      {
        article: 'der',
        noun: 'Weinkeller',
        plural: null,
        english: 'wine cellar',
        da: 'Vinkælder',
        en: 'Wine cellar',
        id: 636,
      },
      {
        article: 'der',
        noun: 'Weizen',
        plural: null,
        english: 'wheat',
        da: 'hvede',
        en: 'wheat',
        id: 637,
      },
      {
        article: 'der',
        noun: 'Weltraum',
        plural: null,
        english: 'the universe',
        da: 'plads',
        en: 'space',
        id: 638,
      },
      {
        article: 'der',
        noun: 'Wert',
        plural: null,
        english: 'worth, value',
        da: 'værdi',
        en: 'value',
        id: 639,
      },
      {
        article: 'der',
        noun: 'Wettbewerb',
        plural: null,
        english: 'competition',
        da: 'konkurrence',
        en: 'competition',
        id: 640,
      },
      {
        article: 'der',
        noun: 'Wetter',
        plural: null,
        english: 'better',
        da: 'Vejr',
        en: 'Weather',
        id: 641,
      },
      {
        article: 'der',
        noun: 'Wettlauf',
        plural: null,
        english: 'race',
        da: 'race',
        en: 'race',
        id: 642,
      },
      {
        article: 'der',
        noun: 'Whirlpool',
        plural: null,
        english: 'whirlpool, jacuzzi',
        da: 'Whirlpool',
        en: 'Whirlpool',
        id: 643,
      },
      {
        article: 'der',
        noun: 'Winkel',
        plural: null,
        english: 'angle',
        da: 'vinkel',
        en: 'angle',
        id: 644,
      },
      {
        article: 'der',
        noun: 'Wirbel',
        plural: null,
        english: 'whirl, whirlpool (im Fluß etc.)',
        da: 'hvirvel',
        en: 'whirl',
        id: 645,
      },
      {
        article: 'der',
        noun: 'Witz',
        plural: null,
        english: 'joke, wit.',
        da: 'joke',
        en: 'joke',
        id: 646,
      },
      {
        article: 'der',
        noun: 'Wunsch',
        plural: null,
        english: 'wish',
        da: 'ønske',
        en: 'wish',
        id: 647,
      },
      {
        article: 'der',
        noun: 'Wäscheständer',
        plural: null,
        english: 'clothes drying rack',
        da: 'Tøjhest',
        en: 'Clothes horse',
        id: 648,
      },
      {
        article: 'der',
        noun: 'Zahn',
        plural: null,
        english: 'tooth, teeth',
        da: 'tand',
        en: 'tooth',
        id: 649,
      },
      {
        article: 'der',
        noun: 'Zahnstocher',
        plural: null,
        english: 'toothpick',
        da: 'tandstikker',
        en: 'toothpick',
        id: 650,
      },
      {
        article: 'der',
        noun: 'Zauber',
        plural: null,
        english: 'magic',
        da: 'Magi',
        en: 'Magic',
        id: 651,
      },
      {
        article: 'der',
        noun: 'Zaun',
        plural: null,
        english: 'fence',
        da: 'hegn',
        en: 'fence',
        id: 652,
      },
      {
        article: 'der',
        noun: 'Zeigefinger',
        plural: null,
        english: 'pointing finger, index finger',
        da: 'pegefinger',
        en: 'index finger',
        id: 653,
      },
      {
        article: 'der',
        noun: 'Zerstörer',
        plural: null,
        english: 'the destroyer',
        da: 'destroyer',
        en: 'destroyer',
        id: 654,
      },
      {
        article: 'der',
        noun: 'Zipfel',
        plural: null,
        english: 'corner, point',
        da: 'Hale',
        en: 'Tail',
        id: 655,
      },
      {
        article: 'der',
        noun: 'Zoll',
        plural: null,
        english: 'tax, fee, toll, customs duty',
        da: 'inch',
        en: 'inch',
        id: 656,
      },
      {
        article: 'der',
        noun: 'Zoo',
        plural: null,
        english: 'zoo',
        da: 'Zoologisk have',
        en: 'zoo',
        id: 657,
      },
      {
        article: 'der',
        noun: 'Zucker',
        plural: null,
        english: 'sugar',
        da: 'sukker',
        en: 'sugar',
        id: 658,
      },
      {
        article: 'der',
        noun: 'Zweig',
        plural: null,
        english: 'twig',
        da: 'afdeling',
        en: 'branch',
        id: 659,
      },
      {
        article: 'der',
        noun: 'Übersetzer',
        plural: null,
        english: 'translator',
        da: 'oversætter',
        en: 'translator',
        id: 660,
      },
      {
        article: 'die',
        noun: 'Abhängigkeit',
        plural: null,
        english: 'addiction, dependence',
        da: 'Afhængighed',
        en: 'Dependence',
        id: 661,
      },
      {
        article: 'die',
        noun: 'Abteilung',
        plural: null,
        english: 'department',
        da: 'Afdeling',
        en: 'Department',
        id: 662,
      },
      {
        article: 'die',
        noun: 'Abwicklung',
        plural: null,
        english: 'liquidation, phaseout',
        da: 'færdiggørelse',
        en: 'completion',
        id: 663,
      },
      {
        article: 'die',
        noun: 'Ader',
        plural: null,
        english: 'artery',
        da: 'Vene',
        en: 'Vein',
        id: 664,
      },
      {
        article: 'die',
        noun: 'Ahnung',
        plural: null,
        english: 'suspicion, hunch',
        da: 'ide',
        en: 'idea',
        id: 665,
      },
      {
        article: 'die',
        noun: 'Alge',
        plural: null,
        english: 'algae',
        da: 'alge',
        en: 'alga',
        id: 666,
      },
      {
        article: 'die',
        noun: 'Ampel',
        plural: null,
        english: 'stop light',
        da: 'trafiklys',
        en: 'traffic light',
        id: 667,
      },
      {
        article: 'die',
        noun: 'Anbetung',
        plural: null,
        english: 'worship, adoration',
        da: 'tilbede',
        en: 'worship',
        id: 668,
      },
      {
        article: 'die',
        noun: 'Anfangsgründe',
        plural: null,
        english: 'elements (wie in chemie)',
        da: 'Årsager til at starte',
        en: 'Reasons to start',
        id: 669,
      },
      {
        article: 'die',
        noun: 'Angst',
        plural: null,
        english: 'axiety',
        da: 'angst',
        en: 'anxiety',
        id: 670,
      },
      {
        article: 'die',
        noun: 'Anhöhe',
        plural: null,
        english: 'bank, hill',
        da: 'bakke',
        en: 'hill',
        id: 671,
      },
      {
        article: 'die',
        noun: 'Anklage',
        plural: null,
        english: 'charge, - legal',
        da: 'anklage',
        en: 'accusation',
        id: 672,
      },
      {
        article: 'die',
        noun: 'Ankunft',
        plural: null,
        english: 'arrival',
        da: 'Ankomster',
        en: 'Arrivals',
        id: 673,
      },
      {
        article: 'die',
        noun: 'Apfelsine',
        plural: null,
        english: 'orange, orange tree',
        da: 'orange',
        en: 'Orange',
        id: 674,
      },
      {
        article: 'die',
        noun: 'Apotheke',
        plural: null,
        english: 'chemist, drug store',
        da: 'apotek',
        en: 'pharmacy',
        id: 675,
      },
      {
        article: 'die',
        noun: 'Aprikose',
        plural: null,
        english: 'apricot',
        da: 'abrikos',
        en: 'apricot',
        id: 676,
      },
      {
        article: 'die',
        noun: 'Armlehne',
        plural: null,
        english: 'armrest',
        da: 'armlæn',
        en: 'armrest',
        id: 677,
      },
      {
        article: 'die',
        noun: 'Art',
        plural: null,
        english: 'kind, sort, type',
        da: 'Kunst',
        en: 'Art',
        id: 678,
      },
      {
        article: 'die',
        noun: 'Atmung',
        plural: null,
        english: 'respiration, breathing',
        da: 'vejrtrækning',
        en: 'breathing',
        id: 679,
      },
      {
        article: 'die',
        noun: 'Auflage',
        plural: null,
        english: 'edition, imprint',
        da: 'Edition',
        en: 'Edition',
        id: 680,
      },
      {
        article: 'die',
        noun: 'Augenbraue',
        plural: null,
        english: 'eyebrows',
        da: 'Øjenbryn',
        en: 'Eyebrow',
        id: 681,
      },
      {
        article: 'die',
        noun: 'Ausnahme',
        plural: null,
        english: 'exception',
        da: 'undtagelse',
        en: 'exception',
        id: 682,
      },
      {
        article: 'die',
        noun: 'Autobahn',
        plural: null,
        english: 'autobahn, freeway',
        da: 'hovedvej',
        en: 'Highway',
        id: 683,
      },
      {
        article: 'die',
        noun: 'Backspacetaste',
        plural: null,
        english: 'backspace key',
        da: 'Backspace-knap',
        en: 'Backspace button',
        id: 684,
      },
      {
        article: 'die',
        noun: 'Backware',
        plural: null,
        english: 'baked goods',
        da: 'Bagværk',
        en: 'Baked goods',
        id: 685,
      },
      {
        article: 'die',
        noun: 'Badesachen',
        plural: null,
        english: 'bathing things',
        da: 'badetøj',
        en: 'swimwear',
        id: 686,
      },
      {
        article: 'die',
        noun: 'Badewanne',
        plural: null,
        english: 'bathtub',
        da: 'badekar',
        en: 'bathtub',
        id: 687,
      },
      {
        article: 'die',
        noun: 'Banane',
        plural: null,
        english: 'banana',
        da: 'banan',
        en: 'banana',
        id: 688,
      },
      {
        article: 'die',
        noun: 'Bank',
        plural: null,
        english: 'bank',
        da: 'Bank',
        en: 'Bank',
        id: 689,
      },
      {
        article: 'die',
        noun: 'Bank',
        plural: null,
        english: 'bench',
        da: 'Bank',
        en: 'Bank',
        id: 690,
      },
      {
        article: 'die',
        noun: 'Barmherzigkeit',
        plural: null,
        english: 'mercy',
        da: 'medfølelse',
        en: 'compassion',
        id: 691,
      },
      {
        article: 'die',
        noun: 'Beere',
        plural: null,
        english: 'berry',
        da: 'bær',
        en: 'berry',
        id: 692,
      },
      {
        article: 'die',
        noun: 'Begeisterung',
        plural: null,
        english: 'enthusiasm',
        da: 'entusiasme',
        en: 'enthusiasm',
        id: 693,
      },
      {
        article: 'die',
        noun: 'Bekannte',
        plural: null,
        english: 'acquaintance',
        da: 'kendt',
        en: 'Known',
        id: 694,
      },
      {
        article: 'die',
        noun: 'Besatzung',
        plural: null,
        english: 'crew (ins Gesamt)',
        da: 'mandskab',
        en: 'crew',
        id: 695,
      },
      {
        article: 'die',
        noun: 'Besichtigung',
        plural: null,
        english: 'sight seeing tour',
        da: 'sightseeing',
        en: 'sightseeing',
        id: 696,
      },
      {
        article: 'die',
        noun: 'Besprechung',
        plural: null,
        english: 'discussion',
        da: 'møde',
        en: 'meeting',
        id: 697,
      },
      {
        article: 'die',
        noun: 'Bezirksstadt',
        plural: null,
        english: 'district capital',
        da: 'Distrikt by',
        en: 'District town',
        id: 698,
      },
      {
        article: 'die',
        noun: 'Birne',
        plural: null,
        english: 'pear',
        da: 'pære',
        en: 'pear',
        id: 699,
      },
      {
        article: 'die',
        noun: 'Bohne',
        plural: null,
        english: 'beans',
        da: 'Bønne',
        en: 'Bean',
        id: 700,
      },
      {
        article: 'die',
        noun: 'Bohrmaschine',
        plural: null,
        english: 'drill',
        da: 'boremaskine',
        en: 'drilling machine',
        id: 701,
      },
      {
        article: 'die',
        noun: 'Bordkarte',
        plural: null,
        english: 'bording card',
        da: 'Boardingkort',
        en: 'Boarding pass',
        id: 702,
      },
      {
        article: 'die',
        noun: 'Boutique',
        plural: null,
        english: 'boutique',
        da: 'boutique',
        en: 'boutique',
        id: 703,
      },
      {
        article: 'die',
        noun: 'Bremse',
        plural: null,
        english: 'brake',
        da: 'bremse',
        en: 'brake',
        id: 704,
      },
      {
        article: 'die',
        noun: 'Brezel',
        plural: null,
        english: 'pretzel',
        da: 'kringle',
        en: 'pretzel',
        id: 705,
      },
      {
        article: 'die',
        noun: 'Brust',
        plural: null,
        english: 'chest, breasts (pl)',
        da: 'bryst',
        en: 'chest',
        id: 706,
      },
      {
        article: 'die',
        noun: 'Brücke',
        plural: null,
        english: 'bridge',
        da: 'bro',
        en: 'bridge',
        id: 707,
      },
      {
        article: 'die',
        noun: 'Buchhaltung',
        plural: null,
        english: 'finance office',
        da: 'regnskab',
        en: 'accounting',
        id: 708,
      },
      {
        article: 'die',
        noun: 'Buchhandlung',
        plural: null,
        english: 'bookstore',
        da: 'Boghandel',
        en: 'Bookstore',
        id: 709,
      },
      {
        article: 'die',
        noun: 'Bushaltestelle',
        plural: null,
        english: 'bus stop',
        da: 'Busstoppested',
        en: 'Bus stop',
        id: 710,
      },
      {
        article: 'die',
        noun: 'Butter',
        plural: null,
        english: 'butter',
        da: 'smør',
        en: 'butter',
        id: 711,
      },
      {
        article: 'die',
        noun: 'Decke',
        plural: null,
        english: 'ceiling, bed cover',
        da: 'tæppe',
        en: 'blanket',
        id: 712,
      },
      {
        article: 'die',
        noun: 'Digitalkamera',
        plural: null,
        english: 'digital camera',
        da: 'Digitalt kamera',
        en: 'Digital camera',
        id: 713,
      },
      {
        article: 'die',
        noun: 'Ding',
        plural: null,
        english: 'thing',
        da: 'ting',
        en: 'thing',
        id: 714,
      },
      {
        article: 'die',
        noun: 'Disko',
        plural: null,
        english: 'dance club',
        da: 'diskotek',
        en: 'disco',
        id: 715,
      },
      {
        article: 'die',
        noun: 'Dose',
        plural: null,
        english: 'tin of something',
        da: 'Kan',
        en: 'Can',
        id: 716,
      },
      {
        article: 'die',
        noun: 'Dreiheit',
        plural: null,
        english: 'trinity',
        da: 'Trinity',
        en: 'Trinity',
        id: 717,
      },
      {
        article: 'die',
        noun: 'Droge',
        plural: null,
        english: 'drugs',
        da: 'medicin',
        en: 'drug',
        id: 718,
      },
      {
        article: 'die',
        noun: 'Drogerie',
        plural: null,
        english: 'chemist, apotheke, drugstore',
        da: 'Apotek',
        en: 'Drugstore',
        id: 719,
      },
      {
        article: 'die',
        noun: 'Dunkelheit',
        plural: null,
        english: 'darkness',
        da: 'Mørke',
        en: 'Darkness',
        id: 720,
      },
      {
        article: 'die',
        noun: 'Durchwahl',
        plural: null,
        english: 'telephone extension',
        da: 'Udvidelse',
        en: 'Extension',
        id: 721,
      },
      {
        article: 'die',
        noun: 'Ecke',
        plural: null,
        english: 'corner',
        da: 'hjørne',
        en: 'corner',
        id: 722,
      },
      {
        article: 'die',
        noun: 'Ehre',
        plural: null,
        english: 'honor',
        da: 'Ære',
        en: 'honor',
        id: 723,
      },
      {
        article: 'die',
        noun: 'Eifersucht',
        plural: null,
        english: 'jealousy',
        da: 'misundelse',
        en: 'jealousy',
        id: 724,
      },
      {
        article: 'die',
        noun: 'Einkünfte',
        plural: null,
        english: 'earnings',
        da: 'indkomst',
        en: 'income',
        id: 725,
      },
      {
        article: 'die',
        noun: 'Einsamkeit',
        plural: null,
        english: 'loneliness',
        da: 'lonliness',
        en: 'lonliness',
        id: 726,
      },
      {
        article: 'die',
        noun: 'Empfindlichkeit',
        plural: null,
        english: 'sensitivity',
        da: 'følsomhed',
        en: 'sensitivity',
        id: 727,
      },
      {
        article: 'die',
        noun: 'Erbse',
        plural: null,
        english: 'pea',
        da: 'ært',
        en: 'pea',
        id: 728,
      },
      {
        article: 'die',
        noun: 'Erde',
        plural: null,
        english: 'earth',
        da: 'jorden',
        en: 'earth',
        id: 729,
      },
      {
        article: 'die',
        noun: 'Erhebung',
        plural: null,
        english: 'rise, ascension to power',
        da: 'Undersøgelse',
        en: 'Survey',
        id: 730,
      },
      {
        article: 'die',
        noun: 'Erkältung',
        plural: null,
        english: 'cold, cough',
        da: 'kold',
        en: 'cold',
        id: 731,
      },
      {
        article: 'die',
        noun: 'Ermutigung',
        plural: null,
        english: 'encouragement',
        da: 'opmuntring',
        en: 'encouragement',
        id: 732,
      },
      {
        article: 'die',
        noun: 'Ernährung',
        plural: null,
        english: 'nutrition',
        da: 'ernæring',
        en: 'nutrition',
        id: 733,
      },
      {
        article: 'die',
        noun: 'Errettung',
        plural: null,
        english: 'salvation',
        da: 'frelse',
        en: 'salvation',
        id: 734,
      },
      {
        article: 'die',
        noun: 'Erschöpfung',
        plural: null,
        english: 'exhaustion, fatigue',
        da: 'udmattelse',
        en: 'exhaustion',
        id: 735,
      },
      {
        article: 'die',
        noun: 'Erwachsene',
        plural: null,
        english: 'adult, grown up',
        da: 'Voksne',
        en: 'Adults',
        id: 736,
      },
      {
        article: 'die',
        noun: 'Fachkraft',
        plural: null,
        english: 'skilled workers, professional workers',
        da: 'professionel',
        en: 'professional',
        id: 737,
      },
      {
        article: 'die',
        noun: 'Fahrkarte',
        plural: null,
        english: 'train ticket, bus ticket, etc.',
        da: 'billet',
        en: 'ticket',
        id: 738,
      },
      {
        article: 'die',
        noun: 'Farbenpalette',
        plural: null,
        english: 'painters palette',
        da: 'Farvepalet',
        en: 'Color palette',
        id: 739,
      },
      {
        article: 'die',
        noun: 'Feile',
        plural: null,
        english: 'file',
        da: 'fil',
        en: 'file',
        id: 740,
      },
      {
        article: 'die',
        noun: 'Fensterbank',
        plural: null,
        english: 'window sill',
        da: 'vindueskarmen',
        en: 'Windowsill',
        id: 741,
      },
      {
        article: 'die',
        noun: 'Ferien',
        plural: null,
        english: 'holidays',
        da: 'ferie',
        en: 'vacation',
        id: 742,
      },
      {
        article: 'die',
        noun: 'Fernbedienung',
        plural: null,
        english: 'TV remote',
        da: 'fjernbetjening',
        en: 'remote control',
        id: 743,
      },
      {
        article: 'die',
        noun: 'Ferse',
        plural: null,
        english: 'heel',
        da: 'hæl',
        en: 'heel',
        id: 744,
      },
      {
        article: 'die',
        noun: 'Festplatte',
        plural: null,
        english: 'computer hard drive',
        da: 'harddisk',
        en: 'hard disk',
        id: 745,
      },
      {
        article: 'die',
        noun: 'Fischsemmel',
        plural: null,
        english: 'fish bread, fish sandwich',
        da: 'Fiskerulle',
        en: 'Fish roll',
        id: 746,
      },
      {
        article: 'die',
        noun: 'Flotte',
        plural: null,
        english: 'fleet, armada',
        da: 'flåde',
        en: 'fleet',
        id: 747,
      },
      {
        article: 'die',
        noun: 'Flucht',
        plural: null,
        english: 'escape, getaway',
        da: 'Flugt',
        en: 'Escape',
        id: 748,
      },
      {
        article: 'die',
        noun: 'Fluggesellschaft',
        plural: null,
        english: 'airline',
        da: 'flyselskab',
        en: 'airline',
        id: 749,
      },
      {
        article: 'die',
        noun: 'Flut',
        plural: null,
        english: 'flood',
        da: 'oversvømmelse',
        en: 'flood',
        id: 750,
      },
      {
        article: 'die',
        noun: 'Form',
        plural: null,
        english: 'form',
        da: 'form',
        en: 'shape',
        id: 751,
      },
      {
        article: 'die',
        noun: 'Fotokopie',
        plural: null,
        english: 'photocopy',
        da: 'fotokopi',
        en: 'Photocopy',
        id: 752,
      },
      {
        article: 'die',
        noun: 'Frage',
        plural: null,
        english: 'question',
        da: 'spørgsmål',
        en: 'question',
        id: 753,
      },
      {
        article: 'die',
        noun: 'Freizügigkeit',
        plural: null,
        english: 'liberality, permissiveness',
        da: 'Fri bevægelse',
        en: 'Free movement',
        id: 754,
      },
      {
        article: 'die',
        noun: 'Freude',
        plural: null,
        english: 'joy',
        da: 'glæde',
        en: 'joy',
        id: 755,
      },
      {
        article: 'die',
        noun: 'Frucht',
        plural: null,
        english: 'fruit',
        da: 'frugt',
        en: 'fruit',
        id: 756,
      },
      {
        article: 'die',
        noun: 'Frucht',
        plural: null,
        english: 'fruit',
        da: 'frugt',
        en: 'fruit',
        id: 757,
      },
      {
        article: 'die',
        noun: 'Fußgängerzone',
        plural: null,
        english: 'pedestrianized zone',
        da: 'Fodgængerfelt',
        en: 'Pedestrian zone',
        id: 758,
      },
      {
        article: 'die',
        noun: 'Gabel',
        plural: null,
        english: 'fork',
        da: 'gaffel',
        en: 'fork',
        id: 759,
      },
      {
        article: 'die',
        noun: 'Gans',
        plural: null,
        english: 'goose',
        da: 'gås',
        en: 'goose',
        id: 760,
      },
      {
        article: 'die',
        noun: 'Garantie',
        plural: null,
        english: 'guarantee',
        da: 'garanti',
        en: 'guarantee',
        id: 761,
      },
      {
        article: 'die',
        noun: 'Garderobe',
        plural: null,
        english: 'wardrobe',
        da: 'garderobe',
        en: 'wardrobe',
        id: 762,
      },
      {
        article: 'die',
        noun: 'Gardine',
        plural: null,
        english: 'drapes, curtains',
        da: 'gardin',
        en: 'curtain',
        id: 763,
      },
      {
        article: 'die',
        noun: 'Gebrauchsanweisung',
        plural: null,
        english: 'instruction manual',
        da: 'Brugsanvisning',
        en: 'Instructions for use',
        id: 764,
      },
      {
        article: 'die',
        noun: 'Gefühllosigkeit',
        plural: null,
        english: 'callousness, insensitivity',
        da: 'Følelsesløshed',
        en: 'Numbness',
        id: 765,
      },
      {
        article: 'die',
        noun: 'Geige',
        plural: null,
        english: 'violin',
        da: 'violin',
        en: 'violin',
        id: 766,
      },
      {
        article: 'die',
        noun: 'Gelegenheit',
        plural: null,
        english: 'opportunity',
        da: 'lejlighed',
        en: 'opportunity',
        id: 767,
      },
      {
        article: 'die',
        noun: 'Gerste',
        plural: null,
        english: 'barley',
        da: 'byg',
        en: 'barley',
        id: 768,
      },
      {
        article: 'die',
        noun: 'Geschichte',
        plural: null,
        english: 'history, story',
        da: 'historie',
        en: 'history',
        id: 769,
      },
      {
        article: 'die',
        noun: 'Geschwister',
        plural: null,
        english: 'brothers and sisters',
        da: 'brødre og søstre',
        en: 'brothers and sisters',
        id: 770,
      },
      {
        article: 'die',
        noun: 'Geselligkeit',
        plural: null,
        english: 'sociability',
        da: 'selskabelighed',
        en: 'Sociability',
        id: 771,
      },
      {
        article: 'die',
        noun: 'Glühlampe',
        plural: null,
        english: 'light bulb',
        da: 'Lys pære',
        en: 'Light bulb',
        id: 772,
      },
      {
        article: 'die',
        noun: 'Gottheit',
        plural: null,
        english: 'deity, Godhead',
        da: 'Guddommen',
        en: 'Deity',
        id: 773,
      },
      {
        article: 'die',
        noun: 'Grippe',
        plural: null,
        english: 'flu, sickness',
        da: 'influenza',
        en: 'flu',
        id: 774,
      },
      {
        article: 'die',
        noun: 'Grube',
        plural: null,
        english: 'pit, ditch',
        da: 'grube',
        en: 'pit',
        id: 775,
      },
      {
        article: 'die',
        noun: 'Größe',
        plural: null,
        english: 'size, format',
        da: 'størrelse',
        en: 'size',
        id: 776,
      },
      {
        article: 'die',
        noun: 'Gurgel',
        plural: null,
        english: 'throat',
        da: 'hals',
        en: 'throat',
        id: 777,
      },
      {
        article: 'die',
        noun: 'Gurke',
        plural: null,
        english: 'cucumber',
        da: 'agurk',
        en: 'cucumber',
        id: 778,
      },
      {
        article: 'die',
        noun: 'Gymnastik',
        plural: null,
        english: 'exercises',
        da: 'gymnastik',
        en: 'gymnastics',
        id: 779,
      },
      {
        article: 'die',
        noun: 'Halbpension',
        plural: null,
        english: 'room and board, full or half',
        da: 'Halvpension',
        en: 'Half board',
        id: 780,
      },
      {
        article: 'die',
        noun: 'Hand',
        plural: null,
        english: 'hand',
        da: 'hånd',
        en: 'hand',
        id: 781,
      },
      {
        article: 'die',
        noun: 'Harke',
        plural: null,
        english: 'rake',
        da: 'rive',
        en: 'rake',
        id: 782,
      },
      {
        article: 'die',
        noun: 'Hauptspeise',
        plural: null,
        english: 'main meal',
        da: 'hovedret',
        en: 'main course',
        id: 783,
      },
      {
        article: 'die',
        noun: 'Hausschuhe',
        plural: null,
        english: 'house shoes, slippers',
        da: 'hjemmesko',
        en: 'Slippers',
        id: 784,
      },
      {
        article: 'die',
        noun: 'Haut',
        plural: null,
        english: 'skin',
        da: 'hud',
        en: 'skin',
        id: 785,
      },
      {
        article: 'die',
        noun: 'Heiligamesse',
        plural: null,
        english: 'holy mass, church service',
        da: 'Hellig messe',
        en: 'Holy Mass',
        id: 786,
      },
      {
        article: 'die',
        noun: 'Heimat',
        plural: null,
        english: 'home',
        da: 'fædreland',
        en: 'homeland',
        id: 787,
      },
      {
        article: 'die',
        noun: 'Heizung',
        plural: null,
        english: 'heating',
        da: 'varmeapparat',
        en: 'heater',
        id: 788,
      },
      {
        article: 'die',
        noun: 'Hochzeit',
        plural: null,
        english: 'wedding',
        da: 'bryllup',
        en: 'wedding',
        id: 789,
      },
      {
        article: 'die',
        noun: 'Honigmelone',
        plural: null,
        english: 'honeydew melon',
        da: 'Honningmelon',
        en: 'Honeydew melon',
        id: 790,
      },
      {
        article: 'die',
        noun: 'Hurerei',
        plural: null,
        english: 'prostitution',
        da: 'utugt',
        en: 'Fornication',
        id: 791,
      },
      {
        article: 'die',
        noun: 'Höhe',
        plural: null,
        english: 'height',
        da: 'højde',
        en: 'height',
        id: 792,
      },
      {
        article: 'die',
        noun: 'Informationswirtschaft',
        plural: null,
        english: 'information economy',
        da: 'Informationsøkonomi',
        en: 'Information economy',
        id: 793,
      },
      {
        article: 'die',
        noun: 'Insel',
        plural: null,
        english: 'island',
        da: 'ø',
        en: 'island',
        id: 794,
      },
      {
        article: 'die',
        noun: 'Jacke',
        plural: null,
        english: 'jacket',
        da: 'jakke',
        en: 'jacket',
        id: 795,
      },
      {
        article: 'die',
        noun: 'Jalousie',
        plural: null,
        english: 'window blinds',
        da: 'louvre',
        en: 'louvre',
        id: 796,
      },
      {
        article: 'die',
        noun: 'Kachel',
        plural: null,
        english: 'tile',
        da: 'flise',
        en: 'tile',
        id: 797,
      },
      {
        article: 'die',
        noun: 'Kanone',
        plural: null,
        english: 'cannon',
        da: 'kanon',
        en: 'cannon',
        id: 798,
      },
      {
        article: 'die',
        noun: 'Karotte',
        plural: null,
        english: 'carrot',
        da: 'gulerod',
        en: 'carrot',
        id: 799,
      },
      {
        article: 'die',
        noun: 'Kartoffel',
        plural: null,
        english: 'potato',
        da: 'kartoffel',
        en: 'potato',
        id: 800,
      },
      {
        article: 'die',
        noun: 'Kelter',
        plural: null,
        english: 'winepress',
        da: 'Vinpresse',
        en: 'Wine press',
        id: 801,
      },
      {
        article: 'die',
        noun: 'Kerze',
        plural: null,
        english: 'candle',
        da: 'lys',
        en: 'candle',
        id: 802,
      },
      {
        article: 'die',
        noun: 'Kette',
        plural: null,
        english: 'chain',
        da: 'Lænke',
        en: 'Chain',
        id: 803,
      },
      {
        article: 'die',
        noun: 'Kirsche',
        plural: null,
        english: 'cherry',
        da: 'kirsebær',
        en: 'cherry',
        id: 804,
      },
      {
        article: 'die',
        noun: 'Kiste',
        plural: null,
        english: 'wine case',
        da: 'boks',
        en: 'box',
        id: 805,
      },
      {
        article: 'die',
        noun: 'Klappe',
        plural: null,
        english: 'flap, fig. trap, mouth',
        da: 'klap',
        en: 'flap',
        id: 806,
      },
      {
        article: 'die',
        noun: 'Kneipe',
        plural: null,
        english: 'pub',
        da: 'pub',
        en: 'pub',
        id: 807,
      },
      {
        article: 'die',
        noun: 'Knoblauchzehe',
        plural: null,
        english: 'garlic clove',
        da: 'Fed hvidløg',
        en: 'Clove of garlic',
        id: 808,
      },
      {
        article: 'die',
        noun: 'Kokonuss',
        plural: null,
        english: 'coconut',
        da: 'Kokosnød',
        en: 'Coconut',
        id: 809,
      },
      {
        article: 'die',
        noun: 'Kombination',
        plural: null,
        english: 'combination',
        da: 'kombination',
        en: 'combination',
        id: 810,
      },
      {
        article: 'die',
        noun: 'Konferenz',
        plural: null,
        english: 'conference',
        da: 'konference',
        en: 'conference',
        id: 811,
      },
      {
        article: 'die',
        noun: 'Konfession',
        plural: null,
        english: 'denomination',
        da: 'Betegnelse',
        en: 'Denomination',
        id: 812,
      },
      {
        article: 'die',
        noun: 'Kosten',
        plural: null,
        english: 'costs',
        da: 'omkostninger',
        en: 'costs',
        id: 813,
      },
      {
        article: 'die',
        noun: 'Krabbe',
        plural: null,
        english: 'shrimp',
        da: 'krabbe',
        en: 'crab',
        id: 814,
      },
      {
        article: 'die',
        noun: 'Krankmeldung',
        plural: null,
        english: "doctor's excuse",
        da: 'anmeldelse af sygdom',
        en: 'notification of illness',
        id: 815,
      },
      {
        article: 'die',
        noun: 'Krawatte',
        plural: null,
        english: 'necktie',
        da: 'Binde',
        en: 'Tie',
        id: 816,
      },
      {
        article: 'die',
        noun: 'Kreide',
        plural: null,
        english: 'chalk',
        da: 'kridt',
        en: 'chalk',
        id: 817,
      },
      {
        article: 'die',
        noun: 'Kreuzung',
        plural: null,
        english: 'intersection',
        da: 'overfart',
        en: 'crossing',
        id: 818,
      },
      {
        article: 'die',
        noun: 'Kriminalität',
        plural: null,
        english: 'crime rate',
        da: 'forbrydelse',
        en: 'crime',
        id: 819,
      },
      {
        article: 'die',
        noun: 'Kugel',
        plural: null,
        english: 'ball',
        da: 'Kugle',
        en: 'Bullet',
        id: 820,
      },
      {
        article: 'die',
        noun: 'Kusine',
        plural: null,
        english: 'cousin',
        da: 'fætter',
        en: 'cousin',
        id: 821,
      },
      {
        article: 'die',
        noun: 'Kälte',
        plural: null,
        english: 'the cold, i.e. cold weather',
        da: 'kold',
        en: 'cold',
        id: 822,
      },
      {
        article: 'die',
        noun: 'Kühltasche',
        plural: null,
        english: 'cooler, cold bag',
        da: 'Kølepose',
        en: 'Cooler bag',
        id: 823,
      },
      {
        article: 'die',
        noun: 'Kühnheit',
        plural: null,
        english: 'boldness',
        da: 'dristighed',
        en: 'audacity',
        id: 824,
      },
      {
        article: 'die',
        noun: 'Lampe',
        plural: null,
        english: 'lamp, light',
        da: 'lampe',
        en: 'lamp',
        id: 825,
      },
      {
        article: 'die',
        noun: 'Lanze',
        plural: null,
        english: 'lance',
        da: 'lanse',
        en: 'lance',
        id: 826,
      },
      {
        article: 'die',
        noun: 'Lebenshaltung',
        plural: null,
        english: 'cost of living',
        da: 'Holdning til livet',
        en: 'Attitude to life',
        id: 827,
      },
      {
        article: 'die',
        noun: 'Lebensphilosophie',
        plural: null,
        english: 'life philosophy',
        da: 'Livsfilosofi',
        en: 'Philosophy of life',
        id: 828,
      },
      {
        article: 'die',
        noun: 'Leichtathletik',
        plural: null,
        english: 'track and field',
        da: 'atletik',
        en: 'athletics',
        id: 829,
      },
      {
        article: 'die',
        noun: 'Leiter',
        plural: null,
        english: 'ladder',
        da: 'stige',
        en: 'ladder',
        id: 830,
      },
      {
        article: 'die',
        noun: 'Leitung',
        plural: null,
        english: 'leading, routing',
        da: 'ledelse',
        en: 'management',
        id: 831,
      },
      {
        article: 'die',
        noun: 'Limonade',
        plural: null,
        english: 'limonade',
        da: 'limonade',
        en: 'lemonade',
        id: 832,
      },
      {
        article: 'die',
        noun: 'Lippe',
        plural: null,
        english: 'lips',
        da: 'læbe',
        en: 'lip',
        id: 833,
      },
      {
        article: 'die',
        noun: 'List',
        plural: null,
        english: 'cunning, deceit',
        da: 'Cunning',
        en: 'Cunning',
        id: 834,
      },
      {
        article: 'die',
        noun: 'Lust',
        plural: null,
        english: 'desire, pleasure, joy',
        da: 'Begær',
        en: 'Lust',
        id: 835,
      },
      {
        article: 'die',
        noun: 'Lücke',
        plural: null,
        english: 'gap, hole',
        da: 'kløft',
        en: 'gap',
        id: 836,
      },
      {
        article: 'die',
        noun: 'Mahlzeit',
        plural: null,
        english: 'mealtime',
        da: 'Nyd måltidet',
        en: 'Enjoy the meal',
        id: 837,
      },
      {
        article: 'die',
        noun: 'Mannschaft',
        plural: null,
        english: 'sports team',
        da: 'hold',
        en: 'team',
        id: 838,
      },
      {
        article: 'die',
        noun: 'Marketingabteilung',
        plural: null,
        english: 'the marketing department',
        da: 'Marketings afdeling',
        en: 'Marketing department',
        id: 839,
      },
      {
        article: 'die',
        noun: 'Marmelade',
        plural: null,
        english: 'jam',
        da: 'marmelade',
        en: 'jam',
        id: 840,
      },
      {
        article: 'die',
        noun: 'Maschine',
        plural: null,
        english: 'airplane, machine',
        da: 'maskine',
        en: 'machine',
        id: 841,
      },
      {
        article: 'die',
        noun: 'Mauer',
        plural: null,
        english: 'wall',
        da: 'Væg',
        en: 'Wall',
        id: 842,
      },
      {
        article: 'die',
        noun: 'Medizin',
        plural: null,
        english: 'medicine',
        da: 'medicin',
        en: 'medicine',
        id: 843,
      },
      {
        article: 'die',
        noun: 'Meeresfrüchte',
        plural: null,
        english: 'seafood',
        da: 'fisk og skaldyr',
        en: 'seafood',
        id: 844,
      },
      {
        article: 'die',
        noun: 'Meile',
        plural: null,
        english: 'mile',
        da: 'mil',
        en: 'mile',
        id: 845,
      },
      {
        article: 'die',
        noun: 'Meinung',
        plural: null,
        english: 'view, opinion',
        da: 'mening',
        en: 'opinion',
        id: 846,
      },
      {
        article: 'die',
        noun: 'Metzgerei',
        plural: null,
        english: 'butcher shop',
        da: 'Slagterbutik',
        en: "Butcher's shop",
        id: 847,
      },
      {
        article: 'die',
        noun: 'Milch',
        plural: null,
        english: 'milk',
        da: 'mælk',
        en: 'milk',
        id: 848,
      },
      {
        article: 'die',
        noun: 'Mitternacht',
        plural: null,
        english: 'midnight',
        da: 'midnat',
        en: 'midnight',
        id: 849,
      },
      {
        article: 'die',
        noun: 'Möhre',
        plural: null,
        english: 'carrot',
        da: 'gulerod',
        en: 'carrot',
        id: 850,
      },
      {
        article: 'die',
        noun: 'Möwe',
        plural: null,
        english: 'seagull',
        da: 'måge',
        en: 'gull',
        id: 851,
      },
      {
        article: 'die',
        noun: 'Mühe',
        plural: null,
        english: 'trouble, pains',
        da: 'Problemer',
        en: 'Trouble',
        id: 852,
      },
      {
        article: 'die',
        noun: 'Münze',
        plural: null,
        english: 'cap',
        da: 'mønt',
        en: 'coin',
        id: 853,
      },
      {
        article: 'die',
        noun: 'Nachspeise',
        plural: null,
        english: 'dessert',
        da: 'dessert',
        en: 'dessert',
        id: 854,
      },
      {
        article: 'die',
        noun: 'Nacht',
        plural: null,
        english: 'night',
        da: 'nat',
        en: 'night',
        id: 855,
      },
      {
        article: 'die',
        noun: 'Nadel',
        plural: null,
        english: 'needle',
        da: 'nål',
        en: 'needle',
        id: 856,
      },
      {
        article: 'die',
        noun: 'Nase',
        plural: null,
        english: 'nose',
        da: 'næse',
        en: 'nose',
        id: 857,
      },
      {
        article: 'die',
        noun: 'Neffe',
        plural: null,
        english: 'nephew',
        da: 'nevø',
        en: 'nephew',
        id: 858,
      },
      {
        article: 'die',
        noun: 'Nektarine',
        plural: null,
        english: 'nectarine',
        da: 'nektarin',
        en: 'nectarine',
        id: 859,
      },
      {
        article: 'die',
        noun: 'Nichte',
        plural: null,
        english: 'niece',
        da: 'niece',
        en: 'niece',
        id: 860,
      },
      {
        article: 'die',
        noun: 'Niederlassung',
        plural: null,
        english: 'settlement',
        da: 'afdeling',
        en: 'branch',
        id: 861,
      },
      {
        article: 'die',
        noun: 'Not',
        plural: null,
        english: 'need, poverty',
        da: 'Nød',
        en: 'Distress',
        id: 862,
      },
      {
        article: 'die',
        noun: 'Nähe',
        plural: null,
        english: 'proximity',
        da: 'Nærhed',
        en: 'Closeness',
        id: 863,
      },
      {
        article: 'die',
        noun: 'Olive',
        plural: null,
        english: 'olive',
        da: 'oliven',
        en: 'olive',
        id: 864,
      },
      {
        article: 'die',
        noun: 'Ortschaft',
        plural: null,
        english: 'locality',
        da: 'lokalitet',
        en: 'Locality',
        id: 865,
      },
      {
        article: 'die',
        noun: 'Packung',
        plural: null,
        english: 'package or packet',
        da: 'pakke',
        en: 'pack',
        id: 866,
      },
      {
        article: 'die',
        noun: 'Pantoffel',
        plural: null,
        english: 'house slippers',
        da: 'tøffel',
        en: 'slipper',
        id: 867,
      },
      {
        article: 'die',
        noun: 'Pastete',
        plural: null,
        english: 'meat pie',
        da: 'pate',
        en: 'Pate',
        id: 868,
      },
      {
        article: 'die',
        noun: 'Personalabteilung',
        plural: null,
        english: 'personnel department',
        da: 'HR-afdeling',
        en: 'Human resources department',
        id: 869,
      },
      {
        article: 'die',
        noun: 'Pest',
        plural: null,
        english: 'pestilence',
        da: 'skadedyr',
        en: 'pest',
        id: 870,
      },
      {
        article: 'die',
        noun: 'Pfadfinder',
        plural: null,
        english: 'boy scouts',
        da: 'spejder',
        en: 'scout',
        id: 871,
      },
      {
        article: 'die',
        noun: 'Pfanne',
        plural: null,
        english: 'pan',
        da: 'pande',
        en: 'pan',
        id: 872,
      },
      {
        article: 'die',
        noun: 'Pfeffermühle',
        plural: null,
        english: 'pepper grinder',
        da: 'Peberkværn',
        en: 'Pepper mill',
        id: 873,
      },
      {
        article: 'die',
        noun: 'Pflaume',
        plural: null,
        english: 'plum',
        da: 'blomme',
        en: 'plum',
        id: 874,
      },
      {
        article: 'die',
        noun: 'Pfote',
        plural: null,
        english: 'paws',
        da: 'pote',
        en: 'paw',
        id: 875,
      },
      {
        article: 'die',
        noun: 'Pfüzte',
        plural: null,
        english: 'puddle',
        da: 'vandpytter',
        en: 'Puddles',
        id: 876,
      },
      {
        article: 'die',
        noun: 'Pistazie',
        plural: null,
        english: 'pistachio',
        da: 'pistacie',
        en: 'pistachio',
        id: 877,
      },
      {
        article: 'die',
        noun: 'Pizza',
        plural: null,
        english: 'pizza',
        da: 'pizza',
        en: 'Pizza',
        id: 878,
      },
      {
        article: 'die',
        noun: 'Platte',
        plural: null,
        english: 'platter, serving dish',
        da: 'plade',
        en: 'plate',
        id: 879,
      },
      {
        article: 'die',
        noun: 'Pommesfrites',
        plural: null,
        english: 'french fries',
        da: 'pommes frites',
        en: 'French fries',
        id: 880,
      },
      {
        article: 'die',
        noun: 'Portion',
        plural: null,
        english: 'portion',
        da: 'del',
        en: 'portion',
        id: 881,
      },
      {
        article: 'die',
        noun: 'Post',
        plural: null,
        english: 'post office',
        da: 'postkontor',
        en: 'post Office',
        id: 882,
      },
      {
        article: 'die',
        noun: 'Praline',
        plural: null,
        english: 'chocolate with cream or alcohol',
        da: 'Chokolade slik',
        en: 'Chocolate candy',
        id: 883,
      },
      {
        article: 'die',
        noun: 'Praxis',
        plural: null,
        english: 'doctor´s practice',
        da: 'øve sig',
        en: 'practice',
        id: 884,
      },
      {
        article: 'die',
        noun: 'Produktionsabteilung',
        plural: null,
        english: 'production department',
        da: 'Produktionsafdeling',
        en: 'Production department',
        id: 885,
      },
      {
        article: 'die',
        noun: 'Prägung',
        plural: null,
        english: 'imprint, minting, coinage',
        da: 'prægning',
        en: 'Embossing',
        id: 886,
      },
      {
        article: 'die',
        noun: 'Puppe',
        plural: null,
        english: 'doll',
        da: 'Dukke',
        en: 'Doll',
        id: 887,
      },
      {
        article: 'die',
        noun: 'Pute',
        plural: null,
        english: 'turkey',
        da: 'Kalkun',
        en: 'turkey',
        id: 888,
      },
      {
        article: 'die',
        noun: 'Pyramide',
        plural: null,
        english: 'pyramid',
        da: 'pyramide',
        en: 'pyramid',
        id: 889,
      },
      {
        article: 'die',
        noun: 'Quittung',
        plural: null,
        english: 'bill from car repair, etc',
        da: 'Kvittering',
        en: 'receipt',
        id: 890,
      },
      {
        article: 'die',
        noun: 'Realität',
        plural: null,
        english: 'reality',
        da: 'virkelighed',
        en: 'reality',
        id: 891,
      },
      {
        article: 'die',
        noun: 'Rechtsabteilung',
        plural: null,
        english: 'legal department',
        da: 'Juridisk afdeling',
        en: 'Legal department',
        id: 892,
      },
      {
        article: 'die',
        noun: 'Regel',
        plural: null,
        english: 'rules',
        da: 'Herske',
        en: 'rule',
        id: 893,
      },
      {
        article: 'die',
        noun: 'Reihe',
        plural: null,
        english: 'row, line',
        da: 'linje',
        en: 'line',
        id: 894,
      },
      {
        article: 'die',
        noun: 'Reihenfolge',
        plural: null,
        english: 'order, sequence',
        da: 'sekvens',
        en: 'sequence',
        id: 895,
      },
      {
        article: 'die',
        noun: 'Reinigung',
        plural: null,
        english: 'dry cleaners',
        da: 'rengøring',
        en: 'cleaning',
        id: 896,
      },
      {
        article: 'die',
        noun: 'Reise',
        plural: null,
        english: 'trip, journey',
        da: 'rejse',
        en: 'travel',
        id: 897,
      },
      {
        article: 'die',
        noun: 'Reiseunterlagen',
        plural: null,
        english: 'travel documents',
        da: 'Rejsedokumenter',
        en: 'Travel documents',
        id: 898,
      },
      {
        article: 'die',
        noun: 'Reklamation',
        plural: null,
        english: 'complaint, query',
        da: 'påstand',
        en: 'claim',
        id: 899,
      },
      {
        article: 'die',
        noun: 'Reklame',
        plural: null,
        english: 'advertising',
        da: 'Reklame',
        en: 'Advertising',
        id: 900,
      },
      {
        article: 'die',
        noun: 'Rettung',
        plural: null,
        english: 'rescue',
        da: 'redde',
        en: 'rescue',
        id: 901,
      },
      {
        article: 'die',
        noun: 'Richtung',
        plural: null,
        english: 'direction',
        da: 'retning',
        en: 'direction',
        id: 902,
      },
      {
        article: 'die',
        noun: 'Robbe',
        plural: null,
        english: 'a seal',
        da: 'forsegle',
        en: 'seal',
        id: 903,
      },
      {
        article: 'die',
        noun: 'Routine',
        plural: null,
        english: 'routine',
        da: 'rutine',
        en: 'routine',
        id: 904,
      },
      {
        article: 'die',
        noun: 'Ruhe',
        plural: null,
        english: 'quiet, calm, peace',
        da: 'Rolige',
        en: 'Quiet',
        id: 905,
      },
      {
        article: 'die',
        noun: 'Rösti',
        plural: null,
        english: 'fried grate potatoes, i.e. hash',
        da: 'rösti',
        en: 'Rösti',
        id: 906,
      },
      {
        article: 'die',
        noun: 'Rübe',
        plural: null,
        english: 'turnip',
        da: 'majroe',
        en: 'turnip',
        id: 907,
      },
      {
        article: 'die',
        noun: 'Rückflugticket',
        plural: null,
        english: 'a round trip ticket',
        da: 'Retur flybillet',
        en: 'Return flight ticket',
        id: 908,
      },
      {
        article: 'die',
        noun: 'Rüstung',
        plural: null,
        english: 'arms, weapons',
        da: 'rustning',
        en: 'armor',
        id: 909,
      },
      {
        article: 'die',
        noun: 'Sache',
        plural: null,
        english: 'thing, things',
        da: 'Ting',
        en: 'Thing',
        id: 910,
      },
      {
        article: 'die',
        noun: 'Sahne',
        plural: null,
        english: 'cream',
        da: 'fløde',
        en: 'cream',
        id: 911,
      },
      {
        article: 'die',
        noun: 'Salbe',
        plural: null,
        english: 'salve, ointment',
        da: 'salve',
        en: 'ointment',
        id: 912,
      },
      {
        article: 'die',
        noun: 'Salbung',
        plural: null,
        english: 'anointing',
        da: 'salvelse',
        en: 'Anointing',
        id: 913,
      },
      {
        article: 'die',
        noun: 'Sandgrube',
        plural: null,
        english: 'sand pit',
        da: 'Sand pit',
        en: 'Sand pit',
        id: 914,
      },
      {
        article: 'die',
        noun: 'Schachtel',
        plural: null,
        english: 'small box',
        da: 'boks',
        en: 'box',
        id: 915,
      },
      {
        article: 'die',
        noun: 'Schale',
        plural: null,
        english: 'small bowl',
        da: 'Skål',
        en: 'Bowl',
        id: 916,
      },
      {
        article: 'die',
        noun: 'Schar',
        plural: null,
        english: 'band of people',
        da: 'Flock',
        en: 'Flock',
        id: 917,
      },
      {
        article: 'die',
        noun: 'Schlange',
        plural: null,
        english: 'snake, serpent, line of people',
        da: 'Slange',
        en: 'Snake',
        id: 918,
      },
      {
        article: 'die',
        noun: 'Schminke',
        plural: null,
        english: 'makeup',
        da: 'Makeup',
        en: 'Make up',
        id: 919,
      },
      {
        article: 'die',
        noun: 'Schnecke',
        plural: null,
        english: 'snail',
        da: 'slug',
        en: 'slug',
        id: 920,
      },
      {
        article: 'die',
        noun: 'Schokolade',
        plural: null,
        english: 'chocolate',
        da: 'chokolade',
        en: 'chocolate',
        id: 921,
      },
      {
        article: 'die',
        noun: 'Scholle',
        plural: null,
        english: '(ice) floe',
        da: 'rødspætte',
        en: 'plaice',
        id: 922,
      },
      {
        article: 'die',
        noun: 'Schorle',
        plural: null,
        english: 'spritzer',
        da: 'spritzer',
        en: 'spritzer',
        id: 923,
      },
      {
        article: 'die',
        noun: 'Schublade',
        plural: null,
        english: 'drawer',
        da: 'skuffe',
        en: 'drawer',
        id: 924,
      },
      {
        article: 'die',
        noun: 'Schule',
        plural: null,
        english: 'school',
        da: 'skole',
        en: 'school',
        id: 925,
      },
      {
        article: 'die',
        noun: 'Schulter',
        plural: null,
        english: 'shoulder',
        da: 'skulder',
        en: 'shoulder',
        id: 926,
      },
      {
        article: 'die',
        noun: 'Schwelle',
        plural: null,
        english: 'threshhold - psych, phys, door threshhold',
        da: 'Grænseværdi',
        en: 'threshold',
        id: 927,
      },
      {
        article: 'die',
        noun: 'Schwerathletik',
        plural: null,
        english: 'contact sports',
        da: 'Tung atletik',
        en: 'Heavy athletics',
        id: 928,
      },
      {
        article: 'die',
        noun: 'Schwester',
        plural: null,
        english: 'sister',
        da: 'søster',
        en: 'sister',
        id: 929,
      },
      {
        article: 'die',
        noun: 'Serviette',
        plural: null,
        english: 'napkin',
        da: 'serviet',
        en: 'napkin',
        id: 930,
      },
      {
        article: 'die',
        noun: 'Siebensachen',
        plural: null,
        english: 'belongings',
        da: 'Ting',
        en: 'Things',
        id: 931,
      },
      {
        article: 'die',
        noun: 'Socke',
        plural: null,
        english: 'socks',
        da: 'sok',
        en: 'sock',
        id: 932,
      },
      {
        article: 'die',
        noun: 'Sorge',
        plural: null,
        english: 'worry',
        da: 'Bekymre',
        en: 'Worry',
        id: 933,
      },
      {
        article: 'die',
        noun: 'Soße',
        plural: null,
        english: 'sauce',
        da: 'sovs',
        en: 'sauce',
        id: 934,
      },
      {
        article: 'die',
        noun: 'Spalte',
        plural: null,
        english: 'column',
        da: 'kolonne',
        en: 'column',
        id: 935,
      },
      {
        article: 'die',
        noun: 'Spielzeugkiste',
        plural: null,
        english: 'toybox',
        da: 'legetøjskasse',
        en: 'toy box',
        id: 936,
      },
      {
        article: 'die',
        noun: 'Spinne',
        plural: null,
        english: 'spider',
        da: 'edderkop',
        en: 'spider',
        id: 937,
      },
      {
        article: 'die',
        noun: 'Sportskanone',
        plural: null,
        english: 'sports star',
        da: 'Sportskanon',
        en: 'Sports cannon',
        id: 938,
      },
      {
        article: 'die',
        noun: 'Sprechstundenhilfe',
        plural: null,
        english: 'receptionist',
        da: 'Konsultationstider',
        en: 'Consultation hours',
        id: 939,
      },
      {
        article: 'die',
        noun: 'Spritze',
        plural: null,
        english: 'syringe, shot',
        da: 'sprøjte',
        en: 'syringe',
        id: 940,
      },
      {
        article: 'die',
        noun: 'Spur',
        plural: null,
        english: 'track, trace, sign',
        da: 'spore',
        en: 'track',
        id: 941,
      },
      {
        article: 'die',
        noun: 'Stange',
        plural: null,
        english: 'pole, bar',
        da: 'pol',
        en: 'pole',
        id: 942,
      },
      {
        article: 'die',
        noun: 'Startbahn',
        plural: null,
        english: 'runway',
        da: 'Runway',
        en: 'Runway',
        id: 943,
      },
      {
        article: 'die',
        noun: 'Station',
        plural: null,
        english: 'station, stop',
        da: 'station',
        en: 'station',
        id: 944,
      },
      {
        article: 'die',
        noun: 'Stimme',
        plural: null,
        english: 'voice, part',
        da: 'stemme',
        en: 'voice',
        id: 945,
      },
      {
        article: 'die',
        noun: 'Stirn',
        plural: null,
        english: 'forehead',
        da: 'pande',
        en: 'forehead',
        id: 946,
      },
      {
        article: 'die',
        noun: 'Strafe',
        plural: null,
        english: 'penalty, punishment',
        da: 'straf',
        en: 'punishment',
        id: 947,
      },
      {
        article: 'die',
        noun: 'Strahlung',
        plural: null,
        english: 'radiation',
        da: 'stråling',
        en: 'radiation',
        id: 948,
      },
      {
        article: 'die',
        noun: 'Straßenbahn',
        plural: null,
        english: 'tram, streetcar',
        da: 'sporvogn',
        en: 'tram',
        id: 949,
      },
      {
        article: 'die',
        noun: 'Streichholzschachtel',
        plural: null,
        english: 'matchbox',
        da: 'Matchbox',
        en: 'Matchbox',
        id: 950,
      },
      {
        article: 'das',
        noun: 'Stäbchen',
        plural: null,
        english: 'chopsticks',
        da: 'stang',
        en: 'rod',
        id: 951,
      },
      {
        article: 'die',
        noun: 'Suppenkelle',
        plural: null,
        english: 'soup ladle',
        da: 'Suppe-øse',
        en: 'Soup ladle',
        id: 952,
      },
      {
        article: 'die',
        noun: 'Säge',
        plural: null,
        english: 'a saw',
        da: 'sav',
        en: 'saw',
        id: 953,
      },
      {
        article: 'die',
        noun: 'Säuregurke',
        plural: null,
        english: 'pickles',
        da: 'Pickle',
        en: 'Pickle',
        id: 954,
      },
      {
        article: 'die',
        noun: 'Sünde',
        plural: null,
        english: 'sin',
        da: 'synd',
        en: 'sin',
        id: 955,
      },
      {
        article: 'die',
        noun: 'Süße',
        plural: null,
        english: 'sweets',
        da: 'Sød',
        en: 'Sweet',
        id: 956,
      },
      {
        article: 'die',
        noun: 'Süßigkeit',
        plural: null,
        english: 'sweets',
        da: 'Slik',
        en: 'Candy',
        id: 957,
      },
      {
        article: 'die',
        noun: 'Tablette',
        plural: null,
        english: 'pills, tablets',
        da: 'tablet',
        en: 'tablet',
        id: 958,
      },
      {
        article: 'die',
        noun: 'Tafel',
        plural: null,
        english: 'bar of something',
        da: 'tavle',
        en: 'blackboard',
        id: 959,
      },
      {
        article: 'die',
        noun: 'Tante',
        plural: null,
        english: 'aunt',
        da: 'tante',
        en: 'aunt',
        id: 960,
      },
      {
        article: 'die',
        noun: 'Tasche',
        plural: null,
        english: 'pocket, bag',
        da: 'taske',
        en: 'bag',
        id: 961,
      },
      {
        article: 'die',
        noun: 'Tassen',
        plural: null,
        english: 'coffee, tea cup',
        da: 'kopper',
        en: 'cups',
        id: 962,
      },
      {
        article: 'die',
        noun: 'Taucherbrille',
        plural: null,
        english: 'diving goggles',
        da: 'svømmebriller',
        en: 'swimming goggles',
        id: 963,
      },
      {
        article: 'die',
        noun: 'Tenne',
        plural: null,
        english: 'threshing floor',
        da: 'Tærskende gulv',
        en: 'Threshing floor',
        id: 964,
      },
      {
        article: 'die',
        noun: 'Theologie',
        plural: null,
        english: 'theology',
        da: 'teologi',
        en: 'theology',
        id: 965,
      },
      {
        article: 'die',
        noun: 'Tiefkühltruhe',
        plural: null,
        english: 'refrigerator chest - horizontal, deep freeze',
        da: 'Fryser',
        en: 'Freezer',
        id: 966,
      },
      {
        article: 'die',
        noun: 'Tonne',
        plural: null,
        english: 'barrel',
        da: 'ton',
        en: 'ton',
        id: 967,
      },
      {
        article: 'die',
        noun: 'Torte',
        plural: null,
        english: 'fruit pie',
        da: 'kage',
        en: 'cake',
        id: 968,
      },
      {
        article: 'die',
        noun: 'Treppe',
        plural: null,
        english: 'stairs',
        da: 'trappe',
        en: 'stairway',
        id: 969,
      },
      {
        article: 'die',
        noun: 'Träne',
        plural: null,
        english: 'tear',
        da: 'tåre',
        en: 'tear',
        id: 970,
      },
      {
        article: 'die',
        noun: 'Tüte',
        plural: null,
        english: 'bag, cone',
        da: 'taske',
        en: 'bag',
        id: 971,
      },
      {
        article: 'die',
        noun: 'Umfrage',
        plural: null,
        english: 'job Interview',
        da: 'undersøgelse',
        en: 'survey',
        id: 972,
      },
      {
        article: 'die',
        noun: 'Ungelegenheiten',
        plural: null,
        english: 'inconvenience (un-opportunity)',
        da: 'ulemper',
        en: 'Inconveniences',
        id: 973,
      },
      {
        article: 'die',
        noun: 'Universität',
        plural: null,
        english: 'university',
        da: 'universitet',
        en: 'university',
        id: 974,
      },
      {
        article: 'die',
        noun: 'Unsrigen',
        plural: null,
        english: 'our people',
        da: 'vores',
        en: 'Ours',
        id: 975,
      },
      {
        article: 'die',
        noun: 'Unterkunft',
        plural: null,
        english: 'accommodation',
        da: 'indkvartering',
        en: 'accommodation',
        id: 976,
      },
      {
        article: 'die',
        noun: 'Unterlage',
        plural: null,
        english: 'documents',
        da: 'dokument',
        en: 'document',
        id: 977,
      },
      {
        article: 'die',
        noun: 'Untertasse',
        plural: null,
        english: 'saucer',
        da: 'Saucer',
        en: 'Saucer',
        id: 978,
      },
      {
        article: 'die',
        noun: 'Urgroßmutter',
        plural: null,
        english: 'grandmother',
        da: 'Oldemor',
        en: 'Great grandmother',
        id: 979,
      },
      {
        article: 'die',
        noun: 'Verabredung',
        plural: null,
        english: 'arrangement, private appointment',
        da: 'møde',
        en: 'meeting',
        id: 980,
      },
      {
        article: 'die',
        noun: 'Verführung',
        plural: null,
        english: 'seduction, temptation',
        da: 'forførelse',
        en: 'seduction',
        id: 981,
      },
      {
        article: 'die',
        noun: 'Vergangenheit',
        plural: null,
        english: 'past tense, past time',
        da: 'forbi',
        en: 'past',
        id: 982,
      },
      {
        article: 'die',
        noun: 'Vermittlung',
        plural: null,
        english: 'mediation, intercession',
        da: 'Mægling',
        en: 'Mediation',
        id: 983,
      },
      {
        article: 'die',
        noun: 'Versammlung',
        plural: null,
        english: 'assembly',
        da: 'montage',
        en: 'Assembly',
        id: 984,
      },
      {
        article: 'die',
        noun: 'Versichertenkarte',
        plural: null,
        english: 'insurance card',
        da: 'Forsikringskort',
        en: 'Insurance card',
        id: 985,
      },
      {
        article: 'die',
        noun: 'Versicherung',
        plural: null,
        english: 'insurance',
        da: 'forsikring',
        en: 'insurance',
        id: 986,
      },
      {
        article: 'die',
        noun: 'Versklavung',
        plural: null,
        english: 'enslavement',
        da: 'slaveri',
        en: 'enslavement',
        id: 987,
      },
      {
        article: 'die',
        noun: 'Versorgung',
        plural: null,
        english: 'care, provision',
        da: 'omsorg',
        en: 'care',
        id: 988,
      },
      {
        article: 'die',
        noun: 'Verspottung',
        plural: null,
        english: 'mockery',
        da: 'hån',
        en: 'Mockery',
        id: 989,
      },
      {
        article: 'die',
        noun: 'Verspätung',
        plural: null,
        english: 'delay',
        da: 'forsinke',
        en: 'delay',
        id: 990,
      },
      {
        article: 'die',
        noun: 'Verstörung',
        plural: null,
        english: 'destruction',
        da: 'Forstyrrelse',
        en: 'Disturbance',
        id: 991,
      },
      {
        article: 'die',
        noun: 'Verwaltung',
        plural: null,
        english: 'administration (not in schools)',
        da: 'administration',
        en: 'administration',
        id: 992,
      },
      {
        article: 'die',
        noun: 'Verzeihung',
        plural: null,
        english: 'forgiveness',
        da: 'tilgivelse',
        en: 'forgiveness',
        id: 993,
      },
      {
        article: 'die',
        noun: 'Videothek',
        plural: null,
        english: 'video store',
        da: 'Videobibliotek',
        en: 'Video library',
        id: 994,
      },
      {
        article: 'die',
        noun: 'Vorschrift',
        plural: null,
        english: 'commandment, directive',
        da: 'Regulering',
        en: 'Regulation',
        id: 995,
      },
      {
        article: 'die',
        noun: 'Vorsicht',
        plural: null,
        english: 'caution, care',
        da: 'Opmærksomhed',
        en: 'Attention',
        id: 996,
      },
      {
        article: 'die',
        noun: 'Vorspeise',
        plural: null,
        english: 'appetizer',
        da: 'forret',
        en: 'starter',
        id: 997,
      },
      {
        article: 'die',
        noun: 'Vorstellung',
        plural: null,
        english: 'idea, picture',
        da: 'ide',
        en: 'idea',
        id: 998,
      },
      {
        article: 'die',
        noun: 'Vorwahl',
        plural: null,
        english: 'preliminary election',
        da: 'præfiks',
        en: 'prefix',
        id: 999,
      },
      {
        article: 'die',
        noun: 'Waffe',
        plural: null,
        english: 'weapon',
        da: 'våben',
        en: 'weapon',
        id: 1000,
      },
    ];

    if (this.syncStateService.ready) {
      this.initActiveWord();
    }
  }

  private upateStats() {
    const nounStats = this.syncStateService.getNounStats(this.noun.id);
    const statValues = this.syncStateService.statisticValues;

    if (this.badTranslation) {
      nounStats.badTranslation = true;
    }

    if (this.later) {
      nounStats.later = true;
    } else if (this.never) {
      nounStats.never = true;
    } else if (this.firstGuessWrong) {
      statValues.wrongCount++;
      statValues.currentStreak = 0;
      nounStats.wrongCount++;
      nounStats.lastWrongIndex = statValues.wordCount;
      nounStats.currentStreak = 0;
    } else {
      statValues.correctCount++;
      statValues.currentStreak++;
      if (statValues.currentStreak > statValues.longestStreak) {
        statValues.longestStreak = statValues.currentStreak;
      }
      nounStats.correctCount++;
      nounStats.lastCorrectIndex = statValues.wordCount;
      nounStats.currentStreak++;
      if (nounStats.currentStreak > nounStats.longestStreak) {
        nounStats.longestStreak = nounStats.currentStreak;
      }
    }
    statValues.wordCount++;
    this.syncStateService.statisticValues = statValues;
    this.syncStateService.updateNounStats(nounStats);

    this.nounsHighscoreService.updateHighscore(this.userService.userId, {
      userId: `${this.userService.userId}`,
      name: `${this.userService.name}`,
      completed: this.completedNouns.length,
      longestStreak: this.syncStateService.statisticValues.longestStreak,
    });
  }

  private getNounById(id: number): Noun {
    return this.nouns.find((noun) => noun.id === id);
  }

  private getRandomNoun(): Noun {
    for (let i = 0; i < 400; i++) {
      // only try 400 times just in case
      const noun = this.nouns[Math.floor(Math.random() * this.nouns.length)];
      if (!this.syncStateService.hasNounStats(noun.id)) {
        return noun;
      } else if (i > 100) {
        // if hard to find a free word allow words set for later
        const nounStats = this.syncStateService.getNounStats(noun.id);
        if (nounStats.currentStreak < 10 && !nounStats.never) {
          return noun;
        }
      }
    }
    return this.nouns[Math.floor(Math.random() * this.nouns.length)];
  }

  private pickRandomNoun(nouns: NounStats[]) {
    if (nouns.length > 1) {
      const index = Math.floor(Math.random() * nouns.length);
      return this.getNounById(nouns[index].id);
    }
    return this.getNounById(nouns[0].id);
  }

  private get openNouns(): NounStats[] {
    return this.syncStateService.nounStats.filter(
      (n) => n.currentStreak < 10 && !n.never && !n.later
    );
  }

  private get completedNouns(): NounStats[] {
    return this.syncStateService.nounStats.filter((n) => n.currentStreak >= 10);
  }

  private nextWord() {
    this.firstGuessWrong = false;
    this.never = false;
    this.later = false;
    this.badTranslation = false;
    const lastId = this.activeNoun?.id;
    do {
      const openNouns = this.openNouns;
      if (openNouns.length < 50) {
        this.activeNoun = this.getRandomNoun();
      } else {
        const completedNouns = this.completedNouns;
        if (
          this.syncStateService.statisticValues.wordCount % 50 === 0 &&
          completedNouns.length > 0
        ) {
          this.activeNoun = this.pickRandomNoun(completedNouns);
        } else {
          this.activeNoun = this.pickRandomNoun(openNouns);
        }
      }
    } while (this.activeNoun.id === lastId);
    this.syncStateService.activeNoun = this.activeNoun.id;
    const nounStats = this.syncStateService.getNounStats(this.activeNoun.id);
    if (nounStats && nounStats.badTranslation) {
      this.badTranslation = true;
    }

    this.serviceContentUpdated();
  }

  get noun(): Noun {
    if (this.activeNoun) {
      return this.activeNoun;
    }
    return {
      id: 0,
      article: '',
      noun: 'Loading...',
      plural: '',
      english: '',
      da: '',
      en: '',
    };
  }

  get error(): string | null {
    return this.errorText;
  }

  get ready(): boolean {
    return this._ready && this.syncStateService.ready;
  }

  get isDer(): boolean {
    return this.noun.article === 'der';
  }

  get isDie(): boolean {
    return this.noun.article === 'die';
  }

  get isDas(): boolean {
    return this.noun.article === 'das';
  }

  async chooseDer() {
    if (this.noun.article === 'der') {
      this.upateStats();
      this.nextWord();
    } else {
      this.firstGuessWrong = true;
    }
  }

  async chooseDie() {
    if (this.noun.article === 'die') {
      this.upateStats();
      this.nextWord();
    } else {
      this.firstGuessWrong = true;
    }
  }

  async chooseDas() {
    if (this.noun.article === 'das') {
      this.upateStats();
      this.nextWord();
    } else {
      this.firstGuessWrong = true;
    }
  }

  async neverLearn() {
    this.never = true;
    this.upateStats();
    this.nextWord();
  }

  async saveForLater() {
    this.later = true;
    this.upateStats();
    this.nextWord();
  }

  async reportBadTranslation() {
    this.badTranslation = true;
    let report;
    try {
      report = await this.ezrestService.globalGet(
        `/ffa/reports/${this.noun.id}`
      );
    } catch {
      report = {
        id: this.noun.id,
        noun: this.noun.noun,
        badTranslations: 0,
      };
    }
    report.badTranslations++;
    await this.ezrestService.globalPut(`/ffa/reports/${this.noun.id}`, report);
  }

  get alreadyReported(): boolean {
    return this.badTranslation;
  }

  get streak(): number {
    return this.firstGuessWrong
      ? 0
      : this.syncStateService.statisticValues.currentStreak;
  }

  get isAuthenticated(): boolean {
    return this.ezrestService.isAuthenticated;
  }

  get currentVersion(): string {
    return this.serverVersion;
  }
}
