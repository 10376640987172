import { Component } from '@angular/core';

@Component({
  selector: 'app-noun-highscore-page',
  templateUrl: './noun-highscore-page.component.html',
  styleUrls: ['./noun-highscore-page.component.scss']
})
export class NounHighscorePageComponent {


}
