import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/framework/base.service';
import { Noun } from '../../nouns/nouns.service';
import { EzrestService } from 'src/app/services/http/ezrest/ezrest.service';

@Injectable({
  providedIn: 'root'
})
export class AdminNounsService extends BaseService {
  nouns: Noun[];

  constructor(private ezrestService: EzrestService) {
    super();
    this.loadData();
  }

  private async loadData() {
    this.nouns = await this.ezrestService.globalGet('/nouns');
    this.serviceContentUpdated();
  }

  protected async update(): Promise<void> {
  }
}
