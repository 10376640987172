import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { NounsService, Noun } from 'src/app/services/business/nouns/nouns.service';
import { BaseComponent } from 'src/app/framework/base.component';
import { UserService } from '../../services/business/user/user.service';

@Component({
  selector: 'app-noun-gender',
  templateUrl: './noun-gender.component.html',
  styleUrls: ['./noun-gender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NounGenderComponent extends BaseComponent {
  isCorrect = false;
  isGenderDown = false;
  isGenderUp = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private nounsService: NounsService,
    private userService: UserService

  ) {
    super(changeDetectorRef);
  }

  get error(): string | null {
    return this.nounsService.error;
  }

  get ready(): boolean {
    return this.nounsService.ready;
  }

  get isDer(): boolean {
    return this.nounsService.isDer;
  }

  get isDie(): boolean {
    return this.nounsService.isDie;
  }

  get isDas(): boolean {
    return this.nounsService.isDas;
  }

  get noun(): Noun {
    return this.nounsService.noun;
  }

  get streak(): number {
    return this.nounsService.streak;
  }

  get alreadyReported(): boolean {
    return this.nounsService.alreadyReported;
  }

  reportBadTranslation() {
    this.nounsService.reportBadTranslation();
    alert('Thank you!');
  }

  neverClicked() {
    this.nounsService.neverLearn();
  }

  laterClicked() {
    this.nounsService.saveForLater();
  }

  derClicked() {
    this.nounsService.chooseDer();
  }

  dieClicked() {
    this.nounsService.chooseDie();
  }

  dasClicked() {
    this.nounsService.chooseDas();
  }

  genderDown(correct) {
    this.isGenderDown = true;
    this.isGenderUp = false;
    this.isCorrect  = correct;
  }

  genderUp() {
    this.isGenderDown = false;
    this.isGenderUp = true;
  }

  get userId(): string {
    return this.userService.userId;
  }

  get currentVersion(): string {
    return this.nounsService.currentVersion;
  }
}
