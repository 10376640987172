<div *ngIf="error" class="error">
  {{ error }}
</div>
<app-main-menu></app-main-menu>
<div *ngIf="ready" class="noun-gender">
  <div class="noun-gender-top">
    <div class="noun-status">{{ streak }}</div>
    <div class="noun">
      <div
        class="noun-german"
        [class.noun-german-correct--down]="isGenderDown && isCorrect"
        [class.noun-german-correct--up]="isGenderUp && isCorrect"
        [class.noun-german-wrong--down]="isGenderDown && !isCorrect"
        [class.noun-german-wrong--up]="isGenderUp && !isCorrect"
        [class.noun-german--long]="this.noun.noun.length > 14"
      >
        {{ this.noun.noun }}
      </div>
      <div class="noun-translation">
        <div class="noun-translation-title">(meaning)</div>
        <div class="noun-translation-description">{{ this.noun.english }}</div>
        <div class="noun-translation-description">{{ this.noun.da }}</div>
        <div class="noun-translation-functions">
          <!-- <div *ngIf="!alreadyReported" class="noun-translation-report" (click)="reportBadTranslation()">report</div> -->
          <div class="noun-translation-link">
            <a
              target="_blank"
              [href]="'https://de.wiktionary.org/wiki/' + this.noun.noun"
              >Wiktionary</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <div class="gender">
      <app-gender-button
        [isCorrect]="isDer"
        (activated)="derClicked()"
        (down)="genderDown(isDer)"
        (up)="genderUp()"
        >der</app-gender-button
      >
      <app-gender-button
        [isCorrect]="isDie"
        (activated)="dieClicked()"
        (down)="genderDown(isDie)"
        (up)="genderUp()"
        >die</app-gender-button
      >
      <app-gender-button
        [isCorrect]="isDas"
        (activated)="dasClicked()"
        (down)="genderDown(isDas)"
        (up)="genderUp()"
        >das</app-gender-button
      >
    </div>
    <div class="feedback">
      <app-rate-button (activated)="neverClicked()">never</app-rate-button>
      <app-rate-button (activated)="laterClicked()">later</app-rate-button>
    </div>
  </div>
</div>
<!-- <div class="version">{{ userId }} @ {{ currentVersion }}</div> -->
