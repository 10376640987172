import { BaseComponent } from './base.component';

export class ServiceComponentUpdater {
  static components: BaseComponent[] = [];

  static register(comp: BaseComponent) {
    ServiceComponentUpdater.components.push(comp);
  }

  static unRegister(comp: BaseComponent) {
    const index = ServiceComponentUpdater.components.indexOf(comp);
    if (index >= 0) {
      ServiceComponentUpdater.components.splice(index, 1);
    }
  }

  static update() {
    ServiceComponentUpdater.components.forEach(comp => {
      comp.update();
    });
  }
}
