import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ServiceComponentUpdater } from './service-component-updater.service';

@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {
  constructor(private changeDetector: ChangeDetectorRef) {
    ServiceComponentUpdater.register(this);
  }

  ngOnDestroy() {
    ServiceComponentUpdater.unRegister(this);
  }

  update(): void {
    this.changeDetector.detectChanges();
  }
}
