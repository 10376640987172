import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NounGenderPageComponent } from './pages/noun-gender-page/noun-gender-page.component';
import { NounHighscorePageComponent } from './pages/noun-highscore-page/noun-highscore-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CreateAccountPageComponent } from './pages/create-account-page/create-account-page.component';
import { AuthGuard } from './guards/auth/auth-guard.service';
import { ManageNounsComponent } from './components/admin/manage-nouns/manage-nouns.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/noun-gender',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    component: ManageNounsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'create-account',
    component: CreateAccountPageComponent,
  },
  {
    path: 'noun-gender',
    component: NounGenderPageComponent,
  },
  {
    path: 'noun-highscore',
    component: NounHighscorePageComponent,
  },
  {
    path: 'profile/:userId',
    component: ProfilePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
