import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../services/business/profile/profile.service';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent {

  constructor(
    private activateRoute: ActivatedRoute,
    private profileService: ProfileService

  ) {
    this.activateRoute.params.subscribe(params => {
      this.profileService.userId = params.userId;
    });
  }

}
