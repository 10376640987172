import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VersionService {

  constructor(private httpClient: HttpClient) {}

  async getVersion(): Promise<string> {
    const version = await this.httpClient.get<any>('/version.json').toPromise();
    return version.version;
  }

}
