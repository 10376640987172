import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthStore {
  clear() {
    localStorage.clear();
  }

  get userId(): string | null {
    return this.getValue('userId');
  }

  set userId(value: string | null) {
    this.setValue('userId', value);
  }

  get token(): string | null {
    return this.getValue('token');
  }

  set token(value: string | null) {
    this.setValue('token', value);
  }

  get isAdmin(): boolean {
    return this.getValue('isAdmin') === 'true';
  }

  set isAdmin(value: boolean) {
    this.setValue('isAdmin', String(value));
  }

  // get nounStatistics(): string | null {
  //   return this.getValue('nounStatistics');
  // }

  // set nounStatistics(value: string | null) {
  //   this.setValue('nounStatistics', value);
  // }

  private getValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  private setValue(key: string, value: string | null): void {
    if (value) {
      localStorage.setItem(key, value);
    }
    else {
      localStorage.removeItem(key);
    }
  }
}
