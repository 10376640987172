import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../framework/base.component';
import { ProfileService } from '../../services/business/profile/profile.service';
import { UserService } from '../../services/business/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent extends BaseComponent {

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private profileService: ProfileService,
  ) {
    super(changeDetectorRef);
  }

  get ready(): boolean {
    return this.profileService.ready;
  }

  get isSelf(): boolean {
    return `${this.profileService.userId}` === this.userService.userId;
  }

  get name(): string {
    return this.profileService.name;
  }

  set name(value: string) {
    this.profileService.name = value;
  }

  get profileImageUrl(): string {
    const url = this.profileService.profileImageUrl;
    if (url?.length) {
      return url;
    }
    if (this.isSelf) {
      return '/assets/add-image.png';
    }
    return '/assets/no-image.png';
  }

  async uploadImage(evt: Event) {
    const myFile = (evt.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.readAsDataURL(myFile);
    reader.onload = () => {
      this.profileService.uploadImage(reader.result as string).then(result => {
        console.log(result);
      });
    };


  }

}
