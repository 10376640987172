import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/framework/base.component';
import { Router } from '@angular/router';
import { UserService } from '../../services/business/user/user.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent extends BaseComponent {
  menuOpen = false;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private router: Router
  ) {
    super(changeDetectorRef);
  }

  openMenu() {
    this.menuOpen = true;
  }

  hideMenu() {
    this.menuOpen = false;
  }

  async login() {
    await this.router.navigate(['login']);
    this.hideMenu();
  }

  async logout() {
    await this.userService.logout();
    this.hideMenu();
  }

  reload() {
    window.location.reload();
  }

  get isAuthenticated(): boolean {
    return this.userService.isLoggedIn;
  }

  async goToNouns() {
    await this.router.navigate(['noun-gender']);
    this.hideMenu();
  }

  async goToNounsHighscore() {
    await this.router.navigate(['noun-highscore']);
    this.hideMenu();
  }

  async goToProfile() {
    await this.router.navigate([`profile/${this.userService.userId}`]);
    this.hideMenu();
  }

}
