import { Injectable } from '@angular/core';
import { EzrestService } from '../../http/ezrest/ezrest.service';
import { BaseService } from '../../../framework/base.service';

export interface Highscore {
  userId: string;
  name: string;
  completed: number;
  longestStreak: number;
}

@Injectable({
  providedIn: 'root',
})
export class NounsHighscoreService extends BaseService {
  private highscoreItems: { [key: number]: Highscore } = {};
  private highscoreList: Highscore[] = [];

  constructor(private ezrestService: EzrestService) {
    super();
    this.loadData();
  }

  private async loadData() {
    // this.highscoreItems = await this.ezrestService.globalGet('/ffa/highscores');
    // this.highscoreList = Object.keys(this.highscoreItems).map(key => ({
    //   userId: this.highscoreItems[key].name,
    //   ...this.highscoreItems[key]
    // }));
    this.serviceContentUpdated();
  }

  protected async update(): Promise<void> {}

  async updateHighscore(userId: string, score: Highscore) {
    // await this.ezrestService.globalPut(`/ffa/highscores/${userId}`, score);
    // await this.loadData();
  }

  get highscores(): Highscore[] {
    return this.highscoreList;
  }
}
