import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { ServiceComponentUpdater } from './service-component-updater.service';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService implements OnDestroy {
  private nextVersion = 1;
  private subscriptions: Subscription[] = [];
  version = new ReplaySubject<number>(1);

  registerDependencies(...dependencies: BaseService[]) {
    dependencies.forEach((service) => {
      if (service.version) {
        this.subscriptions.push(
          service.version.subscribe(async () => {
            await this.update();
          })
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  protected serviceContentUpdated(): void {
    this.version.next(this.nextVersion++);
    ServiceComponentUpdater.update();
  }

  protected abstract async update(): Promise<void>;
}
