import { Injectable } from '@angular/core';
import { EzrestService } from '../../http/ezrest/ezrest.service';
import { AuthStore } from '../../system/auth/auth.store';
import { Router } from '@angular/router';
import { BaseService } from '../../../framework/base.service';
import { UserProfile } from '../profile/profile.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  private profile: UserProfile;

  constructor(
    private ezrestService: EzrestService,
    private authStore: AuthStore,
    private router: Router
  ) {
    super();
    this.loadData();
  }

  protected async update(): Promise<void> {}

  private async loadData() {
    if (this.isLoggedIn) {
      try {
        this.profile = await this.ezrestService.userGet('/profile', false);
      } catch {
        this.profile = {
          name: `User ${this.userId}`,
          image: '',
        };
      }
    } else {
      this.profile = {
        name: `Anonymous`,
        image: '',
      };
    }
    this.serviceContentUpdated();
  }

  async login(email: string, password: string): Promise<void> {
    const loginResponse = await this.ezrestService.login(email, password);
    if (loginResponse.userId) {
      this.authStore.userId = loginResponse.userId;
      this.authStore.token = loginResponse.token;
      this.authStore.isAdmin = loginResponse.isAdmin;
      await this.loadData();
      this.serviceContentUpdated();
      await this.redirectToHome();
    } else {
      console.warn('Wrong username or password', loginResponse);
    }
  }

  async createAccount(email: string, password: string): Promise<void> {
    const createAccountResponse = await this.ezrestService.createAccount(
      email,
      password
    );
    if (!!createAccountResponse.userId) {
      await this.update();
      await this.redirectToLogin();
    } else {
      console.warn('Something went wrong', createAccountResponse);
    }
  }

  async logout() {
    this.authStore.clear();
    this.serviceContentUpdated();
    await this.redirectToHome();
  }

  private async redirectToHome() {
    await this.router.navigate(['/']);
  }

  private async redirectToLogin() {
    await this.router.navigate(['login']);
  }

  get isLoggedIn(): boolean {
    return !!this.authStore.userId;
  }

  get userId(): string {
    return this.authStore.userId;
  }

  get name(): string {
    return this.profile.name;
  }
}
