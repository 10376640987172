<app-main-menu></app-main-menu>
<div class="highscore-list-container">
  <table class="highscore-list">
    <tr>
      <td class="name-title">
        Name
      </td>
      <td class="completed-title">
        Completed
      </td>
      <td class="streak-title">
        Streak
      </td>
    </tr>
    <tr *ngFor="let highscore of highscores" (click)="goToProfile(highscore.userId)">
      <td class="name-value">
        {{ highscore.name }}
      </td>
      <td class="completed-value">
        {{ highscore.completed }}
      </td>
      <td class="streak-value">
        {{ highscore.longestStreak }}
      </td>
    </tr>
  </table>
</div>