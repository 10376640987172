import { Injectable } from '@angular/core';
import { EzrestService } from '../../http/ezrest/ezrest.service';
import { BaseService } from '../../../framework/base.service';
import { UserService } from '../user/user.service';

export interface UserProfile {
  name: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  private _userId = '';
  private profile: UserProfile;
  private savingProfile = false;

  constructor(
    private ezrestService: EzrestService,
    private userService: UserService
  ) {
    super();
    this.registerDependencies(this.userService);
  }

  protected async update(): Promise<void> {
    this.serviceContentUpdated();
  }

  private async loadProfile() {
    if (this._userId !== '') {
      try {
        this.profile = await this.ezrestService.userGet('/profile', false, this._userId);
      } catch {
        this.profile = {
          name: 'test',
          image: ''
        };
      }
      this.serviceContentUpdated();
    }
  }

  private async saveProfile() {
    if (!this.savingProfile) {
      this.savingProfile = true;
      let profileValue = '';
      do {
        profileValue = JSON.stringify(this.profile);
        await this.ezrestService.userPut('/profile', this.profile, false);
      } while (profileValue !== JSON.stringify(this.profile));
      this.savingProfile = false;
    }
  }

  async uploadImage(image: string) {
    const response = await this.ezrestService.imagePost({
      data: image,
      width: 200,
      height: 200
    });
    this.profile.image = response.name;
    await this.saveProfile();
    this.serviceContentUpdated();
  }

  get ready(): boolean {
    return !!this.profile;
  }

  get userId(): string {
    return this._userId;
  }

  set userId(id: string) {
    this._userId = id;
    this.loadProfile();
  }

  get name(): string {
    return this.profile.name;
  }

  set name(value: string) {
    this.profile.name = value;
    this.saveProfile();
  }

  get profileImageUrl(): string {
    if (this.profile.image?.length) {
      return this.ezrestService.getImageUrl(this.profile.image);
    }
    return '';
  }

}
