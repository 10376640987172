import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-gender-button',
  templateUrl: './gender-button.component.html',
  styleUrls: ['./gender-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenderButtonComponent {
  @Input() isCorrect: boolean;
  @Output() activated = new EventEmitter<void>();
  @Output() down = new EventEmitter<void>();
  @Output() up = new EventEmitter<void>();

  isButtonDown = false;
  isButtonUp = false;

  constructor() {
  }

  buttonClicked() {
    this.activated.emit();
  }

  buttonDown() {
    this.isButtonDown = true;
    this.isButtonUp = false;
    this.down.emit();
  }

  buttonUp() {
    this.isButtonDown = false;
    this.isButtonUp = true;
    this.up.emit();
  }

}
