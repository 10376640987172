<div class="form-container">
  <h1>Get onboard</h1>

  <form
    class="account-form"
    [formGroup]="accountForm"
    (ngSubmit)="onSubmit(accountForm.value)"
  >
    <div class="item">
      <label for="account-email">Email:</label>
      <input id="account-email" type="text" formControlName="email" />
    </div>

    <div class="item">
      <label for="account-password">Password:</label>
      <input id="account-password" type="password" formControlName="password" />
    </div>

    <div class="item">
      <button>Create account</button>
    </div>
  </form>
</div>