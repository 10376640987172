<div class="manage-nouns">
    <h1>Manage nouns</h1>

    <table class="data-table">
        <thead>
            <th>Article</th>
            <th>Noun</th>
            <th>Plural</th>
            <th>English</th>
        </thead>
        <tbody>
            <tr *ngFor="let noun of nouns">
                <td><input type="text" value="{{noun.article}}" /></td>
                <td><input type="text" value="{{noun.noun}}" /></td>
                <td><input type="text" value="{{noun.plural}}" /></td>
                <td><input class="input-english" type="text" value="{{noun.english}}" /></td>
            </tr>
        </tbody>
    </table>

    <button>Save changes</button>
</div>