<div class="form-container">
  <h1>Login with existing user</h1>

  <form
    class="login-form"
    [formGroup]="loginForm"
    (ngSubmit)="onSubmit(loginForm.value)"
  >
    <div class="item">
      <label for="login-email">Email:</label>
      <input id="login-email" type="text" formControlName="email" />
    </div>

    <div class="item">
      <label for="login-password">Password:</label>
      <input id="login-password" type="password" formControlName="password" />
    </div>

    <div class="item">
      <button>Login</button>
    </div>
  </form>
</div>