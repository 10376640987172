<app-main-menu></app-main-menu>
<form #configForm="ngForm">
  <div *ngIf="ready" class="profile">
    <div class="profile-image">
      <img class="profile-image-image" [src]="profileImageUrl" />
      <input class="profile-image-input" type="file" accept="image/*" (change)="uploadImage($event)"/>
    </div>

    <div class="profile-name">
      <div class="profile-name-label">
        Name:
      </div>
      <div *ngIf="!isSelf">
        {{ name }}
      </div>
      <div *ngIf="isSelf" class="profile-name-input">
        <input type="text" name="name" [(ngModel)]="name">
      </div>
    </div>
  </div>
</form>
