<div
  class="button"
	[class.button-correct--down]="isButtonDown && isCorrect"
  [class.button-correct--up]="isButtonUp && isCorrect"
  [class.button-wrong--down]="isButtonDown && !isCorrect"
	[class.button-wrong--up]="isButtonUp && !isCorrect"
  (click)="buttonClicked()"
  (mousedown)="buttonDown()"
  (mouseup)="buttonUp()"
><ng-content></ng-content></div>
