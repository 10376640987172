import { Injectable } from '@angular/core';
import { EzrestService } from '../../http/ezrest/ezrest.service';
import { OfflineStore } from '../offline/offline.store';
import { UserService } from '../../business/user/user.service';
import { BaseService } from '../../../framework/base.service';

export interface NounStats {
  id: number;
  wrongCount: number;
  correctCount: number;
  lastWrongIndex: number;
  lastCorrectIndex: number;
  longestStreak: number;
  currentStreak: number;
  never: boolean;
  later: boolean;
  badTranslation: boolean;
}

export interface StatisticValues {
  userId?: string;
  activeNoun?: number;
  wordCount: number;
  wrongCount: number;
  correctCount: number;
  longestStreak: number;
  currentStreak: number;
}

export interface Statistics {
  values: StatisticValues;
  nouns: { [id: number]: NounStats };
}

@Injectable({
  providedIn: 'root',
})
export class SyncStateService extends BaseService {
  private _statistics: Statistics;
  private _nounQueue: NounStats[] = [];
  private _valuesQueued: StatisticValues | null = null;

  constructor(
    private ezrestService: EzrestService,
    private userService: UserService,
    private offlineStore: OfflineStore
  ) {
    super();
    this.loadData();
    this.processQueue();

    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        await this.loadData();
      }
    });
  }

  protected async update(): Promise<void> {}

  private saveLocal() {
    this.offlineStore.nounStatistics = JSON.stringify(this._statistics);
  }

  private async processQueue() {
    let didProcess = false;
    let didError = false;
    if (this.userService.isLoggedIn) {
      if (this._valuesQueued) {
        didProcess = true;
        const values = this._valuesQueued;
        this._valuesQueued = null;
        try {
          await this.ezrestService.userPut(
            '/nounStatistics/values',
            values,
            true
          );
        } catch (ex) {
          didError = true;
          console.log(ex);
          if (!this._valuesQueued) {
            this._valuesQueued = values;
          }
        }
      }
      if (!didError && this._nounQueue.length > 0) {
        didProcess = true;
        const nounStats = this._nounQueue[0];
        try {
          await this.ezrestService.userPut(
            `/nounStatistics/nouns/${nounStats.id}`,
            nounStats,
            true
          );
          this._nounQueue.shift();
        } catch (ex) {
          didError = true;
          console.log(ex);
        }
      }
    }
    setTimeout(
      () => {
        this.processQueue();
      },
      didError ? 1000 : didProcess ? 0 : 100
    );
  }

  private queueValuesForServer(values: StatisticValues) {
    this._valuesQueued = values;
  }

  private queueNounForServer(noun: NounStats) {
    this._nounQueue.push(noun);
  }

  private async alignToServer() {
    // this._statistics = await this.ezrestService.userGet('/nounStatistics', true);
    // this._statistics.values.userId = `${this._statistics.values.userId}`;
    // this.saveLocal();
  }

  private async alignToLocal() {
    const stats = JSON.parse(this.offlineStore.nounStatistics);
    // await this.ezrestService.userPut('/nounStatistics', stats, true);
    this._statistics = stats;
  }

  private async alignTo(stats: Statistics) {
    // await this.ezrestService.userPut('/nounStatistics', stats, true);
    this._statistics = stats;
    this.saveLocal();
  }

  private async loadData() {
    const localValues: Statistics = JSON.parse(
      this.offlineStore.nounStatistics
    );
    if (this.userService.isLoggedIn) {
      let serverValues: StatisticValues;
      // try {
      //   serverValues = await this.ezrestService.userGet('/nounStatistics/values', true);
      // } catch {}
      if (localValues && localValues.values.userId === '') {
        if (serverValues && serverValues.wordCount > 0) {
          await this.alignToServer();
        } else {
          localValues.values.userId = this.userService.userId;
          await this.alignTo(localValues);
        }
      } else if (
        localValues &&
        localValues.values.userId !== this.userService.userId
      ) {
        await this.alignToServer();
      } else if (!localValues && serverValues) {
        await this.alignToServer();
      } else if (localValues && !serverValues) {
        await this.alignToLocal();
      } else if (localValues.values.wordCount < serverValues.wordCount) {
        await this.alignToServer();
      } else if (localValues.values.wordCount > serverValues.wordCount) {
        await this.alignToLocal();
      } else {
        this._statistics = localValues;
      }
    } else if (localValues) {
      this._statistics = localValues;
    } else {
      this._statistics = {
        values: {
          userId: '',
          wordCount: 0,
          wrongCount: 0,
          correctCount: 0,
          longestStreak: 0,
          currentStreak: 0,
        },
        nouns: {},
      };
    }
    this.serviceContentUpdated();
  }

  get ready(): boolean {
    return !!this._statistics;
  }

  get activeNoun(): number {
    return this._statistics.values.activeNoun;
  }

  set activeNoun(id: number) {
    this._statistics.values.activeNoun = id;
    this.saveLocal();
    this.queueValuesForServer(this._statistics.values);
  }

  get statisticValues(): StatisticValues {
    return this._statistics.values;
  }

  set statisticValues(values: StatisticValues) {
    this._statistics.values = values;
    this.saveLocal();
    this.queueValuesForServer(values);
  }

  get nounStats(): NounStats[] {
    return Object.keys(this._statistics.nouns).map(
      (key) => this._statistics.nouns[key]
    );
  }

  hasNounStats(nounId: number): boolean {
    return !!this._statistics.nouns[nounId];
  }

  getNounStats(nounId: number): NounStats {
    const nounStats = this._statistics.nouns[nounId];
    return (
      nounStats || {
        id: nounId,
        wrongCount: 0,
        correctCount: 0,
        lastWrongIndex: 0,
        lastCorrectIndex: 0,
        longestStreak: 0,
        currentStreak: 0,
        never: false,
        later: false,
        badTranslation: false,
      }
    );
  }

  updateNounStats(stats: NounStats) {
    this._statistics.nouns[stats.id] = stats;
    this.saveLocal();
    this.queueNounForServer(stats);
  }
}
