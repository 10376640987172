import { Component } from '@angular/core';

@Component({
  selector: 'app-noun-gender-page',
  templateUrl: './noun-gender-page.component.html',
  styleUrls: ['./noun-gender-page.component.scss']
})
export class NounGenderPageComponent {

}
