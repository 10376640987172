import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/framework/base.component';
import { AdminNounsService } from 'src/app/services/business/admin/admin-nouns/admin-nouns.service';
import { Noun } from 'src/app/services/business/nouns/nouns.service';

@Component({
  selector: 'app-manage-nouns',
  templateUrl: './manage-nouns.component.html',
  styleUrls: ['./manage-nouns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageNounsComponent extends BaseComponent {

  constructor(private adminNounsService: AdminNounsService, private changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  get nouns(): Noun[] {
    return this.adminNounsService.nouns;
  }

}
