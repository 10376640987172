<div class="main-menu">
  <div *ngIf="isAuthenticated" (click)="openMenu()">
    <div class="burger"></div>
    <div class="burger"></div>
    <div class="burger"></div>
  </div>
  <div *ngIf="!isAuthenticated">
    <!-- <div class="login-button" (click)="login()">login</div> -->
  </div>
</div>
<div
  class="main-menu-overlay"
  [class.main-menu-overlay--show]="menuOpen"
  (click)="hideMenu()"
></div>
<div class="main-menu-items" [class.main-menu-items--show]="menuOpen">
  <div class="menu-item" (click)="goToNouns()">Nouns</div>
  <div class="menu-item" (click)="goToNounsHighscore()">Nouns - Highscore</div>
  <div class="menu-item" (click)="goToProfile()">profile</div>
  <div class="menu-item" (click)="reload()">reload</div>
  <div class="menu-item" (click)="logout()">log out</div>
</div>
