import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OfflineStore {

  get nounStatistics(): string | null {
    return this.getValue('nounStatistics');
  }

  set nounStatistics(value: string | null) {
    this.setValue('nounStatistics', value);
  }

  private getValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  private setValue(key: string, value: string | null): void {
    if (value) {
      localStorage.setItem(key, value);
    }
    else {
      localStorage.removeItem(key);
    }
  }
}
