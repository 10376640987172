import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-rate-button',
  templateUrl: './rate-button.component.html',
  styleUrls: ['./rate-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateButtonComponent {
  @Output() activated = new EventEmitter<void>();

  isButtonDown = false;
  isButtonUp = false;

  constructor() {
  }

  buttonClicked() {
    this.activated.emit();
  }

  buttonDown() {
    this.isButtonDown = true;
    this.isButtonUp = false;
  }

  buttonUp() {
    this.isButtonDown = false;
    this.isButtonUp = true;
  }

}
