import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NounGenderPageComponent } from './pages/noun-gender-page/noun-gender-page.component';
import { NounHighscorePageComponent } from './pages/noun-highscore-page/noun-highscore-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NounGenderComponent } from './components/noun-gender/noun-gender.component';
import { NounHighscoreComponent } from './components/noun-highscore/noun-highscore.component';
import { GenderButtonComponent } from './components/noun-gender/gender-button/gender-button.component';
import { RateButtonComponent } from './components/noun-gender/rate-button/rate-button.component';
import { LoginFormComponent } from './components/account/login-form/login-form.component';
import { CreateAccountFormComponent } from './components/account/create-account-form/create-account-form.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CreateAccountPageComponent } from './pages/create-account-page/create-account-page.component';
import { ManageNounsComponent } from './components/admin/manage-nouns/manage-nouns.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';


@NgModule({
  declarations: [
    AppComponent,
    NounGenderPageComponent,
    NounHighscorePageComponent,
    ProfilePageComponent,
    ProfileComponent,
    NounGenderComponent,
    NounHighscoreComponent,
    GenderButtonComponent,
    RateButtonComponent,
    LoginFormComponent,
    LoginPageComponent,
    CreateAccountFormComponent,
    CreateAccountPageComponent,
    ManageNounsComponent,
    MainMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
