import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../framework/base.component';
import { NounsHighscoreService, Highscore } from '../../services/business/nouns-highscore/nouns-highscore.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-noun-highscore',
  templateUrl: './noun-highscore.component.html',
  styleUrls: ['./noun-highscore.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NounHighscoreComponent extends BaseComponent {
  isCorrect = false;
  isGenderDown = false;
  isGenderUp = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private nounsHighscoreService: NounsHighscoreService,
    private router: Router
  ) {
    super(changeDetectorRef);
  }

  get highscores(): Highscore[] {
    return this.nounsHighscoreService.highscores;
  }

  async goToProfile(userId: number) {
    await this.router.navigate([`profile/${userId}`]);
  }

}
